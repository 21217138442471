const carsKey = 'cars-to-compare'

export const getCars =  () => {
    const cars = (localStorage.getItem(carsKey)) || '[]'
    return JSON.parse(cars)
}

export const saveCars =  (cars) => {
    localStorage.setItem(carsKey, JSON.stringify(cars))
}
