import { createSlice } from '@reduxjs/toolkit'

export const initialFormData = {
    brand: '',
    model: '',
    generation: '',
    engineCapacity: '0.2',
    yearFrom: '1900',
    yearTo: String(new Date().getFullYear()),
    priceFrom: '0',
    priceTo: '',
    profitFrom: '-100',
    profitTo: '100',
}

const initialState = {
    formData: {
        ...initialFormData,
    },
    activeFilters: {},
    openedFilters: {
        price: false,
        profit: false,
        brand: false,
        model: false,
        year: false,
        mileage: false,
        engine: false,
        transmission: false,
        bodyType: false,
    },
    selectedFilters: {
        price: false,
        profit: false,
        brand: false,
        model: false,
        year: false,
        mileage: false,
        engine: false,
        transmission: false,
        bodyType: false,
    },
}

export const filtersFormSlice = createSlice({
    name: 'filtersForm',
    initialState,
    reducers: {
        setFormData(state, action) {
            state.formData = {
                ...state.formData,
                ...action.payload,
            }
        },
        resetFormDataItem(state, action) {
            state.formData[action.payload] = initialFormData[action.payload]
        },
        setActiveFilters(state) {
            for (let key in state.formData) {
                if (state.formData[key] !== initialFormData[key] || key === 'model') {
                    state.activeFilters = {
                        ...state.activeFilters,
                        [key]: state.formData[key],
                    }
                }
            }
        },
        resetActiveFilters(state) {
            state.activeFilters = {}
        },
        setActiveFilter(state, action) {
            state.activeFilters = {
                ...state.activeFilters,
                ...action.payload,
            }
        },
        deleteFilter(state, action) {
            if (action.payload === 'brand') {
                delete state.activeFilters[action.payload]
                delete state.activeFilters['model']
                state.formData[action.payload] = initialFormData[action.payload]
                state.formData['model'] = initialFormData['model']
            }
            delete state.activeFilters[action.payload]
            state.formData[action.payload] = initialFormData[action.payload]
        },
        setOpenedFilters(state, action) {
            state.openedFilters[action.payload] = !state.openedFilters[action.payload]
        },
        setSelectedFilters(state, action) {
            state.selectedFilters[action.payload] =
        !state.selectedFilters[action.payload]
        },
    },
})

export default filtersFormSlice.reducer
