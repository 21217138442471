import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton } from '@mui/material'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

export default function GoBackAndTitle({ title, children }) {
    const theme = useTheme()
    let navigate = useNavigate()

    return (
        <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            sx={{
                width: '100%',
                mb: 3,
            }}>
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='start'
                gap={2}
                sx={{
                    alignSelf: 'start',
                }}>
                <IconButton
                    onClick={() => navigate(-1)}
                    component='button'
                    aria-label='back'
                    sx={{
                        p: 0,
                        color: theme.palette.COLORS.LIGHTGRAY,
                    }}
                ><ArrowBackIcon fontSize='large' /></IconButton>
                <Typography
                    variant='h3'
                    component='h1'
                    sx={{
                        lineHeight: 'normal',
                        fontWeight: 600,
                        color: theme.palette.COLORS.LIGHTGRAY,
                    }}>
                    {title}
                </Typography>
            </Stack>
            {children}
        </Stack>
    )
}

GoBackAndTitle.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
}
