import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { filtersFormSlice } from '../../../redux/reducers/FiltersFormSlice'


export default function TextFieldFilter({ fieldName, minFieldValue, maxFieldValue }) {
    const theme = useTheme()

    const { formData } = useSelector((state) => state.filtersFormReducer)
    const { setFormData } = filtersFormSlice.actions
    const dispatch = useDispatch()

    const formFieldNameFrom = `${fieldName}From`
    const formFieldNameTo = `${fieldName}To`

    const minValue = formData[`${formFieldNameFrom}`] ? Number(formData[`${formFieldNameFrom}`]) : ''
    const maxValue = formData[`${formFieldNameTo}`] ? Number(formData[`${formFieldNameTo}`]) : ''

    const handleChangeMin = (event) => {
        const minValueTarget = event.target.value
        dispatch(setFormData({ [`${formFieldNameFrom}`]: String(minValueTarget) }))
    }

    const handleChangeMax= (event) => {
        const maxValueTarget = event.target.value
        dispatch(setFormData({ [`${formFieldNameTo}`]: String(maxValueTarget) }))
    }

    return (
        <Stack direction='row' gap={1} p={4}>
            <TextField
                id={`min${fieldName}`}
                label='Мин.'
                variant='outlined'
                value={minValue}
                type='number'
                inputProps={ maxFieldValue ? { min: minFieldValue, max: maxFieldValue } : { min: minFieldValue }}
                error={ minValue < minFieldValue }
                onChange={handleChangeMin}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.COLORS.FILTERLISTACTIVE,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.COLORS.DARKCYAN,
                        },
                    }, input: { color: theme.palette.COLORS.DARKCYAN },
                }}
            />
            <TextField
                id={`max${fieldName}`}
                label='Макс.'
                variant='outlined'
                value={formData[`${formFieldNameTo}`] ? Number(formData[`${formFieldNameTo}`]) : ''}
                type='number'
                inputProps={ maxFieldValue ? { min: minFieldValue, max: maxFieldValue } : { min: minFieldValue }}
                error={ maxValue > maxFieldValue }
                onChange={handleChangeMax}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: theme.palette.COLORS.FILTERLISTACTIVE,
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.COLORS.DARKCYAN,
                        },
                    }, input: { color: theme.palette.COLORS.DARKCYAN },
                }}
            />
        </Stack>
    )
}

TextFieldFilter.propTypes = {
    fieldName: PropTypes.string.isRequired,
    minFieldValue: PropTypes.number.isRequired,
    maxFieldValue: PropTypes.number,
}
