import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'

import Icon from '../components/Icon'
import { TELEGRAM_LINK } from '../const/externalLinks'
import tracker from '../utils/tracker'

export const TgLink = ({ isShort = false }) => {
  const theme = useTheme()

  return (
    <a
      href={TELEGRAM_LINK}
      target="_blank"
      rel="noreferrer"
      style={{
        display: 'flex',
        alignItems: 'center',
        'text-decoration': 'none'
      }}
      onClick={() => {
        tracker.reachGoal('[FOOTER]: open TG bot')
      }}
    >
      <Icon
        icon={theme.icons.MESSENGERS_LOGO.telegram}
        width={24}
        height={24}
        sx={{ mr: theme.spacing(1), mb: 0 }}
      />
      <Typography
        variant="subtitle1"
        gutterBottom
        component="div"
        sx={{
          ...(isShort
            ? {
                fontFamily: 'Inter',
                fontWeight: 300,
                lineHeight: 1.5,
                fontSize: '20px',
                color: theme.palette.primary.main,
              }
            : {}),
          mb: 0,
        }}
      >
        {isShort ? 'Бот' : 'Telegram бот'}
      </Typography>
    </a>
  )
}
