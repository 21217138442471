import { Box } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'
import { styled, useTheme } from '@mui/system'
import Advantages from './components/advantage.js'
// import BrandCar from './components/BrandCar.js'
import PickCar from './components/PickCar.js'
import Page from '../../components/Page'

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('xs')]: {
        paddingLeft: 10,
        paddingRight: 10,
    },
    [theme.breakpoints.up('md')]: {
        paddingLeft: 115,
        paddingRight: 115,
    },
}))

const Main = styled(Box)(({ theme }) => ({
}))

export default function HomePage() {
    localStorage.setItem('isVisited', true)
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    
    return (
        <RootStyle heightNavBar={isMobile ? 25 : 50}>
            <Main>
                <PickCar />
                {/* <BrandCar /> */}
                <Advantages />
                {/* <BodyType />
                <Premium /> */}
            </Main>
        </RootStyle >
    )
}
