// ----------------------------------------------------------------------
//TODO: clarify style guid

function pxToRem(value) {
    return `${value / 16}rem`
}

function responsiveFontSizes({ xs, sm, md, lg }) {
    return {
        '@media (min-width:0px)': {
            fontSize: pxToRem(xs),
        },
        '@media (min-width:600px)': {
            fontSize: pxToRem(sm),
        },
        '@media (min-width:900px)': {
            fontSize: pxToRem(md),
        },
        '@media (min-width:1200px)': {
            fontSize: pxToRem(lg),
        },
    }
}

// eslint-disable-next-line quotes
const FONT_PRIMARY = "Inter"

const typography = {
    fontFamily: FONT_PRIMARY,
    fontWeightRegular: 300,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
        fontWeight: 600,
        ...responsiveFontSizes({ xs: 46, sm: 52, md: 58, lg: 64 }),
    },
    h2: {
        fontWeight: 600,
        ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
    },
    h3: {
        fontWeight: 600,
        ...responsiveFontSizes({ xs: 22, sm: 26, md: 30, lg: 32 }),
    },
    h4: {
        fontWeight: 600,
        ...responsiveFontSizes({ xs: 16, sm: 20, md: 24, lg: 24 }),
    },
    h5: {
        fontWeight: 600,
        lineHeight: 24 * 1.4,
        fontSize: 24, //pxToRem(18),
        ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
    },
    h6: {
        fontWeight: 600,
        lineHeight: 20 * 1.4,
        ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {
        fontWeight: 600,
        fontSize: pxToRem(16),
    },
    subtitle2: {
        fontWeight: 600,
        lineHeight: 14 * 1.4,
        fontSize: pxToRem(14),
    },
    body1: {
        fontSize: pxToRem(16),
    },
    body2: {
        fontSize: pxToRem(14),
    },
    caption: {
        lineHeight: 12 * 1.4,
        fontSize: pxToRem(12),
    },
    overline: {
        fontWeight: 700,
        lineHeight: 12 * 1.4,
        fontSize: pxToRem(12),
        letterSpacing: 1.1,
        textTransform: 'uppercase',
    },
    button: {
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        fontSize: '18px',
        textTransform: 'capitalize',
    },
    navItems: {
        fontSize: 20,
        fontWeight: 400,
    },
}

export default typography
