import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import { filtersFormSlice, initialFormData } from '../../../redux/reducers/FiltersFormSlice'
import fetchCars from '../../../redux/thunks/fetchCarsThunk'


export default function FiltersState() {
    const theme = useTheme()

    const { perPage, page } = useSelector((state) => state.carsReducer)
    const { activeFilters } = useSelector((state) => state.filtersFormReducer)
    const { deleteFilter } = filtersFormSlice.actions
    const dispatch = useDispatch()

    const approvedFilters = {}
    const activeFiltersArray = Object.entries(activeFilters)
    activeFiltersArray.forEach(([key, value]) => {
        if (initialFormData[`${key}`] !== value) {
            approvedFilters[`${key}`] = value
        }
    })
    const approvedFiltersArray = Object.entries(approvedFilters)

    const FILTER_NAMES = {
        'brand': '',
        'model': '',
        'engineCapacity': 'объём ',
        'yearFrom': 'год от ',
        'yearTo': 'год до ',
        'priceFrom': 'от ',
        'priceTo': 'до ',
        'profitFrom': 'выгода от ',
        'profitTo': 'выгода до ',
    }

    const handleDelete = (event) => {
        if (event.currentTarget.parentNode) {
            const filterKey = event.currentTarget.parentNode.id

            dispatch(deleteFilter(filterKey))

            dispatch(fetchCars({
                count: perPage,
                page,
            }))
        }
    }

    return (
        <Stack direction='row' gap={1} flexWrap='wrap'>
            {approvedFiltersArray.map(([key, value]) => {
                let text = FILTER_NAMES[`${key}`] + value
                if (key === 'profitFrom' || key === 'profitTo') {
                    text = text + '%'
                }
                if (key === 'engineCapacity') {
                    text = text + 'л'
                }
                if (key === 'priceFrom' || key === 'priceTo') {
                    text = text + '$'
                }

                return (<Chip
                    key={key}
                    id={key}
                    label={text}
                    sx={{
                        backgroundColor: theme.palette.COLORS.FOUNTAINBLUE,
                        color: theme.palette.COLORS.NONE,
                        '& .MuiChip-deleteIcon': {
                            color: theme.palette.COLORS.NONE,
                        },
                        '& .MuiChip-deleteIcon:hover': {
                            color: theme.palette.COLORS.DARKCYAN,
                        },
                    }}
                    onDelete={handleDelete}
                />)
            })}
        </Stack>
    )
}
