import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCars } from '../../dataFetch/getCars'
import tracker from '../../utils/tracker'

const fetchCars = createAsyncThunk(
    'search/fetchCars',
    async ({ count, page }, thunkAPI) => {
        const state = thunkAPI.getState()

        const {
            brand,
            model,
            generation,
            engineCapacity,
            yearFrom,
            yearTo,
            priceFrom,
            priceTo,
            profitFrom,
            profitTo,
        } = state.filtersFormReducer.activeFilters

        const cars = await getCars(
            count,
            page,
            brand,
            model,
            generation,
            engineCapacity,
            yearFrom,
            yearTo,
            priceFrom,
            priceTo,
            profitFrom,
            profitTo,
        )

        tracker.reachGoal(
            '[UPDATE_CAR_LIST] params',
            {
                'search_page': page,
                'search_brand': brand,
                'search_model': model,
                'search_generation': generation,
                'search_engineCapacity': engineCapacity,
                'search_yearFrom': yearFrom,
                'search_yearTo': yearTo,
                'search_priceFrom': priceFrom,
                'search_priceTo': priceTo,
                'search_profitFrom': profitFrom,
                'search_profitTo': profitTo,
            })

        return cars
    },
)

export default fetchCars
