import './mdBasePage.css'
import Page from '../../components/Page'
import React, { useEffect } from 'react'
import showdown from 'showdown'

const converter = new showdown.Converter({
  omitExtraWLInCodeBlocks: true,
  simplifiedAutoLink: true,
  strikethrough: true,
})

async function fetchMarkdown(markdownTextLink) {
  const res = await fetch(markdownTextLink)
  const markdown = await res.text()
  return markdown
}

async function initMarkdown(markdownTextLink, setMarkdown, labelValues) {
  const markdownRaw = await fetchMarkdown(markdownTextLink)

  const mockedLabels = Object.keys(labelValues)

  const markdown = mockedLabels.reduce((markdown, label) => {
    markdown = markdown.replace(label, labelValues[label])
    return markdown
  }, markdownRaw)

  const html = converter.makeHtml(markdown)

  setMarkdown(html)
}

const MdBasePage = ({ markdownTextLink, labelValues }) => {
  const [markdown, setMarkdown] = React.useState('')

  useEffect(() => {
    setMarkdown('Загрузка...')
    initMarkdown(markdownTextLink, setMarkdown, labelValues)
  }, [markdownTextLink, labelValues])

  return (
    <Page>
      <div dangerouslySetInnerHTML={{ __html: markdown }}></div>
      {/* <ReactMarkdown>{markdown}</ReactMarkdown> */}
    </Page>
  )
}

export default MdBasePage
