import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Page from '../../components/Page'
import { Typography } from '@mui/material'

export default function ComparisonTable() {
  const rows = [
    {
      id: 1,
      feature: 'Поиск по критериям',
      withoutReg: 'задержка 1 час',
      withReg: 'задержка 1 час',
      premium: 'моментально*',
    },
    {
      id: 2,
      feature: 'Мониторинг объявлений',
      withoutReg: 'задержка 1 час',
      withReg: 'задержка 1 час',
      premium: 'моментально*',
    },
    // {
    //   id: 3,
    //   feature: 'Поиск по ключевым словам',
    //   withoutReg: 'доступно',
    //   withReg: 'доступно',
    //   premium: 'доступно',
    // },
    // {
    //   id: 4,
    //   feature: 'Проверка по телефону',
    //   withoutReg: 'недоступно',
    //   withReg: '3 проверки',
    //   premium: 'без ограничений',
    // },
    {
      id: 5,
      feature: 'Анализ цены',
      withoutReg: 'доступно**',
      withReg: 'доступно**',
      premium: 'доступно детально',
    },
    // {
    //   id: 6,
    //   feature: 'Сохраненные фильтры',
    //   withoutReg: 'недоступно',
    //   withReg: '3 фильтра',
    //   premium: 'доступно',
    // },
    // {
    //   id: 7,
    //   feature: 'Поиск по удаленным объявлениям',
    //   withoutReg: 'недоступно',
    //   withReg: 'недоступно',
    //   premium: 'доступно',
    // },
    // {
    //   id: 8,
    //   feature: 'Количество объявлений у продавца',
    //   withoutReg: 'недоступно',
    //   withReg: 'недоступно',
    //   premium: 'доступно',
    // },
    // {
    //   id: 9,
    //   feature: 'История правок',
    //   withoutReg: 'недоступно',
    //   withReg: 'недоступно',
    //   premium: 'доступно',
    // },
    {
      id: 10,
      feature: 'Уведомления в мессенджеры',
      withoutReg: 'недоступно',
      withReg: 'недоступно',
      premium: 'доступно',
    },
    {
      id: 11,
      feature: 'Реклама',
      withoutReg: 'повсеместно',
      withReg: 'повсеместно',
      premium: 'отсутствует',
    },
  ]

  return (
    <Page>
      {/* <Typography>
        Сравнение тарифов поисковой системы Автопошук
      </Typography> */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
            <TableCell
                align="center"
                style={{ fontWeight: 'bold', fontSize: '1.2em' }}
              >
                #
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: 'bold', fontSize: '1.2em' }}
              >
                Функционал
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: 'bold', fontSize: '1.2em' }}
              >
                Без регистрации
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: 'bold', fontSize: '1.2em' }}
              >
                С регистрацией
              </TableCell>
              <TableCell
                align="center"
                style={{ fontWeight: 'bold', fontSize: '1.2em' }}
              >
                Премиум
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: 'bold' }}
                >
                  {index + 1}
                </TableCell>
                <TableCell align="center">{row.feature}</TableCell>
                <TableCell align="center">{row.withoutReg}</TableCell>
                <TableCell align="center">{row.withReg}</TableCell>
                <TableCell align="center">{row.premium}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Page>
  )
}
