import { ThemeProvider } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'
import { createTheme, styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import CardCarousel from './CardCarousel/CardCarousel'
import { AddToCompareButton } from '../../../components/AddToCompareButton'
import ProfitView from '../../../components/ProfitView'
import { PAGES } from '../../../routes/constants'
import { CarShiftIcon, CardCarIcon, EngineIcon, FuelIcon } from '../../../theme/svgIcons'
import { numberWithSpaces } from '../../../utils/formatNumber'
import { labelForTime } from '../../../utils/formatTime'
import tracker from '../../../utils/tracker'

export default function AutoCard(car) {
    const {
        title,
        price,
        year,
        photos,
        whenUpdated,
        location,
        bodyType,
        engine,
        transmission,
        mileage,
        profit,
        id,
    } = car
    const theme = useTheme()

    const cardTheme = createTheme({
        breakpoints: {
            ...theme.breakpoints,
        },
        palette: {
            ...theme.palette,
            action: {
                disabled: theme.palette.COLORS.LIGHTGRAY,
            },
            info: {
                main: theme.palette.COLORS.DARKCYAN,
            },
        },
        typography: {
            caption: {
                ...theme.typography.caption,
                lineHeight: 'normal',
                color: theme.palette.COLORS.GRANITEGRAY,
            },
            h5: {
                ...theme.typography.h5,
                lineHeight: 'normal',
                fontWeight: 600,
            },
            h6: {
                ...theme.typography.h6,
                lineHeight: 'normal',
            },
            subtitle1: {
                ...theme.typography.subtitle1,
                color: theme.palette.COLORS.GRANITEGRAY,
            },
            button: {
                ...theme.typography.button,
            },
        },
    })

    const StyledButton = styled(Button)({
        height: '48px',
        padding: '8px 22px',
    })

    const CardContentCustomPadding = styled(CardContent)`
      &:last-child {
            padding-bottom: 8px;
      }
    `

    const whenAddedDate = new Date(whenUpdated)

    const handleClickMoreInfo = () => {
        tracker.reachGoal('[SEARCH_PAGE] MORE_INFO CLICK')
    }

    return (
        <ThemeProvider theme={cardTheme}>
            <Card elevation={0} sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                justifyContent: 'space-between',
            }}>
                <Box>
                    <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
                        <Typography variant='caption' component='p' display='block'>
                            {labelForTime(whenAddedDate)}
                        </Typography>
                        <Typography variant='caption' component='p' display='block'>
                            {location}
                        </Typography>
                    </Stack>
                    <Box sx={{
                        position: 'relative',
                        marginBottom: '20px',
                    }}>
                        <CardCarousel images={photos} />
                        <Box sx={{
                            position: 'absolute',
                            top: '0px',
                            right: '0px',
                            zIndex: 20,
                        }}>
                            <AddToCompareButton car={car} />
                        </Box>
                        <Box sx={{
                            position: 'absolute',
                            bottom: '14px',
                            right: '0px',
                            zIndex: 20,
                        }}>
                            <ProfitView profit={profit}></ProfitView>
                        </Box>
                    </Box>
                    <CardContentCustomPadding sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}>
                        <Stack direction='row' justifyContent='space-between' gap={1}>
                            <Typography variant='h5' component='h3'>
                                {`${title}, ${year}`}
                            </Typography>
                            <Stack direction='column' alignItems='flex-end' sx={{
                                width: '35%',
                            }}>
                                <Typography variant='h5' component='h4' color='primary' sx={{
                                    flexGrow: 3,
                                    whiteSpace: 'nowrap',
                                }}>
                                    $ {numberWithSpaces(price.usd)}
                                </Typography>
                                <Typography variant='subtitle1' component='h5' sx={{
                                    flexGrow: 1,
                                    whiteSpace: 'nowrap',
                                }}>
                                    BYN ≈{numberWithSpaces(price.byn)}
                                </Typography>
                            </Stack>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <EngineIcon color='disabled'/>
                                <Typography variant='body1' component='p'>{engine.capacity}</Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <FuelIcon color='disabled'/>
                                <Typography variant='body1' component='p'>{engine.type}</Typography>
                            </Stack>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <CarShiftIcon color='disabled'/>
                                <Typography variant='body1' component='p'>{transmission}</Typography>
                            </Stack>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <CardCarIcon color='disabled'/>
                                <Typography>{bodyType}</Typography>
                            </Stack>
                            <Typography>{numberWithSpaces(mileage)} км</Typography>
                        </Stack>
                    </CardContentCustomPadding>
                </Box>

                <CardActions>
                    <StyledButton
                        component={RouterLink}
                        to={PAGES.detailedInfo.link + id}
                        size='medium'
                        variant='contained'
                        fullWidth
                        onClick={handleClickMoreInfo}
                    >ПОДРОБНЕЕ</StyledButton>
                </CardActions>
            </Card>
        </ThemeProvider>
    )
}

AutoCard.propTypes = {
    title: PropTypes.string,
    price: PropTypes.shape({
        usd: PropTypes.number,
        byn: PropTypes.number,
    }),
    year: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    whenAdded: PropTypes.string,
    location: PropTypes.string,
    bodyType: PropTypes.string,
    engine: PropTypes.shape({
        capacity: PropTypes.string,
        type: PropTypes.string,
    }),
    transmission: PropTypes.string,
    mileage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    profit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.string,
    isAddedToCompare: PropTypes.bool,
    onAddToCompareChange: PropTypes.func,
}
