import ErrorComponent from './components/ErrorComponent'
import Page from '../../components/Page'


export default function ErrorPage() {
    return (
        <Page>
            <ErrorComponent />
        </Page >
    )
}
