import { Navigate, useRoutes } from 'react-router-dom'

import { PAGES } from './constants'
import AppLayout from '../layouts/app'
import { CarComparePage } from '../pages/CarComparePage/CarComparePage'
import DetailedInfoPage from '../pages/DetailedInfoPage/DetailedInfoPage'
import CallbackPage from '../pages/CallbackPage/CallbackPage'
import HomePage from '../pages/HomePage/HomePage'
import Login from '../pages/Login'
import SearchPage from '../pages/SearchPage/SearchPage'
import LicenseAgreementPage from '../pages/md/LicenseAgreementPage'
import OfferPage from '../pages/OfferPage'

export default function Router() {
  const isFirstVisit = !localStorage.getItem('isVisited')

  return useRoutes([
    {
      path: '/',
      element: <AppLayout />,
      children: [
        {
          path: PAGES.default.path,
          element: isFirstVisit ? (
            <HomePage />
          ) : (
            <Navigate to={PAGES.search.path} replace></Navigate>
          ),
        },
        {
          path: PAGES.home.path,
          element: <HomePage />,
        },
        {
          path: PAGES.catalog.path,
          element: <SearchPage />,
        },
        {
          path: PAGES.login.path,
          element: <Login />,
        },
        {
          path: PAGES.search.path,
          element: <SearchPage />,
        },
        {
          path: PAGES.callback.path,
          element: <CallbackPage />,
        },
        {
          path: PAGES.detailedInfo.path,
          element: <DetailedInfoPage />,
        },
        {
          path: PAGES.carCompare.link,
          element: <CarComparePage />,
        },
        {
          path: PAGES.licenseAgreement.path,
          element: <LicenseAgreementPage />,
        },
        {
          path: PAGES.offer.path,
          element: <OfferPage />,
        }
      ],
    },
    { path: '*', element: <Navigate to={PAGES.home.path} replace /> },
  ])
}
