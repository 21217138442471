import { Card, CardContent, Modal } from '@mui/material'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { FEEDBACK_FORM } from '../const/externalLinks'
import useLocalStorage from '../customHooks/useLocalStorage'
import tracker from '../utils/tracker'

const timerPeriod = 5 * 60 * 1000 //5 mins

export default function ModalSurvey() {
    const [surveyOpened, setSurveyOpened] = useLocalStorage('surveyOpened', false)
    
    const [open, setOpen] = useState(false)
    const handleOpen = (isSurvey) => {
        if (isSurvey){
            tracker.reachGoal('[FEEDBACK]: open survey')}
        else {
            tracker.reachGoal('[FEEDBACK]: open feedback')
        }
        setOpen(true)
    }
    const handleClose = () => setOpen(false)
    const handleLikeAll = () => {
        tracker.reachGoal('[FEEDBACK]: like all')
        handleClose()
    }

    useEffect(() => {
        if (!surveyOpened) {
            const timer = setTimeout(() => {
                handleOpen(true)
                setSurveyOpened(true)
            }, timerPeriod)

            return () => clearTimeout(timer)
        }
    }, [])

    const StyledButton = styled(Button)({
        height: '48px',
        padding: '8px 22px',
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: '288px',
        maxWidth: '800px',
        p: 2,
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
        >
            <Card sx={style}>
                <CardContent>
                    <Stack gap={1}>
                        <Typography variant='body1' component='p'>
                            Пожалуйста, пройдите опрос об удобстве нашего сайта.
                        </Typography>
                        <Typography variant='body1' component='p'>
                            Этим вы поможете его улучшить и сделать удобнее для вас.
                        </Typography>
                        <Typography variant='body1' component='p'>Спасибо!</Typography>
                    </Stack>
                </CardContent>
                <CardActions>
                    <Stack
                        direction='row'
                        gap={2}
                        flexWrap='wrap'
                        justifyContent='center'
                        alignItems='center'
                        sx={{
                            width: '100%',
                        }}
                    >
                        <StyledButton
                            component={Link}
                            href={FEEDBACK_FORM}
                            target='_blank'
                            size='medium'
                            variant='contained'
                            fullWidth
                            onClick={handleClose}
                        >Пройти опрос</StyledButton>
                        <StyledButton
                            size='medium'
                            variant='contained'
                            onClick={handleLikeAll}
                        >Мне всё нравится!</StyledButton>
                    </Stack>
                </CardActions>
            </Card>
        </Modal>
    )
}
