import { Stack } from '@mui/material'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import { Link as RouterLink } from 'react-router-dom'
import { FEEDBACK_FORM } from '../../../const/externalLinks'
import { PAGES } from '../../../routes/constants'
import tracker from '../../../utils/tracker'


export default function ErrorComponent() {
    const theme = useTheme()

    const StyledButton = styled(Button)({
        height: '48px',
        padding: '8px 22px',
    })

    return (
        <Stack
            direction='column'
            alignItems='center'
            justifyContent='center'
            gap={2}
            p={4}
            sx={{
                height: '100%',
            }}
        >
            <Typography variant='h4' component='h2' sx={{
                lineHeight: 'normal',
                color: theme.palette.COLORS.LIGHTGRAY,
            }}>
                    Извините, произошла ошибка.
            </Typography>
            <Typography variant='h4' component='h2' sx={{
                lineHeight: 'normal',
                color: theme.palette.COLORS.LIGHTGRAY,
            }}>
                    Попробуйте обновить страницу или перейти на главную.
                    Если эта ошибка появится снова - сообщите нам.
            </Typography>
            <Stack direction='row' alignItems='center' justifyContent='center' flexWrap='wrap' gap={2}>
                <StyledButton
                    component={RouterLink}
                    to={PAGES.home.path}
                    size='medium'
                    variant='contained'
                    fullWidth
                >НА ГЛАВНУЮ</StyledButton>
                <StyledButton
                    component={Link}
                    href={FEEDBACK_FORM}
                    target='_blank'
                    size='medium'
                    variant='contained'
                    fullWidth
                    onClick={() => 
                        tracker.reachGoal('[ERROR_COMPONENT] open feedback form', { path: window.location.pathname })
                    }
                >Написать нам</StyledButton>
            </Stack>
        </Stack>
    )
}
