import { createSlice } from '@reduxjs/toolkit'
import { getCars, saveCars } from '../../dataFetch/carsToCompare'

const initialState = {
    cars: [],
    error: null,
    status: 'idle',
}

export const carCompareSlice = createSlice({
    name: 'carToCompare',
    initialState,
    reducers: {
        //TO DO: idgf is it ok to do like this
        setCars(state, { cars = [] }) {
            state.cars = cars
            saveCars(cars)
        },
        initCars(state) {
            state.cars = getCars()
        },
        addToCompare(state, action) {
            const { car } = action.payload
            state.cars = [...state.cars, car]
            saveCars(state.cars)
        },
        removeFromCompare(state, action) {
            const { car } = action.payload
            state.cars = state.cars.filter((x) => x.id !== car.id)
            saveCars(state.cars)
        },
    },
})

export default carCompareSlice.reducer
