import { styled } from '@mui/material/styles'
import { Outlet } from 'react-router-dom'

import DashboardNavbar from './DashboardNavbar'
import FooterBar from './FooterBar.js'
import ErrorBoundary from '../../components/ErrorBoundary'
import ModalSurvey from '../../components/ModalSurvey'

const RootStyle = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

const MainStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export default function LayoutView() {
  return (
    <RootStyle>
      <MainStyle>
        <ErrorBoundary>
          <DashboardNavbar />
          <Outlet />
          <FooterBar />
          <ModalSurvey />
        </ErrorBoundary>
      </MainStyle>
    </RootStyle>
  )
}
