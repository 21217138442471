import getUrl from './_baseUrl'
import { createURL } from './getCars.utils'

export const getCars = async (
    count,
    page,
    brand,
    model,
    generation,
    engineCapacity,
    yearFrom,
    yearTo,
    priceFrom,
    priceTo,
    profitFrom,
    profitTo,
) => {
    const getCarsUrl = getUrl('/get-cars')

    const urlObject = {
        count,
        page,
        brand,
        model,
        generation,
        engine_capacity: engineCapacity,
        yearFrom,
        yearTo,
        priceFrom,
        priceTo,
        profitFrom,
        profitTo,
    }

    const url = createURL(urlObject)
    const accessToken = localStorage.getItem('accessToken')

    const response = await fetch(`${getCarsUrl}/?${url}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
        },
    )

    if (!response.ok) {
        return response.error
    }

    return response.json()
}
