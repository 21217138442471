import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import ProfitView from '../../../components/ProfitView'
import { numberWithSpaces } from '../../../utils/formatNumber'

export default function MainInfo({ car }) {
    const theme = useTheme()

    return (
        <Stack>
            <Stack direction='row' gap={2} justifyContent='space-between' sx={{
                flexWrap: 'wrap',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            }}>
                <Typography variant='h5' component='h3' pt={1} sx={{
                    lineHeight: 'normal',
                    color: theme.palette.COLORS.LIGHTGRAY,
                }}>
                    {`${car.title}, ${car.year}`}
                </Typography>

                <ProfitView profit={car.profit} isForCard={false}></ProfitView>
            </Stack>

            <Stack direction='row' pt={2} pb={2} gap={1} sx={{
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                },
            }}>
                <Typography variant='h5' component='h4' sx={{
                    lineHeight: 'normal',
                    whiteSpace: 'nowrap',
                    color: theme.palette.COLORS.DARKGREEN,
                }}>
                    {`${numberWithSpaces(car.price.usd)} $ `}
                </Typography>
                <Typography variant='h5' component='h4' sx={{
                    lineHeight: 'normal',
                    whiteSpace: 'nowrap',
                    color: theme.palette.COLORS.LIGHTGRAY,
                }}>
                    {` ≈ ${numberWithSpaces(car.price.byn)} BYN`}
                </Typography>
            </Stack>
        </Stack>
    )
}

MainInfo.propTypes = {
    car: PropTypes.shape({
        title: PropTypes.string,
        year: PropTypes.string,
        profit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        price: PropTypes.shape({
            usd: PropTypes.number,
            byn: PropTypes.number,
        }),
    }),
}
