export const CARS = [
    {
        brand: 'Acura',
        models: [
            {
                model: 'CL',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2000',
                    },
                ],
            },
            {
                model: 'CSX',
                generations: [],
            },
            {
                model: 'ILX',
                generations: [],
            },
            {
                model: 'MDX',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2006',
                    },
                    {
                        name: 'II',
                        year: '2007…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2010…2013',
                    },
                    {
                        name: 'III',
                        year: '2013…2015',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2015…2016',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2016…2020',
                    },
                    {
                        name: '4 поколение',
                        year: '2021…',
                    },
                ],
            },
            {
                model: 'RDX',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'III',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'RL',
                generations: [
                    {
                        name: 'II',
                        year: '2005…2009',
                    },
                ],
            },
            {
                model: 'RSX',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2006',
                    },
                ],
            },
            {
                model: 'TL',
                generations: [
                    {
                        name: 'II · Рестайлинг',
                        year: '2001…2003',
                    },
                    {
                        name: 'III',
                        year: '2003…2006',
                    },
                    {
                        name: 'IV',
                        year: '2008…2011',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2011…2014',
                    },
                ],
            },
            {
                model: 'TLX',
                generations: [
                    {
                        name: 'I',
                        year: '2014…2017',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…2020',
                    },
                    {
                        name: 'II',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'TSX',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2014',
                    },
                ],
            },
            {
                model: 'ZDX',
                generations: [],
            },
        ],
    },
    {
        brand: 'Alfa Romeo',
        models: [
            {
                model: '145',
                generations: [
                    {
                        name: '930',
                        year: '1994…1999',
                    },
                    {
                        name: '930 · Рестайлинг',
                        year: '1999…2001',
                    },
                ],
            },
            {
                model: '146',
                generations: [],
            },
            {
                model: '147',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2010',
                    },
                ],
            },
            {
                model: '155',
                generations: [
                    {
                        name: '167',
                        year: '1992…1995',
                    },
                ],
            },
            {
                model: '156',
                generations: [
                    {
                        name: '932',
                        year: '1997…2002',
                    },
                    {
                        name: '932 · Рестайлинг',
                        year: '2002…2003',
                    },
                    {
                        name: '932 · 2-й рестайлинг',
                        year: '2003…2007',
                    },
                ],
            },
            {
                model: '159',
                generations: [],
            },
            {
                model: '164',
                generations: [
                    {
                        name: 'I',
                        year: '1987…1992',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1992…1998',
                    },
                ],
            },
            {
                model: '166',
                generations: [
                    {
                        name: '936',
                        year: '1998…2003',
                    },
                    {
                        name: '936 · Рестайлинг',
                        year: '2003…2007',
                    },
                ],
            },
            {
                model: 'Brera',
                generations: [],
            },
            {
                model: 'Giulia',
                generations: [],
            },
            {
                model: 'Giulietta',
                generations: [
                    {
                        name: '940',
                        year: '2010…2016',
                    },
                ],
            },
            {
                model: 'GT',
                generations: [],
            },
            {
                model: 'GTV',
                generations: [],
            },
            {
                model: 'MiTo',
                generations: [
                    {
                        name: '955',
                        year: '2008…2013',
                    },
                ],
            },
            {
                model: 'Stelvio',
                generations: [],
            },
        ],
    },
    {
        brand: 'Alpina',
        models: [
            {
                model: 'B5',
                generations: [
                    {
                        name: 'G30,G31',
                        year: '2017…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Aston Martin',
        models: [
            {
                model: 'DBS',
                generations: [
                    {
                        name: 'II',
                        year: '2007…2012',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Audi',
        models: [
            {
                model: '80',
                generations: [
                    {
                        name: 'B1 · Рестайлинг',
                        year: '1976…1978',
                    },
                    {
                        name: 'B2',
                        year: '1978…1984',
                    },
                    {
                        name: 'B2 · Рестайлинг',
                        year: '1984…1986',
                    },
                    {
                        name: 'B3',
                        year: '1986…1991',
                    },
                    {
                        name: 'B4',
                        year: '1991…1996',
                    },
                ],
            },
            {
                model: '90',
                generations: [
                    {
                        name: 'B2',
                        year: '1984…1986',
                    },
                    {
                        name: '89',
                        year: 'B3',
                    },
                ],
            },
            {
                model: '100',
                generations: [
                    {
                        name: 'С2 · Рестайлинг',
                        year: '1979…1982',
                    },
                    {
                        name: 'С3',
                        year: '1982…1988',
                    },
                    {
                        name: 'С3 · Рестайлинг',
                        year: '1988…1990',
                    },
                    {
                        name: '4A',
                        year: 'C4',
                    },
                ],
            },
            {
                model: '200',
                generations: [
                    {
                        name: 'C3',
                        year: '1983…1988',
                    },
                    {
                        name: 'C3 · Рестайлинг',
                        year: '1988…1991',
                    },
                ],
            },
            {
                model: 'A1',
                generations: [
                    {
                        name: '8X',
                        year: '2010…2014',
                    },
                ],
            },
            {
                model: 'A2',
                generations: [],
            },
            {
                model: 'A3',
                generations: [
                    {
                        name: '8L',
                        year: '1996…2000',
                    },
                    {
                        name: '8L · Рестайлинг',
                        year: '2000…2003',
                    },
                    {
                        name: '8P',
                        year: '2003…2005',
                    },
                    {
                        name: '8P',
                        year: '8PA · Рестайлинг',
                    },
                    {
                        name: '8P',
                        year: '8PA · 2-й рестайлинг',
                    },
                    {
                        name: '8V',
                        year: '2012…2016',
                    },
                    {
                        name: '8V · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'A4',
                generations: [
                    {
                        name: 'B5',
                        year: '1994…1999',
                    },
                    {
                        name: 'B5 · Рестайлинг',
                        year: '1999…2001',
                    },
                    {
                        name: 'B6',
                        year: '2000…2006',
                    },
                    {
                        name: 'B7',
                        year: '2004…2009',
                    },
                    {
                        name: 'B8',
                        year: '2007…2011',
                    },
                    {
                        name: 'B8 · Рестайлинг',
                        year: '2011…2015',
                    },
                    {
                        name: 'B9',
                        year: '2015…2020',
                    },
                    {
                        name: 'B9 · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'A4 Allroad',
                generations: [
                    {
                        name: 'B8 · Рестайлинг',
                        year: '2011…2016',
                    },
                    {
                        name: 'B9',
                        year: '2016…2018',
                    },
                    {
                        name: 'B9 · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'A5',
                generations: [
                    {
                        name: '8T',
                        year: '2007…2011',
                    },
                    {
                        name: '8T · Рестайлинг',
                        year: '2011…2016',
                    },
                    {
                        name: 'F5',
                        year: '2016…2020',
                    },
                    {
                        name: 'F5 · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'A6',
                generations: [
                    {
                        name: 'C4',
                        year: '1994…1997',
                    },
                    {
                        name: 'C5',
                        year: '1997…2001',
                    },
                    {
                        name: 'C5 · Рестайлинг',
                        year: '2001…2004',
                    },
                    {
                        name: 'C6',
                        year: '2004…2011',
                    },
                    {
                        name: 'C6 · Рестайлинг',
                        year: '2008…2011',
                    },
                    {
                        name: 'C7',
                        year: '2011…2014',
                    },
                    {
                        name: 'C7 · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'C8',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'A6 Allroad',
                generations: [
                    {
                        name: 'C5',
                        year: '2000…2006',
                    },
                    {
                        name: 'C6',
                        year: '2006…2008',
                    },
                    {
                        name: 'C6 · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'C7',
                        year: '2012…2014',
                    },
                    {
                        name: 'C7 · Рестайлинг',
                        year: '2014…2019',
                    },
                    {
                        name: 'C8',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'A7',
                generations: [
                    {
                        name: '4G',
                        year: '2010…2014',
                    },
                    {
                        name: '4G · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'Type 4K8',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'A8',
                generations: [
                    {
                        name: 'D2',
                        year: '1994…1999',
                    },
                    {
                        name: 'D2 · Рестайлинг',
                        year: '1999…2002',
                    },
                    {
                        name: 'D3',
                        year: '2002…2005',
                    },
                    {
                        name: 'D3 · Рестайлинг',
                        year: '2005…2007',
                    },
                    {
                        name: 'D3 · 2-й Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'D4',
                        year: '2010…2017',
                    },
                    {
                        name: 'D4 · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'D5',
                        year: '2017…2022',
                    },
                ],
            },
            {
                model: 'Cabriolet',
                generations: [],
            },
            {
                model: 'Coupe',
                generations: [
                    {
                        name: 'B2',
                        year: '1980…1984',
                    },
                    {
                        name: 'B2 · Рестайлинг',
                        year: '1984…1988',
                    },
                    {
                        name: 'B3 · Рестайлинг',
                        year: '1991…1996',
                    },
                ],
            },
            {
                model: 'e-tron',
                generations: [],
            },
            {
                model: 'Q2',
                generations: [],
            },
            {
                model: 'Q3',
                generations: [
                    {
                        name: '8U',
                        year: '2011…2014',
                    },
                    {
                        name: '8U · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'F3',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Q5',
                generations: [
                    {
                        name: '8R',
                        year: '2008…2012',
                    },
                    {
                        name: '8R · Рестайлинг',
                        year: '2012…2016',
                    },
                    {
                        name: 'FY',
                        year: '2016…2020',
                    },
                    {
                        name: 'FY · Рестайлинг',
                        year: '2020…2022',
                    },
                ],
            },
            {
                model: 'Q7',
                generations: [
                    {
                        name: '4L',
                        year: '2005…2009',
                    },
                    {
                        name: '4L · Рестайлинг',
                        year: '2009…2015',
                    },
                    {
                        name: '4M',
                        year: '2015…2019',
                    },
                    {
                        name: '4M · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Q8',
                generations: [],
            },
            {
                model: 'R8',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2012',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2015',
                    },
                ],
            },
            {
                model: 'RS Q3',
                generations: [
                    {
                        name: 'Typ 8U',
                        year: '2013…2014',
                    },
                    {
                        name: 'Typ 8U · Рестайлинг',
                        year: '2014…2018',
                    },
                ],
            },
            {
                model: 'RS3',
                generations: [
                    {
                        name: 'II',
                        year: '2015…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2021',
                    },
                ],
            },
            {
                model: 'RS4',
                generations: [
                    {
                        name: 'B8',
                        year: '2012…2015',
                    },
                ],
            },
            {
                model: 'RS5',
                generations: [
                    {
                        name: 'II',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'RS6',
                generations: [
                    {
                        name: 'C5',
                        year: '2002…2004',
                    },
                    {
                        name: 'C6',
                        year: '2008…2010',
                    },
                ],
            },
            {
                model: 'S3',
                generations: [
                    {
                        name: '8P',
                        year: '8PA · Рестайлинг',
                    },
                    {
                        name: '8V',
                        year: '2013…2016',
                    },
                    {
                        name: '8V · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'S4',
                generations: [
                    {
                        name: 'B5',
                        year: '8D',
                    },
                    {
                        name: 'B7',
                        year: '8E',
                    },
                    {
                        name: 'B8',
                        year: '8K',
                    },
                ],
            },
            {
                model: 'S5',
                generations: [
                    {
                        name: '8T · Рестайлинг',
                        year: '2011…2016',
                    },
                    {
                        name: 'II',
                        year: '2016…2019',
                    },
                ],
            },
            {
                model: 'S6',
                generations: [
                    {
                        name: 'C4',
                        year: '1994…1997',
                    },
                    {
                        name: 'C5',
                        year: '1999…2004',
                    },
                    {
                        name: 'C6',
                        year: '2006…2008',
                    },
                    {
                        name: 'C6 · Рестайлинг',
                        year: '2008…2011',
                    },
                ],
            },
            {
                model: 'S7',
                generations: [
                    {
                        name: '4G · Рестайлинг',
                        year: '2014…2019',
                    },
                ],
            },
            {
                model: 'S8',
                generations: [
                    {
                        name: 'D2',
                        year: '1996…1999',
                    },
                    {
                        name: 'D3',
                        year: '2006…2007',
                    },
                    {
                        name: 'D3 · Рестайлинг',
                        year: '2007…2012',
                    },
                    {
                        name: 'D4',
                        year: '2012…2013',
                    },
                ],
            },
            {
                model: 'SQ5',
                generations: [
                    {
                        name: 'II',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'SQ7',
                generations: [
                    {
                        name: '4M · Рестайлинг',
                        year: '2019…2022',
                    },
                ],
            },
            {
                model: 'SQ8',
                generations: [],
            },
            {
                model: 'TT',
                generations: [
                    {
                        name: '8N',
                        year: '1998…2003',
                    },
                    {
                        name: '8N · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: '8J',
                        year: '2006…2010',
                    },
                    {
                        name: '8S',
                        year: '2014…2019',
                    },
                    {
                        name: '8S · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'TTS',
                generations: [
                    {
                        name: 'Typ 8J',
                        year: '2007…2010',
                    },
                    {
                        name: 'Typ 8S',
                        year: '2014…2018',
                    },
                ],
            },
            {
                model: 'V8',
                generations: [],
            },
        ],
    },
    {
        brand: 'Bentley',
        models: [
            {
                model: 'Continental Flying Spur',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2013',
                    },
                ],
            },
            {
                model: 'Continental GT',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2011',
                    },
                    {
                        name: 'II',
                        year: '2010…2014',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2014…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'BMW',
        models: [
            {
                model: '1 серия',
                generations: [
                    {
                        name: 'E81',
                        year: 'E82',
                    },
                    {
                        name: 'E81',
                        year: 'E82',
                    },
                    {
                        name: 'F20',
                        year: 'F21',
                    },
                    {
                        name: 'F20',
                        year: 'F21 · Рестайлинг',
                    },
                    {
                        name: 'F40',
                        year: '2019…',
                    },
                ],
            },
            {
                model: '2 серия',
                generations: [
                    {
                        name: 'F22',
                        year: '2014…2017',
                    },
                    {
                        name: 'F22 (Рестайлинг)',
                        year: '2017…',
                    },
                    {
                        name: 'F44',
                        year: '2019…',
                    },
                ],
            },
            {
                model: '2 серия Active Tourer',
                generations: [
                    {
                        name: 'F45',
                        year: '2014…2018',
                    },
                    {
                        name: 'F45 · Рестайлинг',
                        year: '2018…2021',
                    },
                ],
            },
            {
                model: '2 серия Gran Tourer',
                generations: [
                    {
                        name: 'F46 · Рестайлинг',
                        year: '2018…2022',
                    },
                ],
            },
            {
                model: '3 серия',
                generations: [
                    {
                        name: 'E21',
                        year: '1975…1984',
                    },
                    {
                        name: 'E30',
                        year: '1982…1994',
                    },
                    {
                        name: 'E36',
                        year: '1990…2000',
                    },
                    {
                        name: 'E46',
                        year: '1998…2006',
                    },
                    {
                        name: 'E46 · Рестайлинг',
                        year: '2001…2007',
                    },
                    {
                        name: 'E90',
                        year: 'E91',
                    },
                    {
                        name: 'E90',
                        year: 'E91',
                    },
                    {
                        name: 'F30',
                        year: 'F31',
                    },
                    {
                        name: 'F34 (GT)',
                        year: '2013…',
                    },
                    {
                        name: 'F30,F31 · Рестайлинг',
                        year: '2015…2019',
                    },
                    {
                        name: 'G20',
                        year: '2018…',
                    },
                ],
            },
            {
                model: '340 Baumaster',
                generations: [],
            },
            {
                model: '4 серия',
                generations: [
                    {
                        name: 'F32',
                        year: 'F33',
                    },
                    {
                        name: 'F36 (GT)',
                        year: '2013…2017',
                    },
                    {
                        name: 'F32',
                        year: 'F33 (Рестайлинг)',
                    },
                    {
                        name: 'F36 (GT) (Рестайлинг)',
                        year: '2017…',
                    },
                    {
                        name: 'G22',
                        year: 'G23',
                    },
                ],
            },
            {
                model: '5 серия',
                generations: [
                    {
                        name: 'F32',
                        year: 'F33',
                    },
                    {
                        name: 'F36 (GT)',
                        year: '2013…2017',
                    },
                    {
                        name: 'F32',
                        year: 'F33 (Рестайлинг)',
                    },
                    {
                        name: 'F36 (GT) (Рестайлинг)',
                        year: '2017…',
                    },
                    {
                        name: 'G22',
                        year: 'G23',
                    },
                ],
            },
            {
                model: '6 серия',
                generations: [
                    {
                        name: 'E24',
                        year: '1976…1989',
                    },
                    {
                        name: 'E63',
                        year: 'E64',
                    },
                    {
                        name: 'E63',
                        year: 'E64 (Рестайлинг)',
                    },
                    {
                        name: 'F12',
                        year: 'F13',
                    },
                    {
                        name: 'F12',
                        year: 'F13',
                    },
                    {
                        name: 'G32',
                        year: '2017…',
                    },
                ],
            },
            {
                model: '7 серия',
                generations: [
                    {
                        name: 'E23',
                        year: '1977…1986',
                    },
                    {
                        name: 'E32',
                        year: '1986…1994',
                    },
                    {
                        name: 'E38',
                        year: '1994…2001',
                    },
                    {
                        name: 'E65',
                        year: '2001…2008',
                    },
                    {
                        name: 'E66 (Long)',
                        year: '2001…2008',
                    },
                    {
                        name: 'F01',
                        year: 'F04',
                    },
                    {
                        name: 'F02 (Long)',
                        year: '2008…2015',
                    },
                    {
                        name: 'F01',
                        year: 'F04 · Рестайлинг',
                    },
                    {
                        name: 'F02 (Long) · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'G11',
                        year: '2015…2020',
                    },
                    {
                        name: 'G12 (Long)',
                        year: '2015…2020',
                    },
                    {
                        name: 'G11 · Рестайлинг',
                        year: '2019…',
                    },
                    {
                        name: 'G12 (Long) · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: '8 серия',
                generations: [
                    {
                        name: 'G14',
                        year: 'G15',
                    },
                ],
            },
            {
                model: 'i3',
                generations: [
                    {
                        name: 'I01',
                        year: '2013…2017',
                    },
                    {
                        name: 'I01 (Рестайлинг)',
                        year: '2017…2022',
                    },
                ],
            },
            {
                model: 'iX',
                generations: [
                    {
                        name: 'I01',
                        year: '2013…2017',
                    },
                    {
                        name: 'I01 (Рестайлинг)',
                        year: '2017…2022',
                    },
                ],
            },
            {
                model: 'M3',
                generations: [
                    {
                        name: 'E90',
                        year: 'E92',
                    },
                    {
                        name: 'F80',
                        year: '2013…2020',
                    },
                    {
                        name: 'G80',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'M4',
                generations: [
                    {
                        name: 'F82',
                        year: 'F83',
                    },
                    {
                        name: 'F82',
                        year: 'F83 · Рестайлинг',
                    },
                    {
                        name: 'G82',
                        year: 'G83',
                    },
                ],
            },
            {
                model: 'M5',
                generations: [
                    {
                        name: 'E60',
                        year: 'E61',
                    },
                    {
                        name: 'F10',
                        year: '2011…2013',
                    },
                    {
                        name: 'F10 · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'F90',
                        year: '2017…2020',
                    },
                    {
                        name: 'F90 · Рестайлинг',
                        year: '2020…2022',
                    },
                ],
            },
            {
                model: 'M6',
                generations: [
                    {
                        name: 'E63',
                        year: 'E64',
                    },
                    {
                        name: 'F06',
                        year: 'F12',
                    },
                ],
            },
            {
                model: 'X1',
                generations: [
                    {
                        name: 'E84',
                        year: '2009…2012',
                    },
                    {
                        name: 'E84 · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'F48',
                        year: '2015…2019',
                    },
                    {
                        name: 'F48 · Рестайлинг',
                        year: '2019…2022',
                    },
                ],
            },
            {
                model: 'X2',
                generations: [],
            },
            {
                model: 'X3',
                generations: [
                    {
                        name: 'E83',
                        year: '2003…2006',
                    },
                    {
                        name: 'E83 · Рестайлинг',
                        year: '2006…2010',
                    },
                    {
                        name: 'F25',
                        year: '2010…2014',
                    },
                    {
                        name: 'F25 · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'G01',
                        year: '2017…2021',
                    },
                    {
                        name: 'G01 · Рестайлинг',
                        year: '2021…',
                    },
                ],
            },
            {
                model: 'X4',
                generations: [
                    {
                        name: 'F26',
                        year: '2014…2018',
                    },
                    {
                        name: 'G02',
                        year: '2018…2021',
                    },
                ],
            },
            {
                model: 'X4M',
                generations: [],
            },
            {
                model: 'X5',
                generations: [
                    {
                        name: 'E53',
                        year: '1999…2006',
                    },
                    {
                        name: 'E70',
                        year: '2006…2010',
                    },
                    {
                        name: 'E70 · Рестайлинг',
                        year: '2010…2013',
                    },
                    {
                        name: 'F15',
                        year: '2013…2018',
                    },
                    {
                        name: 'G05',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'X5M',
                generations: [
                    {
                        name: 'E70',
                        year: '2009…2013',
                    },
                    {
                        name: 'F85',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'X6',
                generations: [
                    {
                        name: 'E71',
                        year: '2008…2012',
                    },
                    {
                        name: 'E71 · Рестайлинг',
                        year: '2012…2014',
                    },
                    {
                        name: 'F16',
                        year: '2014…2020',
                    },
                    {
                        name: 'G06',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'X6M',
                generations: [
                    {
                        name: 'E71',
                        year: '2009…2012',
                    },
                    {
                        name: 'E71 (Рестайлинг)',
                        year: '2012…2014',
                    },
                    {
                        name: 'F86',
                        year: '2014…2019',
                    },
                    {
                        name: 'F96',
                        year: '2019…2021',
                    },
                ],
            },
            {
                model: 'X7',
                generations: [],
            },
            {
                model: 'Z3',
                generations: [
                    {
                        name: 'E36',
                        year: '1995…2000',
                    },
                ],
            },
            {
                model: 'Z4',
                generations: [
                    {
                        name: 'E85',
                        year: '2002…2005',
                    },
                    {
                        name: 'E85',
                        year: 'E86 (Рестайлинг)',
                    },
                    {
                        name: 'E89',
                        year: '2009…2013',
                    },
                    {
                        name: 'G29',
                        year: '2018…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Brilliance',
        models: [
            {
                model: 'BS4',
                generations: [],
            },
            {
                model: 'V5',
                generations: [],
            },
        ],
    },
    {
        brand: 'Buick',
        models: [
            {
                model: 'Cascada',
                generations: [],
            },
            {
                model: 'Enclave',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2013',
                    },
                ],
            },
            {
                model: 'Encore',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…2021',
                    },
                    {
                        name: 'II',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Encore GX',
                generations: [
                    {
                        name: 'I',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Envision',
                generations: [
                    {
                        name: 'I',
                        year: '2014…2018',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…2020',
                    },
                ],
            },
            {
                model: 'LaCrosse',
                generations: [
                    {
                        name: 'II',
                        year: '2009…2013',
                    },
                ],
            },
            {
                model: 'Regal',
                generations: [
                    {
                        name: 'V',
                        year: '2008…2013',
                    },
                    {
                        name: 'VI',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'RendezVous',
                generations: [],
            },
            {
                model: 'Riviera',
                generations: [
                    {
                        name: 'VIII',
                        year: '1995…1999',
                    },
                ],
            },
            {
                model: 'Velite 6',
                generations: [],
            },
        ],
    },
    {
        brand: 'BYD',
        models: [
            {
                model: 'e2',
                generations: [],
            },
            {
                model: 'e5',
                generations: [],
            },
            {
                model: 'E6',
                generations: [],
            },
            {
                model: 'Han EV',
                generations: [],
            },
            {
                model: 'Song',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Song Plus EV',
                generations: [],
            },
            {
                model: 'Tang',
                generations: [
                    {
                        name: 'II',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Yuan',
                generations: [
                    {
                        name: 'I',
                        year: '2016…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Cadillac',
        models: [
            {
                model: 'ATS',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2019',
                    },
                ],
            },
            {
                model: 'BLS',
                generations: [],
            },
            {
                model: 'Brougham',
                generations: [],
            },
            {
                model: 'CT6',
                generations: [],
            },
            {
                model: 'CTS',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2014',
                    },
                    {
                        name: 'III',
                        year: '2014…2020',
                    },
                ],
            },
            {
                model: 'De Ville',
                generations: [
                    {
                        name: 'XI',
                        year: '1999…2006',
                    },
                ],
            },
            {
                model: 'DTS',
                generations: [],
            },
            {
                model: 'ELR',
                generations: [],
            },
            {
                model: 'Escalade',
                generations: [
                    {
                        name: 'II',
                        year: '2002…2006',
                    },
                    {
                        name: 'III',
                        year: '2007…2014',
                    },
                    {
                        name: 'IV',
                        year: '2014…2020',
                    },
                ],
            },
            {
                model: 'SRX',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2009',
                    },
                    {
                        name: 'II',
                        year: '2009…2012',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2012…2017',
                    },
                ],
            },
            {
                model: 'STS',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2011',
                    },
                ],
            },
            {
                model: 'XT4',
                generations: [],
            },
            {
                model: 'XT5',
                generations: [],
            },
        ],
    },
    {
        brand: 'Changan',
        models: [
            {
                model: 'Auchan A600 EV',
                generations: [],
            },
            {
                model: 'CS35',
                generations: [],
            },
            {
                model: 'CS55',
                generations: [],
            },
            {
                model: 'CS75 FL',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Chery',
        models: [
            {
                model: 'A1',
                generations: [],
            },
            {
                model: 'A13',
                generations: [],
            },
            {
                model: 'Amulet',
                generations: [],
            },
            {
                model: 'Cowin',
                generations: [],
            },
            {
                model: 'CrossEastar',
                generations: [],
            },
            {
                model: 'Fora',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2010',
                    },
                ],
            },
            {
                model: 'Indis',
                generations: [],
            },
            {
                model: 'M11',
                generations: [],
            },
            {
                model: 'QQ',
                generations: [],
            },
            {
                model: 'QQ6',
                generations: [],
            },
            {
                model: 'Tiggo',
                generations: [
                    {
                        name: 'T11',
                        year: '2005…2014',
                    },
                    {
                        name: 'FL',
                        year: '2011…2016',
                    },
                ],
            },
            {
                model: 'Tiggo 3',
                generations: [],
            },
            {
                model: 'Tiggo 4',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Tiggo 5',
                generations: [
                    {
                        name: 'I',
                        year: '2014…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Tiggo 7 PRO',
                generations: [],
            },
            {
                model: 'Tiggo 8',
                generations: [],
            },
            {
                model: 'Very',
                generations: [],
            },
        ],
    },
    {
        brand: 'Chevrolet',
        models: [
            {
                model: 'Alero',
                generations: [],
            },
            {
                model: 'Avalanche',
                generations: [
                    {
                        name: 'II',
                        year: '2006…2013',
                    },
                ],
            },
            {
                model: 'Aveo',
                generations: [
                    {
                        name: 'T200',
                        year: '2003…2008',
                    },
                    {
                        name: 'T250 · Рестайлинг',
                        year: '2006…2012',
                    },
                    {
                        name: 'T300',
                        year: '2011…2017',
                    },
                ],
            },
            {
                model: 'Blazer',
                generations: [
                    {
                        name: 'II',
                        year: '1995…1997',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1997…2005',
                    },
                ],
            },
            {
                model: 'Bolt',
                generations: [],
            },
            {
                model: 'C/K',
                generations: [],
            },
            {
                model: 'Camaro',
                generations: [
                    {
                        name: 'IV · Рестайлинг',
                        year: '1998…2002',
                    },
                    {
                        name: 'V',
                        year: '2008…2014',
                    },
                    {
                        name: 'VI',
                        year: '2015…2018',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Captiva',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2011',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…2013',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2013…2015',
                    },
                    {
                        name: 'I · 3-й рестайлинг',
                        year: '2015…2018',
                    },
                ],
            },
            {
                model: 'Cavalier',
                generations: [
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2002…2005',
                    },
                ],
            },
            {
                model: 'Cobalt',
                generations: [
                    {
                        name: 'II',
                        year: '2011…2020',
                    },
                ],
            },
            {
                model: 'Corvette',
                generations: [
                    {
                        name: 'C7',
                        year: '2013…2020',
                    },
                ],
            },
            {
                model: 'Cruze',
                generations: [
                    {
                        name: 'J300',
                        year: '2009…2012',
                    },
                    {
                        name: 'J300 · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'D2LC',
                        year: '2015…2018',
                    },
                    {
                        name: 'D2LC · Рестайлинг',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Epica',
                generations: [
                    {
                        name: 'V250',
                        year: '2006…2009',
                    },
                    {
                        name: 'V250 · Рестайлинг',
                        year: '2008…2012',
                    },
                ],
            },
            {
                model: 'Equinox',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2009',
                    },
                    {
                        name: 'II',
                        year: '2009…2015',
                    },
                    {
                        name: 'III',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Evanda',
                generations: [],
            },
            {
                model: 'Express',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2020',
                    },
                ],
            },
            {
                model: 'HHR',
                generations: [],
            },
            {
                model: 'Kalos',
                generations: [],
            },
            {
                model: 'Lacetti',
                generations: [],
            },
            {
                model: 'Lanos',
                generations: [],
            },
            {
                model: 'Lumina',
                generations: [
                    {
                        name: 'I',
                        year: '1990…1994',
                    },
                ],
            },
            {
                model: 'Lumina APV',
                generations: [
                    {
                        name: 'I',
                        year: '1989…1993',
                    },
                    {
                        name: 'II',
                        year: '1993…1996',
                    },
                ],
            },
            {
                model: 'Malibu',
                generations: [
                    {
                        name: 'VIII',
                        year: '2011…2014',
                    },
                    {
                        name: 'VIII · Рестайлинг',
                        year: '2013…2016',
                    },
                    {
                        name: 'IX',
                        year: '2015…2018',
                    },
                    {
                        name: 'IX · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Matiz',
                generations: [
                    {
                        name: 'II',
                        year: '2005…2010',
                    },
                ],
            },
            {
                model: 'Metro',
                generations: [],
            },
            {
                model: 'Niva',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2020',
                    },
                ],
            },
            {
                model: 'Nubira',
                generations: [],
            },
            {
                model: 'Orlando',
                generations: [],
            },
            {
                model: 'Silverado',
                generations: [
                    {
                        name: 'GMTK2XX · Рестайлинг',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Spark',
                generations: [
                    {
                        name: 'M200',
                        year: '2005…2010',
                    },
                    {
                        name: 'M300',
                        year: '2009…2015',
                    },
                ],
            },
            {
                model: 'Suburban',
                generations: [
                    {
                        name: 'IX',
                        year: '1991…2001',
                    },
                ],
            },
            {
                model: 'Tahoe',
                generations: [
                    {
                        name: 'GMT800',
                        year: '1999…2006',
                    },
                    {
                        name: 'GMT900',
                        year: '2006…2014',
                    },
                    {
                        name: 'GMT K2UC',
                        year: 'G',
                    },
                ],
            },
            {
                model: 'Tracker',
                generations: [
                    {
                        name: 'II',
                        year: '1998…2004',
                    },
                ],
            },
            {
                model: 'TrailBlazer',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2010',
                    },
                    {
                        name: 'II',
                        year: '2012…2016',
                    },
                    {
                        name: 'III',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Traverse',
                generations: [
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Trax',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Van',
                generations: [
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '1982…1991',
                    },
                ],
            },
            {
                model: 'Volt',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2015',
                    },
                    {
                        name: 'II',
                        year: '2015…2019',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Chrysler',
        models: [
            {
                model: '200',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2014',
                    },
                ],
            },
            {
                model: '300М',
                generations: [],
            },
            {
                model: '300С',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2012',
                    },
                ],
            },
            {
                model: 'Aspen',
                generations: [],
            },
            {
                model: 'Cirrus',
                generations: [],
            },
            {
                model: 'Concorde',
                generations: [
                    {
                        name: 'I',
                        year: '1992…1997',
                    },
                    {
                        name: 'II',
                        year: '1998…2001',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2001…2004',
                    },
                ],
            },
            {
                model: 'Crossfire',
                generations: [],
            },
            {
                model: 'Grand Voyager',
                generations: [
                    {
                        name: 'II',
                        year: '1990…1995',
                    },
                    {
                        name: 'III',
                        year: '1995…2000',
                    },
                    {
                        name: 'IV',
                        year: '2000…2004',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2004…2007',
                    },
                    {
                        name: 'V',
                        year: '2007…2010',
                    },
                ],
            },
            {
                model: 'Intrepid',
                generations: [
                    {
                        name: 'II',
                        year: '1998…2004',
                    },
                ],
            },
            {
                model: 'Le Baron',
                generations: [
                    {
                        name: 'III',
                        year: '1987…1995',
                    },
                ],
            },
            {
                model: 'LHS',
                generations: [
                    {
                        name: 'II',
                        year: '1999…2001',
                    },
                ],
            },
            {
                model: 'Neon',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1999',
                    },
                    {
                        name: 'II',
                        year: '1999…2005',
                    },
                ],
            },
            {
                model: 'Pacifica',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2007',
                    },
                    {
                        name: 'RU',
                        year: '2016…2020',
                    },
                    {
                        name: 'RU · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'PT Cruiser',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2010',
                    },
                ],
            },
            {
                model: 'Saratoga',
                generations: [],
            },
            {
                model: 'Sebring',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2000',
                    },
                    {
                        name: 'II',
                        year: '2000…2003',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'III',
                        year: '2006…2010',
                    },
                ],
            },
            {
                model: 'Stratus',
                generations: [],
            },
            {
                model: 'Town and Country',
                generations: [
                    {
                        name: 'III',
                        year: '1996…2000',
                    },
                    {
                        name: 'IV',
                        year: '2001…2004',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2004…2007',
                    },
                    {
                        name: 'V',
                        year: '2007…2010',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2010…2016',
                    },
                ],
            },
            {
                model: 'Vision',
                generations: [],
            },
            {
                model: 'Voyager',
                generations: [
                    {
                        name: 'I',
                        year: '1984…1990',
                    },
                    {
                        name: 'II',
                        year: '1991…1995',
                    },
                    {
                        name: 'III',
                        year: '1995…2000',
                    },
                    {
                        name: 'IV',
                        year: '2000…2004',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2004…2007',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Citroen',
        models: [
            {
                model: 'AX',
                generations: [],
            },
            {
                model: 'Berlingo',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2002',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2012',
                    },
                    {
                        name: 'II',
                        year: '2008…2012',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2015…2022',
                    },
                ],
            },
            {
                model: 'C-Crosser',
                generations: [],
            },
            {
                model: 'C-Elysee',
                generations: [],
            },
            {
                model: 'C-Zero',
                generations: [],
            },
            {
                model: 'C1',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2008',
                    },
                ],
            },
            {
                model: 'C15',
                generations: [],
            },
            {
                model: 'C2',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2008',
                    },
                ],
            },
            {
                model: 'C3',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2010',
                    },
                    {
                        name: 'II',
                        year: '2009…2013',
                    },
                    {
                        name: 'III',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'C3 Aircross',
                generations: [
                    {
                        name: 'I',
                        year: '2017…2021',
                    },
                ],
            },
            {
                model: 'C3 Picasso',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                ],
            },
            {
                model: 'C3 Pluriel',
                generations: [],
            },
            {
                model: 'C4',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2008',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2022',
                    },
                ],
            },
            {
                model: 'C4 Aircross',
                generations: [],
            },
            {
                model: 'C4 Cactus',
                generations: [
                    {
                        name: 'I',
                        year: '2014…',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'C4 Grand Picasso',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2013',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'C4 Picasso',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2013',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'C4 Spacetourer',
                generations: [],
            },
            {
                model: 'C5',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2015',
                    },
                ],
            },
            {
                model: 'C5 Aircross',
                generations: [],
            },
            {
                model: 'C6',
                generations: [],
            },
            {
                model: 'C8',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2014',
                    },
                ],
            },
            {
                model: 'DS3',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2014',
                    },
                ],
            },
            {
                model: 'DS4',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2016',
                    },
                ],
            },
            {
                model: 'DS5',
                generations: [],
            },
            {
                model: 'DS7',
                generations: [],
            },
            {
                model: 'Evasion',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1997',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1997…2002',
                    },
                ],
            },
            {
                model: 'Jumpy',
                generations: [
                    {
                        name: 'I',
                        year: '1994…2004',
                    },
                    {
                        name: 'II',
                        year: '2007…2012',
                    },
                ],
            },
            {
                model: 'Nemo',
                generations: [],
            },
            {
                model: 'Saxo',
                generations: [
                    {
                        name: 'I',
                        year: '1996…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2004',
                    },
                ],
            },
            {
                model: 'SpaceTourer',
                generations: [],
            },
            {
                model: 'Xantia',
                generations: [
                    {
                        name: 'X1',
                        year: '1992…1998',
                    },
                    {
                        name: 'X2',
                        year: '1998…2002',
                    },
                ],
            },
            {
                model: 'XM',
                generations: [
                    {
                        name: 'Y3',
                        year: '1989…1994',
                    },
                    {
                        name: 'Y4',
                        year: '1994…2000',
                    },
                ],
            },
            {
                model: 'Xsara',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2003',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2003…2006',
                    },
                ],
            },
            {
                model: 'Xsara Picasso',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2004',
                    },
                    {
                        name: 'II',
                        year: '2004…2012',
                    },
                ],
            },
            {
                model: 'ZX',
                generations: [],
            },
        ],
    },
    {
        brand: 'Dacia',
        models: [
            {
                model: '1300',
                generations: [],
            },
            {
                model: '1310',
                generations: [],
            },
            {
                model: '1410',
                generations: [],
            },
            {
                model: 'Dokker',
                generations: [],
            },
            {
                model: 'Lodgy',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2017',
                    },
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Logan',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2008',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2012',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'Sandero',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Daewoo',
        models: [
            {
                model: 'Espero',
                generations: [
                    {
                        name: 'KLEJ · Рестайлинг',
                        year: '1993…1999',
                    },
                ],
            },
            {
                model: 'Evanda',
                generations: [],
            },
            {
                model: 'Gentra',
                generations: [
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                ],
            },
            {
                model: 'Kalos',
                generations: [],
            },
            {
                model: 'Lanos',
                generations: [
                    {
                        name: 'T100',
                        year: '1997…2009',
                    },
                ],
            },
            {
                model: 'Leganza',
                generations: [],
            },
            {
                model: 'Matiz',
                generations: [
                    {
                        name: 'M100',
                        year: '1998…2001',
                    },
                    {
                        name: 'M150 · Рестайлинг',
                        year: '2000…2016',
                    },
                    {
                        name: 'M200',
                        year: '2005…2007',
                    },
                    {
                        name: 'M250 · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'M300',
                        year: '2009…2011',
                    },
                ],
            },
            {
                model: 'Nexia',
                generations: [
                    {
                        name: 'I',
                        year: '1994…2002',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2008',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2008…2016',
                    },
                ],
            },
            {
                model: 'Nubira',
                generations: [
                    {
                        name: 'J100',
                        year: '1997…1999',
                    },
                    {
                        name: 'J150',
                        year: 'J190 · Рестайлинг',
                    },
                    {
                        name: 'J200',
                        year: '2002…2008',
                    },
                ],
            },
            {
                model: 'Rezzo',
                generations: [],
            },
            {
                model: 'Tacuma',
                generations: [],
            },
            {
                model: 'Tico',
                generations: [],
            },
        ],
    },
    {
        brand: 'Daihatsu',
        models: [
            {
                model: 'Applause',
                generations: [
                    {
                        name: 'I',
                        year: '1989…1992',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1997…2000',
                    },
                ],
            },
            {
                model: 'Charade',
                generations: [
                    {
                        name: 'III',
                        year: '1987…1993',
                    },
                ],
            },
            {
                model: 'Cuore',
                generations: [
                    {
                        name: 'L700',
                        year: '1998…2003',
                    },
                    {
                        name: 'L251',
                        year: '2003…2007',
                    },
                    {
                        name: 'L276',
                        year: '2007…2012',
                    },
                ],
            },
            {
                model: 'Feroza',
                generations: [],
            },
            {
                model: 'Gran Move',
                generations: [],
            },
            {
                model: 'Hijet',
                generations: [],
            },
            {
                model: 'Move',
                generations: [
                    {
                        name: 'L900',
                        year: '1998…2002',
                    },
                ],
            },
            {
                model: 'Sirion',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2001',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2001…2004',
                    },
                    {
                        name: 'II',
                        year: '2005…2007',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2007…2013',
                    },
                ],
            },
            {
                model: 'Terios',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2000',
                    },
                    {
                        name: 'II',
                        year: '2006…2009',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2009…',
                    },
                ],
            },
            {
                model: 'YRV',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2006',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Datsun',
        models: [
            {
                model: 'on-DO',
                generations: [
                    {
                        name: 'I',
                        year: '2014…2019',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2019…2020',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Derways',
        models: [
            {
                model: 'Cowboy',
                generations: [],
            },
        ],
    },
    {
        brand: 'Dodge',
        models: [
            {
                model: 'Avenger',
                generations: [
                    {
                        name: 'II',
                        year: '2007…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2011…2014',
                    },
                ],
            },
            {
                model: 'Caliber',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2012',
                    },
                ],
            },
            {
                model: 'Caravan',
                generations: [
                    {
                        name: 'II',
                        year: '1990…1995',
                    },
                    {
                        name: 'III',
                        year: '1995…2000',
                    },
                    {
                        name: 'IV',
                        year: '2000…2007',
                    },
                ],
            },
            {
                model: 'Challenger',
                generations: [
                    {
                        name: 'III · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Charger',
                generations: [
                    {
                        name: 'LX',
                        year: '2005…2010',
                    },
                    {
                        name: 'LD · Рестайлинг',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Dakota',
                generations: [
                    {
                        name: 'II',
                        year: '1996…2004',
                    },
                    {
                        name: 'III',
                        year: '2004…2007',
                    },
                ],
            },
            {
                model: 'Dart',
                generations: [],
            },
            {
                model: 'Durango',
                generations: [
                    {
                        name: 'II',
                        year: '2004…2006',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2013…2020',
                    },
                ],
            },
            {
                model: 'Grand Caravan',
                generations: [
                    {
                        name: 'III',
                        year: '1995…2000',
                    },
                    {
                        name: 'IV',
                        year: '2000…2007',
                    },
                    {
                        name: 'V',
                        year: '2007…2010',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2010…2022',
                    },
                ],
            },
            {
                model: 'Intrepid',
                generations: [
                    {
                        name: 'II',
                        year: '1998…2004',
                    },
                ],
            },
            {
                model: 'Journey',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2011',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…2020',
                    },
                ],
            },
            {
                model: 'Magnum',
                generations: [],
            },
            {
                model: 'Neon',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1999',
                    },
                    {
                        name: 'II',
                        year: '2000…2002',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2003…2005',
                    },
                ],
            },
            {
                model: 'Nitro',
                generations: [],
            },
            {
                model: 'RAM',
                generations: [
                    {
                        name: 'II',
                        year: '1994…2002',
                    },
                    {
                        name: 'III',
                        year: '2002…2005',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2005…2009',
                    },
                    {
                        name: 'IV',
                        year: '2008…',
                    },
                ],
            },
            {
                model: 'RAM ProMaster City',
                generations: [],
            },
            {
                model: 'Ram van',
                generations: [
                    {
                        name: 'III',
                        year: '1993…2003',
                    },
                ],
            },
            {
                model: 'Stratus',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2000',
                    },
                    {
                        name: 'II',
                        year: '2000…2003',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2003…2006',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Dongfeng',
        models: [
            {
                model: 'AX7',
                generations: [],
            },
            {
                model: 'E11K',
                generations: [],
            },
            {
                model: 'H30',
                generations: [],
            },
        ],
    },
    {
        brand: 'Dongfeng Honda',
        models: [
            {
                model: 'M-NV',
                generations: [],
            },
        ],
    },
    {
        brand: 'FAW',
        models: [
            {
                model: 'Admiral',
                generations: [],
            },
            {
                model: 'V5',
                generations: [],
            },
        ],
    },
    {
        brand: 'Ferrari',
        models: [
            {
                model: '360',
                generations: [],
            },
            {
                model: '488 GTB',
                generations: [],
            },
        ],
    },
    {
        brand: 'Fiat',
        models: [
            {
                model: '500',
                generations: [
                    {
                        name: 'II',
                        year: '2007…2015',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2015…',
                    },
                ],
            },
            {
                model: '500L',
                generations: [],
            },
            {
                model: '500X',
                generations: [],
            },
            {
                model: '500е',
                generations: [],
            },
            {
                model: 'Albea',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2012',
                    },
                ],
            },
            {
                model: 'Brava',
                generations: [],
            },
            {
                model: 'Bravo',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2001',
                    },
                    {
                        name: 'II',
                        year: '2007…2014',
                    },
                ],
            },
            {
                model: 'Cinquecento',
                generations: [],
            },
            {
                model: 'Coupe',
                generations: [],
            },
            {
                model: 'Croma',
                generations: [
                    {
                        name: 'I',
                        year: '1985…1996',
                    },
                    {
                        name: 'II',
                        year: '2005…2008',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2008…2011',
                    },
                ],
            },
            {
                model: 'Doblo',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2009',
                    },
                    {
                        name: 'II',
                        year: '2009…2015',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Fiorino',
                generations: [
                    {
                        name: 'II',
                        year: '1988…2004',
                    },
                    {
                        name: 'III',
                        year: '2007…2022',
                    },
                ],
            },
            {
                model: 'Freemont',
                generations: [],
            },
            {
                model: 'Grande Punto',
                generations: [],
            },
            {
                model: 'Idea',
                generations: [],
            },
            {
                model: 'Linea',
                generations: [],
            },
            {
                model: 'Marea',
                generations: [],
            },
            {
                model: 'Multipla',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2010',
                    },
                ],
            },
            {
                model: 'Palio',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2004',
                    },
                ],
            },
            {
                model: 'Panda',
                generations: [
                    {
                        name: 'II',
                        year: '2003…2011',
                    },
                ],
            },
            {
                model: 'Punto',
                generations: [
                    {
                        name: 'I',
                        year: '1993…1999',
                    },
                    {
                        name: 'II',
                        year: '1999…2003',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2003…2010',
                    },
                    {
                        name: 'III',
                        year: '2005…2012',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2012…2020',
                    },
                ],
            },
            {
                model: 'Punto Evo',
                generations: [],
            },
            {
                model: 'Qubo',
                generations: [],
            },
            {
                model: 'Scudo',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2016',
                    },
                ],
            },
            {
                model: 'Seicento',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2005',
                    },
                ],
            },
            {
                model: 'Siena',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2001',
                    },
                ],
            },
            {
                model: 'Stilo',
                generations: [],
            },
            {
                model: 'Talento',
                generations: [
                    {
                        name: 'II',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Tempra',
                generations: [],
            },
            {
                model: 'Tipo',
                generations: [
                    {
                        name: 'I',
                        year: '1987…1995',
                    },
                    {
                        name: 'II',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'Ulysse',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1998',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1998…2002',
                    },
                    {
                        name: 'II',
                        year: '2002…2010',
                    },
                ],
            },
            {
                model: 'Uno',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1989…2002',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Ford',
        models: [
            {
                model: 'Aerostar',
                generations: [],
            },
            {
                model: 'B-MAX',
                generations: [],
            },
            {
                model: 'C-MAX',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2015',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Contоur',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1997…2000',
                    },
                ],
            },
            {
                model: 'Cougar',
                generations: [],
            },
            {
                model: 'Courier',
                generations: [
                    {
                        name: 'I',
                        year: '1991…1996',
                    },
                    {
                        name: 'II',
                        year: '1996…2002',
                    },
                ],
            },
            {
                model: 'Crown Victoria',
                generations: [
                    {
                        name: 'I',
                        year: '1990…1999',
                    },
                ],
            },
            {
                model: 'Econoline',
                generations: [],
            },
            {
                model: 'EcoSport',
                generations: [
                    {
                        name: 'II · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Edge',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…2015',
                    },
                    {
                        name: 'II',
                        year: '2015…2018',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Escape',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2006',
                    },
                    {
                        name: 'II',
                        year: '2007…2012',
                    },
                    {
                        name: 'III',
                        year: '2012…2016',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2016…2019',
                    },
                    {
                        name: 'IV',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Escort',
                generations: [
                    {
                        name: 'II · Рестайлинг',
                        year: '1977…1980',
                    },
                    {
                        name: 'III',
                        year: '1980…1986',
                    },
                    {
                        name: 'IV',
                        year: '1986…1995',
                    },
                    {
                        name: 'V',
                        year: '1990…1992',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '1992…1995',
                    },
                    {
                        name: 'V · 2-й рестайлинг',
                        year: '1995…2000',
                    },
                    {
                        name: 'III (USA)',
                        year: '1996…2003',
                    },
                ],
            },
            {
                model: 'Excursion',
                generations: [],
            },
            {
                model: 'Expedition',
                generations: [
                    {
                        name: 'I',
                        year: '1996…1998',
                    },
                    {
                        name: 'II',
                        year: '2002…2006',
                    },
                    {
                        name: 'IV',
                        year: '2017…2022',
                    },
                ],
            },
            {
                model: 'Explorer',
                generations: [
                    {
                        name: 'I',
                        year: '1990…1995',
                    },
                    {
                        name: 'II',
                        year: '1995…1999',
                    },
                    {
                        name: 'III',
                        year: '2002…2006',
                    },
                    {
                        name: 'IV',
                        year: '2006…2010',
                    },
                    {
                        name: 'V',
                        year: '2010…2015',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2015…2017',
                    },
                    {
                        name: 'V · 2-й рестайлинг',
                        year: '2017…2019',
                    },
                    {
                        name: 'VI',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'F-150',
                generations: [
                    {
                        name: 'X',
                        year: '1996…2004',
                    },
                    {
                        name: 'XI',
                        year: '2004…2008',
                    },
                    {
                        name: 'XII',
                        year: '2009…2014',
                    },
                    {
                        name: 'XIII',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'F-250',
                generations: [
                    {
                        name: 'X',
                        year: '1997…2003',
                    },
                    {
                        name: 'XII',
                        year: '2009…2014',
                    },
                ],
            },
            {
                model: 'F-350',
                generations: [
                    {
                        name: 'XI',
                        year: '2004…2008',
                    },
                ],
            },
            {
                model: 'Festiva',
                generations: [
                    {
                        name: 'II',
                        year: '1993…1997',
                    },
                ],
            },
            {
                model: 'Fiesta',
                generations: [
                    {
                        name: 'I',
                        year: '1976…1983',
                    },
                    {
                        name: 'III',
                        year: '1989…1996',
                    },
                    {
                        name: 'IV',
                        year: '1995…1999',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '1999…2002',
                    },
                    {
                        name: 'V',
                        year: '2001…2007',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2005…2008',
                    },
                    {
                        name: 'VI',
                        year: '2008…2013',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2013…2020',
                    },
                    {
                        name: 'VII',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Flex',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2019',
                    },
                ],
            },
            {
                model: 'Focus',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2001',
                    },
                    {
                        name: 'I · (North America)',
                        year: '1999…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2001…2004',
                    },
                    {
                        name: 'II',
                        year: '2004…2008',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2008…2011',
                    },
                    {
                        name: 'III',
                        year: '2011…2014',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'IV',
                        year: '2018…2022',
                    },
                ],
            },
            {
                model: 'Focus ST',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2005',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2015…2018',
                    },
                ],
            },
            {
                model: 'Freestyle',
                generations: [],
            },
            {
                model: 'Fusion',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2012',
                    },
                ],
            },
            {
                model: 'Fusion USA',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2012',
                    },
                    {
                        name: 'II',
                        year: '2012…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Galaxy',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2006',
                    },
                    {
                        name: 'II',
                        year: '2006…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'III',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Granada',
                generations: [
                    {
                        name: 'II',
                        year: '1977…1985',
                    },
                ],
            },
            {
                model: 'Grand C-Max',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2015',
                    },
                    {
                        name: 'II',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Ka',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2008',
                    },
                ],
            },
            {
                model: 'Kuga',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2013',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2019',
                    },
                ],
            },
            {
                model: 'Maverick',
                generations: [
                    {
                        name: 'I',
                        year: '1993…1996',
                    },
                    {
                        name: 'II',
                        year: '2000…2004',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2004…2007',
                    },
                ],
            },
            {
                model: 'Mondeo',
                generations: [
                    {
                        name: 'I',
                        year: '1993…1996',
                    },
                    {
                        name: 'II',
                        year: '1996…2000',
                    },
                    {
                        name: 'III',
                        year: '2000…2003',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2003…2007',
                    },
                    {
                        name: 'IV',
                        year: '2007…2010',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'V',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'Mustang',
                generations: [
                    {
                        name: 'III',
                        year: '1978…1986',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '1998…2004',
                    },
                    {
                        name: 'V',
                        year: '2004…2009',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2009…2014',
                    },
                    {
                        name: 'VI',
                        year: '2014…2017',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Orion',
                generations: [
                    {
                        name: 'II',
                        year: '1986…1990',
                    },
                    {
                        name: 'III',
                        year: '1990…1994',
                    },
                ],
            },
            {
                model: 'Probe',
                generations: [
                    {
                        name: 'II',
                        year: '1993…1998',
                    },
                ],
            },
            {
                model: 'Puma',
                generations: [],
            },
            {
                model: 'Ranger',
                generations: [
                    {
                        name: 'V',
                        year: '2006…2009',
                    },
                ],
            },
            {
                model: 'S-MAX',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'II',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Scorpio',
                generations: [
                    {
                        name: 'I',
                        year: '1985…1992',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1992…1994',
                    },
                    {
                        name: 'II',
                        year: '1994…1998',
                    },
                ],
            },
            {
                model: 'Sierra',
                generations: [
                    {
                        name: 'I',
                        year: '1982…1987',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1987…1993',
                    },
                ],
            },
            {
                model: 'Taunus',
                generations: [
                    {
                        name: 'I',
                        year: '1970…1976',
                    },
                ],
            },
            {
                model: 'Taurus',
                generations: [
                    {
                        name: 'II',
                        year: '1991…1995',
                    },
                    {
                        name: 'III',
                        year: '1995…1999',
                    },
                    {
                        name: 'IV',
                        year: '2000…2007',
                    },
                ],
            },
            {
                model: 'Taurus X',
                generations: [],
            },
            {
                model: 'Tourneo Connect',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2013',
                    },
                    {
                        name: 'II',
                        year: '2013…2018',
                    },
                ],
            },
            {
                model: 'Tourneo Courier',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Tourneo Custom',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Transit Connect',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2022',
                    },
                    {
                        name: 'II',
                        year: '2012…2018',
                    },
                ],
            },
            {
                model: 'Transit Custom',
                generations: [],
            },
            {
                model: 'Transit Tourneo',
                generations: [],
            },
            {
                model: 'Windstar',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1999',
                    },
                    {
                        name: 'II',
                        year: '1999…2003',
                    },
                ],
            },
        ],
    },
    {
        brand: 'FSO',
        models: [
            {
                model: 'Lanos',
                generations: [],
            },
            {
                model: 'Syrena',
                generations: [],
            },
        ],
    },
    {
        brand: 'GAC',
        models: [
            {
                model: 'Aion Y',
                generations: [],
            },
            {
                model: 'Trumpchi GE3',
                generations: [],
            },
        ],
    },
    {
        brand: 'Geely',
        models: [
            {
                model: 'Atlas',
                generations: [],
            },
            {
                model: 'Atlas Pro',
                generations: [],
            },
            {
                model: 'CK',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2008',
                    },
                ],
            },
            {
                model: 'Coolray',
                generations: [],
            },
            {
                model: 'Emgrand 7',
                generations: [
                    {
                        name: 'I',
                        year: '2016…2018',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…2020',
                    },
                ],
            },
            {
                model: 'Emgrand EC7',
                generations: [],
            },
            {
                model: 'Emgrand GT',
                generations: [
                    {
                        name: 'I',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Emgrand X7',
                generations: [
                    {
                        name: 'I',
                        year: '2013…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…2020',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Geometry A',
                generations: [
                    {
                        name: 'I',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Geometry C',
                generations: [],
            },
            {
                model: 'GS',
                generations: [],
            },
            {
                model: 'LC Cross',
                generations: [],
            },
            {
                model: 'MK',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2015',
                    },
                ],
            },
            {
                model: 'MK Cross',
                generations: [],
            },
            {
                model: 'SC7',
                generations: [],
            },
            {
                model: 'Tugella',
                generations: [
                    {
                        name: 'I',
                        year: '2019…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Genesis',
        models: [
            {
                model: 'G70',
                generations: [],
            },
            {
                model: 'G80',
                generations: [],
            },
            {
                model: 'G90',
                generations: [
                    {
                        name: 'I',
                        year: '2016…2019',
                    },
                ],
            },
        ],
    },
    {
        brand: 'GMC',
        models: [
            {
                model: 'Acadia',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2013',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Envoy',
                generations: [
                    {
                        name: 'II',
                        year: '2001…2009',
                    },
                ],
            },
            {
                model: 'Jimmy',
                generations: [],
            },
            {
                model: 'Safari',
                generations: [
                    {
                        name: 'I',
                        year: '1985…1994',
                    },
                    {
                        name: 'II',
                        year: '1995…2005',
                    },
                ],
            },
            {
                model: 'Sierra',
                generations: [
                    {
                        name: 'II',
                        year: '2007…2014',
                    },
                ],
            },
            {
                model: 'Terrain',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2015',
                    },
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Yukon',
                generations: [
                    {
                        name: 'GMT900',
                        year: '2006…2014',
                    },
                    {
                        name: 'IV',
                        year: '2014…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Great Wall',
        models: [
            {
                model: 'Deer',
                generations: [],
            },
            {
                model: 'Hover',
                generations: [],
            },
            {
                model: 'Hover H3',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2016',
                    },
                ],
            },
            {
                model: 'Hover H5',
                generations: [],
            },
            {
                model: 'Hover M4',
                generations: [],
            },
            {
                model: 'Peri',
                generations: [],
            },
            {
                model: 'Safe',
                generations: [],
            },
            {
                model: 'Wingle',
                generations: [
                    {
                        name: '3',
                        year: '2006…2012',
                    },
                    {
                        name: '5',
                        year: '2011…2015',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Hafei',
        models: [
            {
                model: 'Simbo',
                generations: [],
            },
        ],
    },
    {
        brand: 'Honda',
        models: [
            {
                model: 'Accord',
                generations: [
                    {
                        name: 'III',
                        year: '1985…1989',
                    },
                    {
                        name: 'IV',
                        year: '1989…1994',
                    },
                    {
                        name: 'V',
                        year: '1993…1995',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '1995…1998',
                    },
                    {
                        name: 'VI',
                        year: '1998…2000',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2000…2002',
                    },
                    {
                        name: 'VII',
                        year: '2002…2006',
                    },
                    {
                        name: 'VII · Рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'VIII',
                        year: '2008…2011',
                    },
                    {
                        name: 'VIII · Рестайлинг',
                        year: '2011…2013',
                    },
                    {
                        name: 'IX',
                        year: '2012…2015',
                    },
                    {
                        name: 'IX · Рестайлинг',
                        year: '2015…2017',
                    },
                    {
                        name: 'X',
                        year: '2017…2020',
                    },
                    {
                        name: 'X · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'City',
                generations: [
                    {
                        name: 'IV · Рестайлинг',
                        year: '2005…2008',
                    },
                    {
                        name: 'V',
                        year: '2008…2011',
                    },
                ],
            },
            {
                model: 'Civic',
                generations: [
                    {
                        name: 'III',
                        year: '1983…1987',
                    },
                    {
                        name: 'IV',
                        year: '1987…1991',
                    },
                    {
                        name: 'V',
                        year: '1991…1995',
                    },
                    {
                        name: 'VI',
                        year: '1995…2000',
                    },
                    {
                        name: 'VII',
                        year: '2000…2005',
                    },
                    {
                        name: 'VII · Рестайлинг',
                        year: '2003…2005',
                    },
                    {
                        name: 'VIII',
                        year: '2005…2008',
                    },
                    {
                        name: 'VIII · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'IX',
                        year: '2011…2015',
                    },
                    {
                        name: 'IX · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'X',
                        year: '2015…2021',
                    },
                ],
            },
            {
                model: 'Clarity',
                generations: [],
            },
            {
                model: 'Concerto',
                generations: [],
            },
            {
                model: 'CR-V',
                generations: [
                    {
                        name: 'I',
                        year: '1995…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2001',
                    },
                    {
                        name: 'II',
                        year: '2001…2004',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2004…2006',
                    },
                    {
                        name: 'III',
                        year: '2006…2009',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'IV',
                        year: '2012…2014',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2014…2016',
                    },
                    {
                        name: 'V',
                        year: '2016…',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'CR-X',
                generations: [
                    {
                        name: 'I',
                        year: '1983…1987',
                    },
                    {
                        name: 'II',
                        year: '1987…1992',
                    },
                    {
                        name: 'III',
                        year: '1992…1999',
                    },
                ],
            },
            {
                model: 'CR-Z',
                generations: [],
            },
            {
                model: 'Crosstour',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2012',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2015',
                    },
                ],
            },
            {
                model: 'Element',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2008',
                    },
                ],
            },
            {
                model: 'Fit',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'III',
                        year: '2014…2017',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'FR-V',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'III',
                        year: '2014…2017',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'HR-V',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2001',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2001…2006',
                    },
                    {
                        name: 'II',
                        year: '2013…2018',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2018…2022',
                    },
                ],
            },
            {
                model: 'Insight',
                generations: [
                    {
                        name: 'II',
                        year: '2009…2011',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2011…2015',
                    },
                    {
                        name: 'III',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Jazz',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2008',
                    },
                    {
                        name: 'II',
                        year: '2007…2011',
                    },
                ],
            },
            {
                model: 'Legend',
                generations: [
                    {
                        name: 'II',
                        year: '1990…1996',
                    },
                    {
                        name: 'III',
                        year: '1996…1998',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '1998…2004',
                    },
                    {
                        name: 'IV',
                        year: '2004…2008',
                    },
                ],
            },
            {
                model: 'Odyssey',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1997',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1997…1999',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2001…2003',
                    },
                    {
                        name: 'III',
                        year: '2003…2006',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'IV',
                        year: '2008…2011',
                    },
                    {
                        name: 'V',
                        year: '2013…2020',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'Pilot',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2011',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2011…2015',
                    },
                    {
                        name: 'III',
                        year: '2015…2018',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2018…2022',
                    },
                ],
            },
            {
                model: 'Prelude',
                generations: [
                    {
                        name: 'III',
                        year: '1987…1989',
                    },
                    {
                        name: 'IV',
                        year: '1991…1996',
                    },
                    {
                        name: 'V',
                        year: '1996…2001',
                    },
                ],
            },
            {
                model: 'Ridgeline',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2009',
                    },
                ],
            },
            {
                model: 'Shuttle',
                generations: [],
            },
            {
                model: 'Stepwgn',
                generations: [
                    {
                        name: 'III',
                        year: '2005…2007',
                    },
                ],
            },
            {
                model: 'Stream',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2006',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Hummer',
        models: [
            {
                model: 'H2',
                generations: [
                    {
                        name: 'H2',
                        year: '2002…2007',
                    },
                    {
                        name: 'H2 · Рестайлинг',
                        year: '2007…2009',
                    },
                ],
            },
            {
                model: 'H3',
                generations: [],
            },
        ],
    },
    {
        brand: 'Hyundai',
        models: [
            {
                model: 'Accent',
                generations: [
                    {
                        name: 'X3',
                        year: '1994…1997',
                    },
                    {
                        name: 'X3 · Рестайлинг',
                        year: '1997…1999',
                    },
                    {
                        name: 'LC',
                        year: '1999…2012',
                    },
                    {
                        name: 'LC · Рестайлинг',
                        year: '2002…2006',
                    },
                    {
                        name: 'MC',
                        year: '2006…2011',
                    },
                    {
                        name: 'RB',
                        year: '2011…2014',
                    },
                    {
                        name: 'RB · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'V',
                        year: '2017…2020',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Atos',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2003',
                    },
                ],
            },
            {
                model: 'Avante',
                generations: [
                    {
                        name: 'MD',
                        year: '2010…2013',
                    },
                ],
            },
            {
                model: 'Centennial',
                generations: [],
            },
            {
                model: 'Coupe',
                generations: [
                    {
                        name: 'RD',
                        year: '1996…1999',
                    },
                    {
                        name: 'RD · Рестайлинг',
                        year: '1999…2001',
                    },
                    {
                        name: 'GK',
                        year: '2002…2007',
                    },
                ],
            },
            {
                model: 'Creta',
                generations: [
                    {
                        name: 'I',
                        year: '2016…2020',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2020…2021',
                    },
                    {
                        name: 'II',
                        year: '2021…',
                    },
                ],
            },
            {
                model: 'Elantra',
                generations: [
                    {
                        name: 'J1',
                        year: '1990…1993',
                    },
                    {
                        name: 'J1 · Рестайлинг',
                        year: '1993…1995',
                    },
                    {
                        name: 'J2',
                        year: 'J3',
                    },
                    {
                        name: 'J2',
                        year: 'J3 · Рестайлинг',
                    },
                    {
                        name: 'XD',
                        year: '2000…2003',
                    },
                    {
                        name: 'XD2',
                        year: '2003…2010',
                    },
                    {
                        name: 'HD',
                        year: '2006…2011',
                    },
                    {
                        name: 'MD',
                        year: '2010…2013',
                    },
                    {
                        name: 'MD · Рестайлинг',
                        year: '2013…2016',
                    },
                    {
                        name: 'AD',
                        year: '2015…2019',
                    },
                    {
                        name: 'AD · Рестайлинг',
                        year: '2018…2020',
                    },
                    {
                        name: 'CN7',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Equus',
                generations: [
                    {
                        name: 'II',
                        year: '2010…2013',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2013…2016',
                    },
                ],
            },
            {
                model: 'Galloper',
                generations: [
                    {
                        name: 'II',
                        year: '1997…2003',
                    },
                ],
            },
            {
                model: 'Genesis',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                    {
                        name: 'II',
                        year: '2013…2017',
                    },
                ],
            },
            {
                model: 'Getz',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2011',
                    },
                ],
            },
            {
                model: 'Grand Santa Fe',
                generations: [
                    {
                        name: 'III',
                        year: '2012…2016',
                    },
                    {
                        name: 'IV',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Grand Starex',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2015',
                    },
                ],
            },
            {
                model: 'Grandeur',
                generations: [
                    {
                        name: 'LX',
                        year: '1992…1998',
                    },
                    {
                        name: 'XG',
                        year: '1998…2002',
                    },
                    {
                        name: 'TG',
                        year: '2005…2010',
                    },
                    {
                        name: 'HG · Рестайлинг',
                        year: '2013…2014',
                    },
                ],
            },
            {
                model: 'H-1(Starex)',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2015',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2015…2017',
                    },
                ],
            },
            {
                model: 'H100',
                generations: [],
            },
            {
                model: 'H200',
                generations: [],
            },
            {
                model: 'i10',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2013',
                    },
                ],
            },
            {
                model: 'i20',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                    {
                        name: 'II',
                        year: '2014…2018',
                    },
                ],
            },
            {
                model: 'i30',
                generations: [
                    {
                        name: 'FD',
                        year: '2007…2010',
                    },
                    {
                        name: 'FD · Рестайлинг',
                        year: '2010…2012',
                    },
                    {
                        name: 'GD',
                        year: '2012…2015',
                    },
                    {
                        name: 'GD · Рестайлинг',
                        year: '2015…2017',
                    },
                    {
                        name: 'III',
                        year: '2017…2018',
                    },
                ],
            },
            {
                model: 'i40',
                generations: [
                    {
                        name: 'VF',
                        year: '2011…2015',
                    },
                    {
                        name: 'VF · Рестайлинг',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'Ioniq',
                generations: [],
            },
            {
                model: 'ix20',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2015',
                    },
                ],
            },
            {
                model: 'ix35',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2015',
                    },
                ],
            },
            {
                model: 'ix55',
                generations: [],
            },
            {
                model: 'Kona',
                generations: [],
            },
            {
                model: 'Lantra',
                generations: [
                    {
                        name: 'J1',
                        year: '1990…1993',
                    },
                    {
                        name: 'J1 · Рестайлинг',
                        year: '1993…1995',
                    },
                    {
                        name: 'J2',
                        year: '1995…1998',
                    },
                    {
                        name: 'J2 · Рестайлинг',
                        year: '1998…2000',
                    },
                ],
            },
            {
                model: 'Matrix',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2008',
                    },
                ],
            },
            {
                model: 'Palisade',
                generations: [],
            },
            {
                model: 'Pony',
                generations: [
                    {
                        name: 'III',
                        year: '1989…1994',
                    },
                ],
            },
            {
                model: 'S Coupe',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1992…1995',
                    },
                ],
            },
            {
                model: 'Santa Fe',
                generations: [
                    {
                        name: 'SM',
                        year: '2000…2005',
                    },
                    {
                        name: 'SM · Рестайлинг',
                        year: '2004…2006',
                    },
                    {
                        name: 'CM',
                        year: '2006…2010',
                    },
                    {
                        name: 'CM · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'DM',
                        year: '2012…2016',
                    },
                    {
                        name: 'DM · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'TM',
                        year: '2018…2021',
                    },
                    {
                        name: 'TM · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Santamo',
                generations: [],
            },
            {
                model: 'Solaris',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'II',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'Sonata',
                generations: [
                    {
                        name: 'Y3',
                        year: '1993…1996',
                    },
                    {
                        name: 'Y3 · Рестайлинг',
                        year: '1996…1998',
                    },
                    {
                        name: 'EF',
                        year: '1998…2001',
                    },
                    {
                        name: 'EF New · Рестайлинг',
                        year: '2001…2013',
                    },
                    {
                        name: 'NF',
                        year: '2004…2007',
                    },
                    {
                        name: 'NF · Рестайлинг',
                        year: '2007…2009',
                    },
                    {
                        name: 'YF',
                        year: '2009…2014',
                    },
                    {
                        name: 'LF',
                        year: '2014…2017',
                    },
                    {
                        name: 'LF · Рестайлинг',
                        year: '2017…2019',
                    },
                    {
                        name: 'DN',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Terracan',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2007',
                    },
                ],
            },
            {
                model: 'Tiburon',
                generations: [
                    {
                        name: 'RD · Рестайлинг',
                        year: '1999…2001',
                    },
                    {
                        name: 'GK',
                        year: '2002…2005',
                    },
                    {
                        name: 'GK F',
                        year: 'L2 · 2-й рестайлинг',
                    },
                ],
            },
            {
                model: 'Trajet',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2008',
                    },
                ],
            },
            {
                model: 'Tucson',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2010',
                    },
                    {
                        name: 'II',
                        year: '2009…2015',
                    },
                    {
                        name: 'III',
                        year: '2015…2020',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2018…2021',
                    },
                    {
                        name: 'IV',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Veloster',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2016',
                    },
                ],
            },
            {
                model: 'Veracruz',
                generations: [],
            },
            {
                model: 'XG',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2003',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Infiniti',
        models: [
            {
                model: 'EX',
                generations: [],
            },
            {
                model: 'FX',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2012',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2011…2013',
                    },
                ],
            },
            {
                model: 'G',
                generations: [
                    {
                        name: 'III',
                        year: '2002…2007',
                    },
                    {
                        name: 'IV',
                        year: '2006…2015',
                    },
                ],
            },
            {
                model: 'J',
                generations: [],
            },
            {
                model: 'JX',
                generations: [],
            },
            {
                model: 'M',
                generations: [
                    {
                        name: 'III',
                        year: '2004…2008',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'IV',
                        year: '2010…2013',
                    },
                ],
            },
            {
                model: 'Q30',
                generations: [],
            },
            {
                model: 'Q45',
                generations: [
                    {
                        name: 'I',
                        year: '1989…1993',
                    },
                ],
            },
            {
                model: 'Q50',
                generations: [
                    {
                        name: 'I',
                        year: '2013…2017',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Q60',
                generations: [
                    {
                        name: 'II',
                        year: '2016…2022',
                    },
                ],
            },
            {
                model: 'Q70',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2018',
                    },
                ],
            },
            {
                model: 'QX30',
                generations: [],
            },
            {
                model: 'QX4',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2003',
                    },
                ],
            },
            {
                model: 'QX50',
                generations: [
                    {
                        name: 'J50 · Рестайлинг',
                        year: '2015…2017',
                    },
                    {
                        name: 'P71A',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'QX56',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2014',
                    },
                ],
            },
            {
                model: 'QX60',
                generations: [
                    {
                        name: 'L50',
                        year: '2013…2016',
                    },
                    {
                        name: 'L50 · Рестайлинг',
                        year: '2016…2021',
                    },
                ],
            },
            {
                model: 'QX70',
                generations: [],
            },
            {
                model: 'QX80',
                generations: [
                    {
                        name: 'Z62',
                        year: '2013…2014',
                    },
                    {
                        name: 'Z62 · Рестайлинг',
                        year: '2014…2018',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Iran Khodro',
        models: [
            {
                model: 'Samand',
                generations: [],
            },
        ],
    },
    {
        brand: 'Isuzu',
        models: [
            {
                model: 'Amigo',
                generations: [
                    {
                        name: 'II · Рестайлинг',
                        year: '2000…2001',
                    },
                ],
            },
            {
                model: 'Axiom',
                generations: [],
            },
            {
                model: 'Midi',
                generations: [],
            },
            {
                model: 'Rodeo',
                generations: [
                    {
                        name: 'II',
                        year: '1998…2004',
                    },
                ],
            },
            {
                model: 'Trooper',
                generations: [
                    {
                        name: 'I',
                        year: '1981…1987',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1987…1991',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1998…2002',
                    },
                ],
            },
        ],
    },
    {
        brand: 'JAC',
        models: [
            {
                model: 'Refine',
                generations: [],
            },
            {
                model: 'S5',
                generations: [],
            },
            {
                model: 'T6',
                generations: [],
            },
        ],
    },
    {
        brand: 'Jaguar',
        models: [
            {
                model: 'E-Pace',
                generations: [],
            },
            {
                model: 'F-Pace',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'F-Type',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…2019',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2019…2022',
                    },
                ],
            },
            {
                model: 'I-Pace',
                generations: [],
            },
            {
                model: 'S-Type',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2004',
                    },
                ],
            },
            {
                model: 'X-Type',
                generations: [],
            },
            {
                model: 'XE',
                generations: [],
            },
            {
                model: 'XF',
                generations: [
                    {
                        name: 'X250',
                        year: '2007…2011',
                    },
                    {
                        name: 'X250 · Рестайлинг',
                        year: '2011…2016',
                    },
                    {
                        name: 'X260',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'XJ',
                generations: [
                    {
                        name: 'Series 2',
                        year: '1973…1979',
                    },
                    {
                        name: 'Series 3',
                        year: '1979…1992',
                    },
                    {
                        name: 'X350',
                        year: '2003…2007',
                    },
                    {
                        name: 'X358 · Рестайлинг',
                        year: '2007…2009',
                    },
                    {
                        name: 'X351',
                        year: '2009…2015',
                    },
                ],
            },
            {
                model: 'XJR',
                generations: [
                    {
                        name: 'X300',
                        year: '1994…1997',
                    },
                ],
            },
            {
                model: 'XJS',
                generations: [
                    {
                        name: 'Series 3',
                        year: '1991…1996',
                    },
                ],
            },
            {
                model: 'XK',
                generations: [
                    {
                        name: 'Х100',
                        year: '1996…2004',
                    },
                    {
                        name: 'X150',
                        year: '2005…2009',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Jeep',
        models: [
            {
                model: 'Cherokee',
                generations: [
                    {
                        name: 'XJ',
                        year: '1984…1997',
                    },
                    {
                        name: 'XJ · Рестайлинг',
                        year: '1997…2001',
                    },
                    {
                        name: 'KJ',
                        year: '2001…2004',
                    },
                    {
                        name: 'KJ · Рестайлинг',
                        year: '2004…2007',
                    },
                    {
                        name: 'KL',
                        year: '2013…2017',
                    },
                    {
                        name: 'KL · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Commander',
                generations: [],
            },
            {
                model: 'Compass',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…2013',
                    },
                    {
                        name: 'II',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Grand Cherokee',
                generations: [
                    {
                        name: 'ZJ',
                        year: '1991…1996',
                    },
                    {
                        name: 'ZJ · Рестайлинг',
                        year: '1996…1999',
                    },
                    {
                        name: 'WJ',
                        year: '1999…2004',
                    },
                    {
                        name: 'WK',
                        year: '2004…2010',
                    },
                    {
                        name: 'WK2',
                        year: '2010…2014',
                    },
                    {
                        name: 'WK2 · Рестайлинг',
                        year: '2013…2022',
                    },
                ],
            },
            {
                model: 'Liberty',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2007',
                    },
                ],
            },
            {
                model: 'Patriot',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2010…2016',
                    },
                ],
            },
            {
                model: 'Renegade',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Wrangler',
                generations: [
                    {
                        name: 'YJ',
                        year: '1987…1996',
                    },
                    {
                        name: 'TJ',
                        year: '1997…2006',
                    },
                    {
                        name: 'JK · Рестайлинг',
                        year: '2011…2018',
                    },
                    {
                        name: 'JL',
                        year: '2017…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Kia',
        models: [
            {
                model: 'Besta',
                generations: [
                    {
                        name: 'I',
                        year: '1988…1997',
                    },
                ],
            },
            {
                model: 'Carens',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2002',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2006',
                    },
                    {
                        name: 'II',
                        year: '2006…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2010…2012',
                    },
                    {
                        name: 'III',
                        year: '2013…2018',
                    },
                ],
            },
            {
                model: 'Carnival',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2002',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2006',
                    },
                    {
                        name: 'II',
                        year: '2006…2014',
                    },
                    {
                        name: 'III',
                        year: '2014…2021',
                    },
                    {
                        name: 'IV',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Cee\'d',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'II',
                        year: '2012…2015',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'III',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Cerato',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'II',
                        year: '2009…2013',
                    },
                    {
                        name: 'III',
                        year: '2013…2016',
                    },
                    {
                        name: 'IV',
                        year: '2018…2022',
                    },
                ],
            },
            {
                model: 'Clarus',
                generations: [
                    {
                        name: 'I',
                        year: '1996…1998',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1998…2001',
                    },
                ],
            },
            {
                model: 'Joice',
                generations: [],
            },
            {
                model: 'K5',
                generations: [
                    {
                        name: 'III',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Magentis',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2003',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'II',
                        year: '2006…2008',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2008…2010',
                    },
                ],
            },
            {
                model: 'Mohave',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2016',
                    },
                ],
            },
            {
                model: 'Niro',
                generations: [],
            },
            {
                model: 'Opirus',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                ],
            },
            {
                model: 'Optima',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2002',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'III',
                        year: '2010…2014',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2013…2015',
                    },
                    {
                        name: 'IV',
                        year: '2015…2018',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2018…2020',
                    },
                ],
            },
            {
                model: 'Picanto',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'II',
                        year: '2011…2015',
                    },
                    {
                        name: 'III',
                        year: '2017…2021',
                    },
                ],
            },
            {
                model: 'Pride',
                generations: [
                    {
                        name: 'I',
                        year: '1987…2000',
                    },
                ],
            },
            {
                model: 'Quoris',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2014',
                    },
                ],
            },
            {
                model: 'Retona',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2000',
                    },
                ],
            },
            {
                model: 'Rio',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2002',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2005',
                    },
                    {
                        name: 'II',
                        year: '2005…2009',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2009…2011',
                    },
                    {
                        name: 'III',
                        year: '2011…2015',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2015…2017',
                    },
                    {
                        name: 'IV X-Line',
                        year: '2017…',
                    },
                    {
                        name: 'IV',
                        year: '2017…',
                    },
                    {
                        name: 'IV X · Рестайлинг ',
                        year: '2020…',
                    },
                    {
                        name: 'IV X-Line · Рестайлинг',
                        year: '2020…',
                    },
                    {
                        name: 'IV · Рестайлинг ',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Sedona',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2001…2005',
                    },
                    {
                        name: 'III',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Seltos',
                generations: [],
            },
            {
                model: 'Sephia',
                generations: [
                    {
                        name: 'I',
                        year: '1992…1994',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1994…1998',
                    },
                    {
                        name: 'II',
                        year: '1997…2001',
                    },
                ],
            },
            {
                model: 'Shuma',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2001',
                    },
                    {
                        name: 'II',
                        year: '2001…2004',
                    },
                ],
            },
            {
                model: 'Sorento',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'II',
                        year: '2009…2012',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2012…2020',
                    },
                    {
                        name: 'III',
                        year: '2015…2018',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'Soul',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2011',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…2014',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2020',
                    },
                    {
                        name: 'III',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Soul EV',
                generations: [],
            },
            {
                model: 'Spectra',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2001…2004',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2004…2011',
                    },
                ],
            },
            {
                model: 'Sportage',
                generations: [
                    {
                        name: 'I',
                        year: '1993…2006',
                    },
                    {
                        name: 'II',
                        year: '2004…2008',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'III',
                        year: '2010…2014',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2014…2016',
                    },
                    {
                        name: 'IV',
                        year: '2016…2020',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2018…2022',
                    },
                    {
                        name: 'V',
                        year: '2021…',
                    },
                ],
            },
            {
                model: 'Stinger',
                generations: [],
            },
            {
                model: 'Stonic',
                generations: [],
            },
            {
                model: 'Venga',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2014',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Lada (ВАЗ)',
        models: [
            {
                model: '2101',
                generations: [],
            },
            {
                model: '2102',
                generations: [],
            },
            {
                model: '2103',
                generations: [],
            },
            {
                model: '2104',
                generations: [],
            },
            {
                model: '2105',
                generations: [],
            },
            {
                model: '2106',
                generations: [],
            },
            {
                model: '2107',
                generations: [],
            },
            {
                model: '2108',
                generations: [],
            },
            {
                model: '2109',
                generations: [],
            },
            {
                model: '2110',
                generations: [],
            },
            {
                model: '2111',
                generations: [],
            },
            {
                model: '2112',
                generations: [],
            },
            {
                model: '2114',
                generations: [],
            },
            {
                model: '2115',
                generations: [],
            },
            {
                model: '2329',
                generations: [],
            },
            {
                model: '21013',
                generations: [],
            },
            {
                model: '21099',
                generations: [],
            },
            {
                model: '1111 Ока',
                generations: [],
            },
            {
                model: '2121 Нива',
                generations: [
                    {
                        name: 'I',
                        year: '1977…',
                    },
                    {
                        name: 'Legend',
                        year: '2021…',
                    },
                ],
            },
            {
                model: '2131 Нива',
                generations: [
                    {
                        name: 'I',
                        year: '1993…',
                    },
                ],
            },
            {
                model: '2345 ВИС',
                generations: [],
            },
            {
                model: 'Granta',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Kalina',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2013',
                    },
                    {
                        name: 'II',
                        year: '2013…',
                    },
                ],
            },
            {
                model: 'Largus',
                generations: [],
            },
            {
                model: 'Niva Travel',
                generations: [],
            },
            {
                model: 'Priora',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…',
                    },
                ],
            },
            {
                model: 'Vesta',
                generations: [],
            },
            {
                model: 'XRAY',
                generations: [],
            },
        ],
    },
    {
        brand: 'Lancia',
        models: [
            {
                model: 'Dedra',
                generations: [],
            },
            {
                model: 'Delta',
                generations: [
                    {
                        name: 'II',
                        year: '1993…1999',
                    },
                    {
                        name: 'III',
                        year: '2008…2014',
                    },
                ],
            },
            {
                model: 'Kappa',
                generations: [],
            },
            {
                model: 'Lybra',
                generations: [],
            },
            {
                model: 'Musa',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                ],
            },
            {
                model: 'Phedra',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2008',
                    },
                ],
            },
            {
                model: 'Thema',
                generations: [
                    {
                        name: 'I',
                        year: '1984…1994',
                    },
                ],
            },
            {
                model: 'Thesis',
                generations: [],
            },
            {
                model: 'Ypsilon',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2003',
                    },
                ],
            },
            {
                model: 'Zeta',
                generations: [],
            },
        ],
    },
    {
        brand: 'Land Rover',
        models: [
            {
                model: 'Defender',
                generations: [
                    {
                        name: 'II',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Discovery',
                generations: [
                    {
                        name: 'I',
                        year: '1989…1998',
                    },
                    {
                        name: 'II',
                        year: '1998…2004',
                    },
                    {
                        name: 'III',
                        year: '2004…2009',
                    },
                    {
                        name: 'IV',
                        year: '2009…2013',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'V',
                        year: '2017…2020',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Discovery Sport',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Freelander',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2003',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'II',
                        year: '2006…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2010…2012',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2012…2015',
                    },
                ],
            },
            {
                model: 'Range Rover',
                generations: [
                    {
                        name: 'II',
                        year: '1994…2002',
                    },
                    {
                        name: 'III',
                        year: '2002…2005',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2005…2009',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'IV',
                        year: '2012…2017',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2017…2022',
                    },
                ],
            },
            {
                model: 'Range Rover Evoque',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2015',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'II ',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Range Rover Sport',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2013',
                    },
                    {
                        name: 'II',
                        year: '2013…2017',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2017…2022',
                    },
                ],
            },
            {
                model: 'Range Rover Velar',
                generations: [],
            },
        ],
    },
    {
        brand: 'Lexus',
        models: [
            {
                model: 'CT',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'ES',
                generations: [
                    {
                        name: 'IV',
                        year: '2001…2003',
                    },
                    {
                        name: 'V',
                        year: '2006…2009',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'VI',
                        year: '2012…2015',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'VII',
                        year: '2018…2021',
                    },
                ],
            },
            {
                model: 'GS',
                generations: [
                    {
                        name: 'I',
                        year: '1991…1997',
                    },
                    {
                        name: 'II',
                        year: '1997…2000',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2000…2005',
                    },
                    {
                        name: 'III',
                        year: '2005…2008',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2007…2011',
                    },
                    {
                        name: 'IV',
                        year: '2011…2016',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'GX',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2009',
                    },
                    {
                        name: 'II',
                        year: '2009…2013',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2013…2019',
                    },
                ],
            },
            {
                model: 'HS',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2012',
                    },
                ],
            },
            {
                model: 'IS',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2005',
                    },
                    {
                        name: 'II',
                        year: '2005…2008',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2010…2016',
                    },
                    {
                        name: 'III',
                        year: '2013…2016',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'LC',
                generations: [],
            },
            {
                model: 'LS',
                generations: [
                    {
                        name: 'III',
                        year: '2000…2003',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'IV',
                        year: '2006…2009',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'IV · 2-й рестайлинг',
                        year: '2012…2017',
                    },
                    {
                        name: 'V',
                        year: '2017…2021',
                    },
                ],
            },
            {
                model: 'LX',
                generations: [
                    {
                        name: 'II',
                        year: '1998…2002',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2002…2007',
                    },
                    {
                        name: 'III',
                        year: '2007…2012',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2015…2022',
                    },
                ],
            },
            {
                model: 'NX',
                generations: [
                    {
                        name: 'I',
                        year: '2014…2017',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…2021',
                    },
                ],
            },
            {
                model: 'RC',
                generations: [],
            },
            {
                model: 'RX',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2003',
                    },
                    {
                        name: 'II',
                        year: '2003…2007',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2007…2009',
                    },
                    {
                        name: 'III',
                        year: '2009…2012',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'IV',
                        year: '2015…2019',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'SC',
                generations: [
                    {
                        name: 'I',
                        year: '1991…2000',
                    },
                    {
                        name: 'II',
                        year: '2000…2005',
                    },
                ],
            },
            {
                model: 'UX',
                generations: [
                    {
                        name: 'I',
                        year: '2018…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Lifan',
        models: [
            {
                model: 'Breez',
                generations: [],
            },
            {
                model: 'Murman',
                generations: [],
            },
            {
                model: 'MyWay',
                generations: [],
            },
            {
                model: 'Smily',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2017',
                    },
                ],
            },
            {
                model: 'Solano',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2016',
                    },
                    {
                        name: 'II',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'X50',
                generations: [],
            },
            {
                model: 'X70',
                generations: [],
            },
            {
                model: 'Х60',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2015',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2015…2016',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2016…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Lincoln',
        models: [
            {
                model: 'Continental',
                generations: [
                    {
                        name: 'V',
                        year: '1970…1979',
                    },
                    {
                        name: 'VIII',
                        year: '1988…1994',
                    },
                    {
                        name: 'X',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'Corsair',
                generations: [
                    {
                        name: 'I',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'MKC',
                generations: [],
            },
            {
                model: 'MKT',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2012',
                    },
                ],
            },
            {
                model: 'MKX',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2010',
                    },
                    {
                        name: 'II',
                        year: '2015…2018',
                    },
                ],
            },
            {
                model: 'MKZ',
                generations: [
                    {
                        name: 'II',
                        year: '2012…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2019',
                    },
                ],
            },
            {
                model: 'Nautilus',
                generations: [],
            },
            {
                model: 'Navigator',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2003',
                    },
                    {
                        name: 'II',
                        year: '2003…2006',
                    },
                    {
                        name: 'III',
                        year: '2006…2014',
                    },
                    {
                        name: 'IV',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Town Car',
                generations: [
                    {
                        name: 'II',
                        year: '1989…1997',
                    },
                    {
                        name: 'III',
                        year: '1997…2003',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2003…2011',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Mazda',
        models: [
            {
                model: '2',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2005',
                    },
                    {
                        name: 'II',
                        year: '2007…2010',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2010…2014',
                    },
                ],
            },
            {
                model: '3',
                generations: [
                    {
                        name: 'BK',
                        year: '2003…2006',
                    },
                    {
                        name: 'BK · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'BL',
                        year: '2009…2011',
                    },
                    {
                        name: 'BL · Рестайлинг',
                        year: '2011…2013',
                    },
                    {
                        name: 'BM',
                        year: '2013…2016',
                    },
                    {
                        name: 'BM · Рестайлинг',
                        year: '2016…2019',
                    },
                    {
                        name: 'BP',
                        year: '2019…',
                    },
                ],
            },
            {
                model: '5',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2015',
                    },
                ],
            },
            {
                model: '6',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2009',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2009…2013',
                    },
                    {
                        name: 'III',
                        year: '2012…2015',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: '121',
                generations: [
                    {
                        name: 'II',
                        year: '1991…1996',
                    },
                    {
                        name: 'III',
                        year: '1996…2003',
                    },
                ],
            },
            {
                model: '323',
                generations: [
                    {
                        name: 'BF',
                        year: '1985…1987',
                    },
                    {
                        name: 'BG',
                        year: '1989…1994',
                    },
                    {
                        name: 'BA',
                        year: '1994…1996',
                    },
                    {
                        name: 'BA · Рестайлинг',
                        year: '1996…1998',
                    },
                    {
                        name: 'BJ',
                        year: '1998…2000',
                    },
                    {
                        name: 'BJ · Рестайлинг',
                        year: '2000…2003',
                    },
                ],
            },
            {
                model: '626',
                generations: [
                    {
                        name: 'II',
                        year: '1982…1987',
                    },
                    {
                        name: 'III',
                        year: '1987…1992',
                    },
                    {
                        name: 'GE',
                        year: '1991…1997',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '1992…1997',
                    },
                    {
                        name: 'GF',
                        year: '1996…1999',
                    },
                    {
                        name: 'GF · Рестайлинг',
                        year: '1999…2002',
                    },
                ],
            },
            {
                model: '929',
                generations: [
                    {
                        name: 'HC',
                        year: '1986…1991',
                    },
                ],
            },
            {
                model: '3 MPS',
                generations: [
                    {
                        name: 'BK',
                        year: '2006…2009',
                    },
                    {
                        name: 'BL',
                        year: '2009…2011',
                    },
                ],
            },
            {
                model: '323F',
                generations: [],
            },
            {
                model: 'CX-3',
                generations: [],
            },
            {
                model: 'CX-30',
                generations: [],
            },
            {
                model: 'CX-5',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2015',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2015…2020',
                    },
                    {
                        name: 'II',
                        year: '2016…2022',
                    },
                ],
            },
            {
                model: 'CX-7',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2012',
                    },
                ],
            },
            {
                model: 'CX-9',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'II',
                        year: '2015…2021',
                    },
                ],
            },
            {
                model: 'Demio',
                generations: [
                    {
                        name: 'DW',
                        year: '1996…1999',
                    },
                    {
                        name: 'DW · Рестайлинг',
                        year: '1999…2002',
                    },
                ],
            },
            {
                model: 'Millenia',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2003',
                    },
                ],
            },
            {
                model: 'MPV',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1995…1999',
                    },
                    {
                        name: 'II',
                        year: '1999…2003',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2003…2006',
                    },
                ],
            },
            {
                model: 'MX-3',
                generations: [],
            },
            {
                model: 'MX-30',
                generations: [],
            },
            {
                model: 'MX-5',
                generations: [
                    {
                        name: 'NC · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'IV',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'MX-6',
                generations: [
                    {
                        name: 'II',
                        year: '1991…1995',
                    },
                ],
            },
            {
                model: 'Premacy',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2001',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2001…2005',
                    },
                ],
            },
            {
                model: 'Protege',
                generations: [
                    {
                        name: 'BH',
                        year: '1994…1999',
                    },
                    {
                        name: 'BJ · Рестайлинг',
                        year: '2000…2003',
                    },
                ],
            },
            {
                model: 'RX-8',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2008',
                    },
                ],
            },
            {
                model: 'Tribute',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2011',
                    },
                ],
            },
            {
                model: 'Xedos 6',
                generations: [],
            },
            {
                model: 'Xedos 9',
                generations: [
                    {
                        name: 'I',
                        year: '1993…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2002',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Mercedes-Benz',
        models: [
            {
                model: '190 (W201)',
                generations: [
                    {
                        name: 'I',
                        year: '1982…1994',
                    },
                ],
            },
            {
                model: 'A-Класс',
                generations: [
                    {
                        name: 'W168',
                        year: '1997…2001',
                    },
                    {
                        name: 'W168 · Рестайлинг',
                        year: '2001…2004',
                    },
                    {
                        name: 'W169',
                        year: '2004…2008',
                    },
                    {
                        name: 'W169 · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'W176',
                        year: '2012…2015',
                    },
                    {
                        name: 'W176 · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'W177',
                        year: 'V177',
                    },
                ],
            },
            {
                model: 'A-Класс AMG',
                generations: [
                    {
                        name: 'W168',
                        year: '1997…2001',
                    },
                    {
                        name: 'W168 · Рестайлинг',
                        year: '2001…2004',
                    },
                    {
                        name: 'W169',
                        year: '2004…2008',
                    },
                    {
                        name: 'W169 · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'W176',
                        year: '2012…2015',
                    },
                    {
                        name: 'W176 · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'W177',
                        year: 'V177',
                    },
                ],
            },
            {
                model: 'AMG GT',
                generations: [
                    {
                        name: 'C190',
                        year: 'R190 · Рестайлинг',
                    },
                ],
            },
            {
                model: 'B-Класс',
                generations: [
                    {
                        name: 'W245',
                        year: '2005…2009',
                    },
                    {
                        name: 'W245 · Рестайлинг',
                        year: '2008…2011',
                    },
                    {
                        name: 'W246',
                        year: '2011…2014',
                    },
                    {
                        name: 'W246 · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'W247',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'C-Класс',
                generations: [
                    {
                        name: 'W202',
                        year: 'S202',
                    },
                    {
                        name: 'W202',
                        year: 'S202 · Рестайлинг',
                    },
                    {
                        name: 'W203',
                        year: 'S203',
                    },
                    {
                        name: 'W203',
                        year: 'S203',
                    },
                    {
                        name: 'W204',
                        year: 'S204',
                    },
                    {
                        name: 'W204',
                        year: 'S204 · Рестайлинг',
                    },
                    {
                        name: 'W205',
                        year: 'S205',
                    },
                    {
                        name: 'W205',
                        year: 'S205',
                    },
                ],
            },
            {
                model: 'C-Класс AMG',
                generations: [
                    {
                        name: 'W202',
                        year: '1994…1997',
                    },
                    {
                        name: 'W205',
                        year: 'S205',
                    },
                    {
                        name: 'W205',
                        year: 'S205',
                    },
                ],
            },
            {
                model: 'Citan',
                generations: [],
            },
            {
                model: 'CL',
                generations: [
                    {
                        name: 'C140',
                        year: '1992…2000',
                    },
                    {
                        name: 'C215',
                        year: '1999…2002',
                    },
                    {
                        name: 'C215 · Рестайлинг',
                        year: '2002…2006',
                    },
                    {
                        name: 'C216',
                        year: '2006…2010',
                    },
                    {
                        name: 'C216 · Рестайлинг',
                        year: '2010…2014',
                    },
                ],
            },
            {
                model: 'CL AMG',
                generations: [
                    {
                        name: 'C216',
                        year: '2006…2010',
                    },
                ],
            },
            {
                model: 'CLA',
                generations: [
                    {
                        name: 'C117',
                        year: 'X117',
                    },
                    {
                        name: 'C117',
                        year: 'X117 · Рестайлинг',
                    },
                    {
                        name: 'II',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'CLA AMG',
                generations: [
                    {
                        name: 'C117',
                        year: 'X117',
                    },
                    {
                        name: 'C118',
                        year: 'X118',
                    },
                ],
            },
            {
                model: 'CLK',
                generations: [
                    {
                        name: 'C208',
                        year: '1997…2000',
                    },
                    {
                        name: 'C208 · Рестайлинг',
                        year: '1999…2003',
                    },
                    {
                        name: 'C209',
                        year: '2002…2005',
                    },
                    {
                        name: 'C209 · Рестайлинг',
                        year: '2005…2010',
                    },
                ],
            },
            {
                model: 'CLS',
                generations: [
                    {
                        name: 'C219',
                        year: '2004…2008',
                    },
                    {
                        name: 'C219 · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'C218',
                        year: 'X218',
                    },
                    {
                        name: 'C218',
                        year: 'X218 · Рестайлинг',
                    },
                    {
                        name: 'C257',
                        year: '2017…2021',
                    },
                ],
            },
            {
                model: 'CLS AMG',
                generations: [
                    {
                        name: 'C219',
                        year: '2005…2008',
                    },
                    {
                        name: 'C218 · Рестайлинг',
                        year: '2014…2018',
                    },
                ],
            },
            {
                model: 'E-Класс',
                generations: [
                    {
                        name: 'W115',
                        year: '1968…1977',
                    },
                    {
                        name: 'W123',
                        year: '1975…1985',
                    },
                    {
                        name: 'W124',
                        year: 'S124',
                    },
                    {
                        name: 'W210',
                        year: 'S210',
                    },
                    {
                        name: 'W211',
                        year: 'S211',
                    },
                    {
                        name: 'W212',
                        year: 'S212',
                    },
                    {
                        name: 'W212',
                        year: 'S212',
                    },
                    {
                        name: 'W213',
                        year: 'S213',
                    },
                    {
                        name: 'W213',
                        year: 'S213',
                    },
                ],
            },
            {
                model: 'E-Класс AMG',
                generations: [
                    {
                        name: 'W212',
                        year: 'S212',
                    },
                    {
                        name: 'W212',
                        year: 'S212 (Рестайлинг)',
                    },
                    {
                        name: 'W213',
                        year: 'S213',
                    },
                ],
            },
            {
                model: 'EQC',
                generations: [
                    {
                        name: 'I',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'EQS',
                generations: [],
            },
            {
                model: 'G-Класс',
                generations: [
                    {
                        name: 'W460',
                        year: 'W461',
                    },
                    {
                        name: 'W463',
                        year: '1990…2006',
                    },
                    {
                        name: 'W463 · 2-й рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'W463 · 3-й рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'W464',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'G-Класс AMG',
                generations: [
                    {
                        name: 'W463 · 2-й рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'W464',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'GL-Класс',
                generations: [
                    {
                        name: 'X164',
                        year: '2006…2009',
                    },
                    {
                        name: 'X164 (Рестайлинг)',
                        year: '2009…2012',
                    },
                    {
                        name: 'X166',
                        year: '2012…2016',
                    },
                ],
            },
            {
                model: 'GL-Класс AMG',
                generations: [],
            },
            {
                model: 'GLA',
                generations: [
                    {
                        name: 'X156',
                        year: '2013…2017',
                    },
                    {
                        name: 'X156 (Рестайлинг)',
                        year: '2017…2020',
                    },
                    {
                        name: 'II (H247)',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'GLA AMG',
                generations: [
                    {
                        name: 'X156 (Рестайлинг)',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'GLB',
                generations: [
                    {
                        name: 'I (X247)',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'GLC',
                generations: [],
            },
            {
                model: 'GLC AMG',
                generations: [],
            },
            {
                model: 'GLC Coupe',
                generations: [],
            },
            {
                model: 'GLC Coupe AMG',
                generations: [],
            },
            {
                model: 'GLE',
                generations: [
                    {
                        name: 'W166',
                        year: '2015…2018',
                    },
                    {
                        name: 'W167',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'GLE AMG',
                generations: [
                    {
                        name: 'W166',
                        year: '2015…2019',
                    },
                    {
                        name: 'V167',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'GLE Coupe',
                generations: [
                    {
                        name: 'C292',
                        year: '2015…2019',
                    },
                    {
                        name: 'C167',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'GLE Coupe AMG',
                generations: [
                    {
                        name: 'C292',
                        year: '2015…2019',
                    },
                    {
                        name: 'C167',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'GLK',
                generations: [
                    {
                        name: 'X204',
                        year: '2008…2012',
                    },
                    {
                        name: 'X204 · Рестайлинг',
                        year: '2012…2015',
                    },
                ],
            },
            {
                model: 'GLS',
                generations: [
                    {
                        name: 'X166',
                        year: '2012…2019',
                    },
                    {
                        name: 'X167',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'GLS AMG',
                generations: [],
            },
            {
                model: 'M-Класс',
                generations: [
                    {
                        name: 'W163',
                        year: '1997…2001',
                    },
                    {
                        name: 'W163 · Рестайлинг',
                        year: '2001…2005',
                    },
                    {
                        name: 'W164',
                        year: '2005…2008',
                    },
                    {
                        name: 'W164 · Рестайлинг',
                        year: '2008…2011',
                    },
                    {
                        name: 'W166',
                        year: '2011…2015',
                    },
                ],
            },
            {
                model: 'M-Класс AMG',
                generations: [
                    {
                        name: 'W163 · Рестайлинг',
                        year: '2001…2005',
                    },
                    {
                        name: 'W164',
                        year: '2006…2008',
                    },
                    {
                        name: 'W166',
                        year: '2011…2015',
                    },
                ],
            },
            {
                model: 'Maybach',
                generations: [
                    {
                        name: 'X222',
                        year: '2014…2017',
                    },
                    {
                        name: 'X222 · Рестайлинг',
                        year: '2017…2021',
                    },
                ],
            },
            {
                model: 'Maybach GLS',
                generations: [],
            },
            {
                model: 'MB',
                generations: [],
            },
            {
                model: 'R-Класс',
                generations: [
                    {
                        name: 'W251',
                        year: '2005…2007',
                    },
                    {
                        name: 'W251 · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'W251 · 2-й рестайлинг',
                        year: '2010…',
                    },
                ],
            },
            {
                model: 'S-Класс',
                generations: [
                    {
                        name: 'W116',
                        year: '1972…1980',
                    },
                    {
                        name: 'W126',
                        year: 'C126',
                    },
                    {
                        name: 'W140',
                        year: 'C140',
                    },
                    {
                        name: 'W140',
                        year: 'C140 · Рестайлинг',
                    },
                    {
                        name: 'W220',
                        year: '1998…2002',
                    },
                    {
                        name: 'W220 · Рестайлинг',
                        year: '2002…2005',
                    },
                    {
                        name: 'W221',
                        year: '2005…2009',
                    },
                    {
                        name: 'W221· Рестайлинг',
                        year: '2009…2013',
                    },
                    {
                        name: 'W222',
                        year: 'C217',
                    },
                    {
                        name: 'W222',
                        year: 'C217',
                    },
                    {
                        name: 'W223',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'S-Класс AMG',
                generations: [
                    {
                        name: 'W220',
                        year: '1999…2002',
                    },
                    {
                        name: 'W222',
                        year: 'C217',
                    },
                    {
                        name: 'W222',
                        year: 'C217',
                    },
                ],
            },
            {
                model: 'SL',
                generations: [
                    {
                        name: 'R230',
                        year: '2001…2006',
                    },
                    {
                        name: 'R231 · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'SL AMG',
                generations: [
                    {
                        name: 'R230',
                        year: '2002…2006',
                    },
                ],
            },
            {
                model: 'SLK',
                generations: [
                    {
                        name: 'R170 · Рестайлинг',
                        year: '2000…2004',
                    },
                    {
                        name: 'R171',
                        year: '2004…2008',
                    },
                    {
                        name: 'R172',
                        year: '2011…2016',
                    },
                ],
            },
            {
                model: 'SLK AMG',
                generations: [
                    {
                        name: 'R170',
                        year: '2001…2004',
                    },
                ],
            },
            {
                model: 'V-Класс',
                generations: [
                    {
                        name: 'W638',
                        year: 'W639',
                    },
                    {
                        name: 'W447',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Vaneo',
                generations: [],
            },
            {
                model: 'Viano',
                generations: [
                    {
                        name: 'W639',
                        year: '2003…2010',
                    },
                    {
                        name: 'W639 · Рестайлинг',
                        year: '2010…2015',
                    },
                ],
            },
            {
                model: 'Vito',
                generations: [
                    {
                        name: 'W638',
                        year: '1996…2003',
                    },
                    {
                        name: 'W639',
                        year: '2003…2010',
                    },
                    {
                        name: 'W639 · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'W447',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'W110',
                generations: [
                    {
                        name: 'II',
                        year: '1965…1968',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Mercury',
        models: [
            {
                model: 'Cougar',
                generations: [
                    {
                        name: 'VIII',
                        year: '1998…2002',
                    },
                ],
            },
            {
                model: 'Mariner',
                generations: [
                    {
                        name: 'II',
                        year: '2007…2010',
                    },
                ],
            },
            {
                model: 'Mountaineer',
                generations: [
                    {
                        name: 'II',
                        year: '2001…2006',
                    },
                ],
            },
            {
                model: 'Sable',
                generations: [
                    {
                        name: 'III',
                        year: '1995…2000',
                    },
                ],
            },
            {
                model: 'Villager',
                generations: [
                    {
                        name: 'I',
                        year: '1992…1998',
                    },
                ],
            },
        ],
    },
    {
        brand: 'MG',
        models: [
            {
                model: '3',
                generations: [],
            },
            {
                model: '6',
                generations: [],
            },
            {
                model: '350',
                generations: [],
            },
            {
                model: '550',
                generations: [],
            },
            {
                model: 'ZR',
                generations: [],
            },
            {
                model: 'ZS',
                generations: [],
            },
        ],
    },
    {
        brand: 'MINI',
        models: [
            {
                model: 'Cabrio',
                generations: [
                    {
                        name: 'II',
                        year: '2008…2010',
                    },
                    {
                        name: 'III',
                        year: '2015…2018',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2018…2021',
                    },
                ],
            },
            {
                model: 'Clubman',
                generations: [
                    {
                        name: 'R55',
                        year: '2007…2010',
                    },
                    {
                        name: 'R55 · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'F54',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Cooper',
                generations: [
                    {
                        name: 'R50',
                        year: '2001…2004',
                    },
                    {
                        name: 'R50 · Рестайлинг',
                        year: '2004…2006',
                    },
                    {
                        name: 'R56',
                        year: '2006…2010',
                    },
                    {
                        name: 'R56 · Рестайлинг',
                        year: '2010…2013',
                    },
                    {
                        name: 'F55',
                        year: 'F56',
                    },
                    {
                        name: 'F55',
                        year: 'F56 · Рестайлинг',
                    },
                    {
                        name: 'F55',
                        year: 'F56 · 2-й рестайлинг',
                    },
                ],
            },
            {
                model: 'Countryman',
                generations: [
                    {
                        name: 'R60',
                        year: '2010…2017',
                    },
                    {
                        name: 'F60',
                        year: '2016…2020',
                    },
                    {
                        name: 'F60 · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Coupe',
                generations: [],
            },
            {
                model: 'Hatch',
                generations: [],
            },
            {
                model: 'Paceman',
                generations: [],
            },
        ],
    },
    {
        brand: 'Mitsubishi',
        models: [
            {
                model: '3000GT',
                generations: [
                    {
                        name: 'II',
                        year: '1994…1998',
                    },
                ],
            },
            {
                model: 'ASX',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2012',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2016',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2016…2020',
                    },
                    {
                        name: 'I · 3-й рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Carisma',
                generations: [
                    {
                        name: 'I',
                        year: '1995…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2004',
                    },
                ],
            },
            {
                model: 'Colt',
                generations: [
                    {
                        name: 'IV',
                        year: '1991…1995',
                    },
                    {
                        name: 'V',
                        year: '1995…2003',
                    },
                    {
                        name: 'VI',
                        year: '2002…2008',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2008…2012',
                    },
                ],
            },
            {
                model: 'Eclipse',
                generations: [
                    {
                        name: '1G',
                        year: '1989…1992',
                    },
                    {
                        name: '1G · Рестайлинг',
                        year: '1992…1994',
                    },
                    {
                        name: '2G',
                        year: '1994…1997',
                    },
                    {
                        name: '2G · Рестайлинг',
                        year: '1997…1999',
                    },
                    {
                        name: '3G',
                        year: '1999…2005',
                    },
                    {
                        name: '4G',
                        year: '2005…2008',
                    },
                ],
            },
            {
                model: 'Eclipse Cross',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2021…',
                    },
                ],
            },
            {
                model: 'Endeavor',
                generations: [],
            },
            {
                model: 'Galant',
                generations: [
                    {
                        name: 'VI',
                        year: '1987…1993',
                    },
                    {
                        name: 'VII',
                        year: '1992…1998',
                    },
                    {
                        name: 'VIII',
                        year: '1996…2000',
                    },
                    {
                        name: 'VIII · Рестайлинг',
                        year: '2000…2006',
                    },
                    {
                        name: 'IX',
                        year: '2003…2006',
                    },
                    {
                        name: 'IX · Рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'IX · 2-й рестайлинг',
                        year: '2007…2013',
                    },
                ],
            },
            {
                model: 'Grandis',
                generations: [],
            },
            {
                model: 'i-MiEV',
                generations: [],
            },
            {
                model: 'L200',
                generations: [
                    {
                        name: 'III',
                        year: '1996…2006',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2004…2006',
                    },
                    {
                        name: 'IV',
                        year: '2006…2011',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2011…2013',
                    },
                    {
                        name: 'IV · 2-й рестайлинг',
                        year: '2013…2015',
                    },
                    {
                        name: 'V',
                        year: '2015…2019',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'L300',
                generations: [
                    {
                        name: 'I',
                        year: '1980…1986',
                    },
                    {
                        name: 'II',
                        year: '1986…1998',
                    },
                ],
            },
            {
                model: 'L400',
                generations: [],
            },
            {
                model: 'Lancer',
                generations: [
                    {
                        name: 'VI',
                        year: '1988…1989',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '1988…1995',
                    },
                    {
                        name: 'VII',
                        year: '1991…2000',
                    },
                    {
                        name: 'VIII',
                        year: '1995…1997',
                    },
                    {
                        name: 'IX',
                        year: '2000…2005',
                    },
                    {
                        name: 'IX · Рестайлинг',
                        year: '2005…2010',
                    },
                    {
                        name: 'X',
                        year: '2007…2011',
                    },
                    {
                        name: 'X · Рестайлинг',
                        year: '2010…2015',
                    },
                ],
            },
            {
                model: 'Lancer Evolution',
                generations: [
                    {
                        name: 'V',
                        year: '1998…1999',
                    },
                    {
                        name: 'X',
                        year: '2008…2016',
                    },
                ],
            },
            {
                model: 'Mirage',
                generations: [
                    {
                        name: 'V',
                        year: '1995…2002',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'Montero',
                generations: [
                    {
                        name: 'II',
                        year: '1991…1998',
                    },
                    {
                        name: 'III',
                        year: '1999…2003',
                    },
                ],
            },
            {
                model: 'Montero Sport',
                generations: [],
            },
            {
                model: 'Outlander',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2008',
                    },
                    {
                        name: 'XL',
                        year: '2005…2009',
                    },
                    {
                        name: 'XL · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'III',
                        year: '2012…2014',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2014…2016',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'III · 3-й рестайлинг',
                        year: '2018…2022',
                    },
                ],
            },
            {
                model: 'Outlander Sport',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2012',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'Pajero',
                generations: [
                    {
                        name: 'I',
                        year: '1982…1991',
                    },
                    {
                        name: 'II',
                        year: '1991…1997',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1997…2004',
                    },
                    {
                        name: 'III',
                        year: '1999…2003',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'IV',
                        year: '2006…2011',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2011…2014',
                    },
                    {
                        name: 'IV · 2-й рестайлинг',
                        year: '2014…2022',
                    },
                ],
            },
            {
                model: 'Pajero Pinin',
                generations: [],
            },
            {
                model: 'Pajero Sport',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2013',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2013…2016',
                    },
                    {
                        name: 'III',
                        year: '2016…2021',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Sigma',
                generations: [],
            },
            {
                model: 'Space Gear',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1997',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1997…2007',
                    },
                ],
            },
            {
                model: 'Space Runner',
                generations: [
                    {
                        name: 'I',
                        year: '1991…1995',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1995…1999',
                    },
                    {
                        name: 'II',
                        year: '1999…2002',
                    },
                ],
            },
            {
                model: 'Space Star',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2002',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2005',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Space Wagon',
                generations: [
                    {
                        name: 'Typ D00',
                        year: '1983…1991',
                    },
                    {
                        name: 'Typ N30',
                        year: 'N40',
                    },
                    {
                        name: 'Typ N50',
                        year: '1998…2004',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Nissan',
        models: [
            {
                model: '100NX',
                generations: [],
            },
            {
                model: '200SX',
                generations: [
                    {
                        name: 'S13',
                        year: '1988…1993',
                    },
                    {
                        name: 'S14',
                        year: '1993…2000',
                    },
                ],
            },
            {
                model: '300ZX',
                generations: [
                    {
                        name: 'I (Z31)',
                        year: '1983…1989',
                    },
                ],
            },
            {
                model: '350Z',
                generations: [
                    {
                        name: 'I (Z33)',
                        year: '2001…2005',
                    },
                ],
            },
            {
                model: '370Z',
                generations: [
                    {
                        name: 'II (Z34) · Рестайлинг',
                        year: '2012…2020',
                    },
                ],
            },
            {
                model: 'Almera',
                generations: [
                    {
                        name: 'I (N15)',
                        year: '1995…2000',
                    },
                    {
                        name: 'II (N16)',
                        year: '2000…2003',
                    },
                    {
                        name: 'II (N16) · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'III (G15)',
                        year: '2012…2018',
                    },
                ],
            },
            {
                model: 'Almera Classic',
                generations: [],
            },
            {
                model: 'Almera Tino',
                generations: [],
            },
            {
                model: 'Altima',
                generations: [
                    {
                        name: 'II (L30)',
                        year: '1997…1999',
                    },
                    {
                        name: 'III (L31)',
                        year: '2001…2004',
                    },
                    {
                        name: 'IV (L32)',
                        year: '2007…2009',
                    },
                    {
                        name: 'IV (L32) · Рестайлинг',
                        year: '2009…2012',
                    },
                ],
            },
            {
                model: 'Armada',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2007',
                    },
                ],
            },
            {
                model: 'Bluebird',
                generations: [
                    {
                        name: 'VI (910)',
                        year: '1979…1993',
                    },
                    {
                        name: 'VII (U11)',
                        year: '1983…1990',
                    },
                ],
            },
            {
                model: 'Cherry',
                generations: [
                    {
                        name: 'IV (N12)',
                        year: '1982…1986',
                    },
                ],
            },
            {
                model: 'Cube',
                generations: [
                    {
                        name: 'III',
                        year: '2008…2014',
                    },
                ],
            },
            {
                model: 'e-NV200',
                generations: [
                    {
                        name: 'I',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Frontier',
                generations: [
                    {
                        name: 'II',
                        year: '2005…2015',
                    },
                ],
            },
            {
                model: 'Juke',
                generations: [
                    {
                        name: 'YF15',
                        year: '2010…2014',
                    },
                    {
                        name: 'YF15 · Рестайлинг',
                        year: '2013…2019',
                    },
                ],
            },
            {
                model: 'Kicks',
                generations: [
                    {
                        name: 'I',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Laurel',
                generations: [
                    {
                        name: 'IV (C31)',
                        year: '1980…1984',
                    },
                    {
                        name: 'V (C32)',
                        year: '1984…1989',
                    },
                ],
            },
            {
                model: 'Leaf',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2017',
                    },
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Maxima',
                generations: [
                    {
                        name: 'III (J30)',
                        year: '1988…1994',
                    },
                    {
                        name: 'IV (A32)',
                        year: '1994…2000',
                    },
                    {
                        name: 'V (A33)',
                        year: '2000…2006',
                    },
                    {
                        name: 'VI (A34)',
                        year: '2003…2006',
                    },
                    {
                        name: 'VII (A36)',
                        year: '2015…2018',
                    },
                ],
            },
            {
                model: 'Micra',
                generations: [
                    {
                        name: 'II (K11)',
                        year: '1992…2000',
                    },
                    {
                        name: 'II (K11) · Рестайлинг',
                        year: '2000…2003',
                    },
                    {
                        name: 'III (K12)',
                        year: '2002…2007',
                    },
                    {
                        name: 'III (K12) · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'IV (K13)',
                        year: '2010…2013',
                    },
                    {
                        name: 'V (K14)',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Murano',
                generations: [
                    {
                        name: 'I (Z50)',
                        year: '2002…2008',
                    },
                    {
                        name: 'II (Z51)',
                        year: '2008…2011',
                    },
                    {
                        name: 'II (Z51) · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'III (Z52)',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Navara',
                generations: [
                    {
                        name: 'D22',
                        year: '1997…2004',
                    },
                    {
                        name: 'D40',
                        year: '2004…2010',
                    },
                ],
            },
            {
                model: 'Note',
                generations: [
                    {
                        name: 'I (E11)',
                        year: '2005…2009',
                    },
                    {
                        name: 'I (E11) · Рестайлинг',
                        year: '2009…2014',
                    },
                    {
                        name: 'II (E12)',
                        year: '2012…2016',
                    },
                ],
            },
            {
                model: 'NV200',
                generations: [],
            },
            {
                model: 'Pathfinder',
                generations: [
                    {
                        name: 'R50',
                        year: '1995…1999',
                    },
                    {
                        name: 'R50 · Рестайлинг',
                        year: '1999…2004',
                    },
                    {
                        name: 'R51',
                        year: '2004…2010',
                    },
                    {
                        name: 'R51 · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'R52',
                        year: '2012…2016',
                    },
                ],
            },
            {
                model: 'Patrol',
                generations: [
                    {
                        name: 'K160',
                        year: 'K260',
                    },
                    {
                        name: 'K160',
                        year: 'K260 · Рестайлинг',
                    },
                    {
                        name: 'Y60',
                        year: '1987…1998',
                    },
                    {
                        name: 'Y61',
                        year: '1997…2004',
                    },
                    {
                        name: 'Y61 · Рестайлинг',
                        year: '2004…2013',
                    },
                    {
                        name: 'Y62',
                        year: '2010…2014',
                    },
                    {
                        name: 'Y62 · Рестайлинг',
                        year: '2013…2020',
                    },
                ],
            },
            {
                model: 'Pixo',
                generations: [],
            },
            {
                model: 'Prairie',
                generations: [
                    {
                        name: 'II (M11)',
                        year: '1988…1998',
                    },
                    {
                        name: 'III (M12)',
                        year: '1998…2004',
                    },
                ],
            },
            {
                model: 'Primastar',
                generations: [],
            },
            {
                model: 'Primera',
                generations: [
                    {
                        name: 'P10',
                        year: '1990…1997',
                    },
                    {
                        name: 'P11',
                        year: '1996…1999',
                    },
                    {
                        name: 'P11 · Рестайлинг',
                        year: '1999…2002',
                    },
                    {
                        name: 'P12',
                        year: '2001…2012',
                    },
                ],
            },
            {
                model: 'Pulsar',
                generations: [
                    {
                        name: 'V',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Qashqai',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'II',
                        year: '2013…2019',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2017…2022',
                    },
                ],
            },
            {
                model: 'Qashqai+2',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2010…2013',
                    },
                ],
            },
            {
                model: 'Quest',
                generations: [
                    {
                        name: 'II · Рестайлинг',
                        year: '2000…2002',
                    },
                    {
                        name: 'III',
                        year: '2003…2006',
                    },
                    {
                        name: 'IV',
                        year: '2010…2020',
                    },
                ],
            },
            {
                model: 'Rogue',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2010…2013',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…2020',
                    },
                    {
                        name: 'III',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Rogue Sport',
                generations: [
                    {
                        name: 'I',
                        year: '2017…2019',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Sentra',
                generations: [
                    {
                        name: 'V (B15)',
                        year: '1999…2006',
                    },
                    {
                        name: 'VI (B16)',
                        year: '2006…2009',
                    },
                    {
                        name: 'VII (B17)',
                        year: '2012…2017',
                    },
                    {
                        name: 'VII (B17) · Рестайлинг',
                        year: '2015…2019',
                    },
                    {
                        name: 'VIII (B18)',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Serena',
                generations: [
                    {
                        name: 'I (C23)',
                        year: '1991…2002',
                    },
                ],
            },
            {
                model: 'Silvia',
                generations: [
                    {
                        name: 'S12',
                        year: '1983…1988',
                    },
                ],
            },
            {
                model: 'Skyline',
                generations: [
                    {
                        name: 'R31',
                        year: '1985…1989',
                    },
                    {
                        name: 'V35',
                        year: '2001…2007',
                    },
                ],
            },
            {
                model: 'Sunny',
                generations: [
                    {
                        name: 'VI (B12)',
                        year: '1985…1990',
                    },
                    {
                        name: 'N13',
                        year: '1987…1991',
                    },
                    {
                        name: 'Y10',
                        year: '1990…2000',
                    },
                    {
                        name: 'N14',
                        year: '1991…1995',
                    },
                    {
                        name: 'VIII (B14)',
                        year: '1993…1998',
                    },
                ],
            },
            {
                model: 'Sylphy',
                generations: [],
            },
            {
                model: 'Teana',
                generations: [
                    {
                        name: 'I (J31)',
                        year: '2003…2008',
                    },
                    {
                        name: 'J31 · Рестайлинг',
                        year: '2005…2008',
                    },
                    {
                        name: 'II (J32)',
                        year: '2008…2014',
                    },
                    {
                        name: 'III (L33)',
                        year: '2014…2021',
                    },
                ],
            },
            {
                model: 'Terrano',
                generations: [
                    {
                        name: 'I',
                        year: '1985…1995',
                    },
                    {
                        name: 'II',
                        year: '1993…1996',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '1999…2006',
                    },
                    {
                        name: 'D10',
                        year: '2014…2022',
                    },
                ],
            },
            {
                model: 'Tiida',
                generations: [
                    {
                        name: 'I (C11)',
                        year: '2004…2010',
                    },
                    {
                        name: 'I (C11) · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'II (C13)',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Titan',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2015',
                    },
                    {
                        name: 'II',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: 'Vanette',
                generations: [
                    {
                        name: 'C22',
                        year: '1985…1994',
                    },
                    {
                        name: 'C23',
                        year: '1991…2002',
                    },
                ],
            },
            {
                model: 'X-Trail',
                generations: [
                    {
                        name: 'I (T30)',
                        year: '2001…2007',
                    },
                    {
                        name: 'I (T30) · Рестайлинг',
                        year: '2003…2007',
                    },
                    {
                        name: 'II (T31)',
                        year: '2007…2011',
                    },
                    {
                        name: 'II (T31) · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'II (T32)',
                        year: '2013…2020',
                    },
                    {
                        name: 'III (T32) · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'XTerra',
                generations: [
                    {
                        name: 'WD22',
                        year: '1999…2001',
                    },
                    {
                        name: 'N50 · Рестайлинг',
                        year: '2008…2015',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Opel',
        models: [
            {
                model: 'Adam',
                generations: [],
            },
            {
                model: 'Agila',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2003',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2003…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2015',
                    },
                ],
            },
            {
                model: 'Ampera',
                generations: [
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Antara',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2011',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…',
                    },
                ],
            },
            {
                model: 'Ascona',
                generations: [
                    {
                        name: 'B',
                        year: '1975…1981',
                    },
                    {
                        name: 'C',
                        year: '1981…1988',
                    },
                ],
            },
            {
                model: 'Astra',
                generations: [
                    {
                        name: 'F',
                        year: '1991…1994',
                    },
                    {
                        name: 'F · Рестайлинг',
                        year: '1994…2002',
                    },
                    {
                        name: 'G',
                        year: '1998…2009',
                    },
                    {
                        name: 'H',
                        year: '2004…2006',
                    },
                    {
                        name: 'H · Рестайлинг',
                        year: '2006…2014',
                    },
                    {
                        name: 'J',
                        year: '2009…2012',
                    },
                    {
                        name: 'J · Рестайлинг',
                        year: '2012…2018',
                    },
                    {
                        name: 'K',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Calibra',
                generations: [],
            },
            {
                model: 'Combo',
                generations: [
                    {
                        name: 'B',
                        year: '1993…2001',
                    },
                    {
                        name: 'C',
                        year: '2001…2005',
                    },
                    {
                        name: 'C · Рестайлинг',
                        year: '2005…2011',
                    },
                    {
                        name: 'D',
                        year: '2011…',
                    },
                ],
            },
            {
                model: 'Corsa',
                generations: [
                    {
                        name: 'A',
                        year: '1982…1993',
                    },
                    {
                        name: 'B',
                        year: '1993…2000',
                    },
                    {
                        name: 'C',
                        year: '2000…2003',
                    },
                    {
                        name: 'C · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'D',
                        year: '2006…2011',
                    },
                    {
                        name: 'D · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'E',
                        year: '2014…2019',
                    },
                ],
            },
            {
                model: 'Crossland X',
                generations: [],
            },
            {
                model: 'Frontera',
                generations: [
                    {
                        name: 'A',
                        year: '1991…1998',
                    },
                    {
                        name: 'B',
                        year: '1998…2001',
                    },
                    {
                        name: 'B · Рестайлинг',
                        year: '2001…2004',
                    },
                ],
            },
            {
                model: 'Grandland X',
                generations: [],
            },
            {
                model: 'Insignia',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Kadett',
                generations: [
                    {
                        name: 'E',
                        year: '1983…1991',
                    },
                    {
                        name: 'E · Рестайлинг',
                        year: '1989…1993',
                    },
                ],
            },
            {
                model: 'Meriva',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2014',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Mokka',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'Monterey',
                generations: [
                    {
                        name: 'I',
                        year: '1992…1998',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1998…1999',
                    },
                ],
            },
            {
                model: 'Omega',
                generations: [
                    {
                        name: 'A',
                        year: '1986…1993',
                    },
                    {
                        name: 'B',
                        year: '1994…1999',
                    },
                    {
                        name: 'B · Рестайлинг',
                        year: '1999…2003',
                    },
                ],
            },
            {
                model: 'Rekord',
                generations: [],
            },
            {
                model: 'Senator',
                generations: [
                    {
                        name: 'I',
                        year: '1978…1987',
                    },
                ],
            },
            {
                model: 'Signum',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2008',
                    },
                ],
            },
            {
                model: 'Sintra',
                generations: [],
            },
            {
                model: 'Tigra',
                generations: [
                    {
                        name: 'I',
                        year: '1994…2000',
                    },
                    {
                        name: 'II',
                        year: '2004…2009',
                    },
                ],
            },
            {
                model: 'Vectra',
                generations: [
                    {
                        name: 'A',
                        year: '1988…1995',
                    },
                    {
                        name: 'B',
                        year: '1995…1999',
                    },
                    {
                        name: 'B · Рестайлинг',
                        year: '1999…2002',
                    },
                    {
                        name: 'C',
                        year: '2002…2005',
                    },
                    {
                        name: 'C · Рестайлинг',
                        year: '2005…2008',
                    },
                ],
            },
            {
                model: 'Vivaro',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2014',
                    },
                    {
                        name: 'II',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Zafira',
                generations: [
                    {
                        name: 'A',
                        year: '1999…2003',
                    },
                    {
                        name: 'A · Рестайлинг',
                        year: '2002…2006',
                    },
                    {
                        name: 'B',
                        year: '2005…2008',
                    },
                    {
                        name: 'B · Рестайлинг',
                        year: '2008…2014',
                    },
                    {
                        name: 'C',
                        year: '2011…2016',
                    },
                    {
                        name: 'C · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Zafira Life',
                generations: [
                    {
                        name: 'I',
                        year: '2019…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Peugeot',
        models: [
            {
                model: '106',
                generations: [
                    {
                        name: 'I',
                        year: '1991…1996',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1996…2003',
                    },
                ],
            },
            {
                model: '107',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2008',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2012…2015',
                    },
                ],
            },
            {
                model: '108',
                generations: [],
            },
            {
                model: '205',
                generations: [],
            },
            {
                model: '206',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2009',
                    },
                    {
                        name: 'Plus',
                        year: '2009…2012',
                    },
                ],
            },
            {
                model: '207',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2013',
                    },
                ],
            },
            {
                model: '208',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2015',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2015…2019',
                    },
                ],
            },
            {
                model: '301',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: '305',
                generations: [],
            },
            {
                model: '306',
                generations: [],
            },
            {
                model: '307',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2005',
                    },
                    {
                        name: 'CC',
                        year: '2003…2008',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2008',
                    },
                ],
            },
            {
                model: '308',
                generations: [
                    {
                        name: 'T7',
                        year: '2007…2011',
                    },
                    {
                        name: 'T7 · Рестайлинг',
                        year: '2011…2015',
                    },
                    {
                        name: 'T9',
                        year: '2013…2017',
                    },
                    {
                        name: 'T9 · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: '309',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1989…1993',
                    },
                ],
            },
            {
                model: '405',
                generations: [],
            },
            {
                model: '406',
                generations: [
                    {
                        name: 'I',
                        year: '1995…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2005',
                    },
                ],
            },
            {
                model: '407',
                generations: [],
            },
            {
                model: '408',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2017',
                    },
                ],
            },
            {
                model: '508',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'II',
                        year: '2018…',
                    },
                ],
            },
            {
                model: '605',
                generations: [
                    {
                        name: 'I',
                        year: '1989…1994',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1994…1999',
                    },
                ],
            },
            {
                model: '607',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2010',
                    },
                ],
            },
            {
                model: '806',
                generations: [
                    {
                        name: '221',
                        year: '1994…1999',
                    },
                    {
                        name: '221 · Рестайлинг',
                        year: '1999…2002',
                    },
                ],
            },
            {
                model: '807',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2014',
                    },
                ],
            },
            {
                model: '1007',
                generations: [],
            },
            {
                model: '2008',
                generations: [
                    {
                        name: 'I',
                        year: '2013…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…2019',
                    },
                    {
                        name: 'II',
                        year: '2019…',
                    },
                ],
            },
            {
                model: '3008',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'II',
                        year: '2016…',
                    },
                ],
            },
            {
                model: '4007',
                generations: [],
            },
            {
                model: '4008',
                generations: [],
            },
            {
                model: '5008',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Bipper',
                generations: [],
            },
            {
                model: 'Expert',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2006',
                    },
                    {
                        name: 'II',
                        year: '2007…2012',
                    },
                    {
                        name: 'III',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Partner',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2002',
                    },
                    {
                        name: 'Origin · Рестайлинг',
                        year: '2002…2012',
                    },
                    {
                        name: 'II',
                        year: '2008…2012',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2012…2017',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Traveller',
                generations: [],
            },
        ],
    },
    {
        brand: 'Plymouth',
        models: [
            {
                model: 'Breeze',
                generations: [],
            },
            {
                model: 'Neon',
                generations: [],
            },
            {
                model: 'Voyager',
                generations: [
                    {
                        name: 'II',
                        year: '1990…1995',
                    },
                    {
                        name: 'III',
                        year: '1995…2000',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Pontiac',
        models: [
            {
                model: 'Aztek',
                generations: [],
            },
            {
                model: 'Firebird',
                generations: [
                    {
                        name: 'III',
                        year: '1982…1992',
                    },
                ],
            },
            {
                model: 'G6',
                generations: [],
            },
            {
                model: 'Grand AM',
                generations: [
                    {
                        name: 'IV',
                        year: '1992…1998',
                    },
                    {
                        name: 'V',
                        year: '1999…2005',
                    },
                ],
            },
            {
                model: 'Solstice',
                generations: [],
            },
            {
                model: 'Torrent',
                generations: [],
            },
            {
                model: 'Trans Sport',
                generations: [
                    {
                        name: 'I',
                        year: '1989…1996',
                    },
                    {
                        name: 'II',
                        year: '1996…1999',
                    },
                ],
            },
            {
                model: 'Vibe',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2009',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Porsche',
        models: [
            {
                model: '911',
                generations: [
                    {
                        name: '996 · Рестайлинг',
                        year: '2000…2005',
                    },
                    {
                        name: '997',
                        year: '2004…2010',
                    },
                    {
                        name: '991 · Рестайлинг',
                        year: '2015…2020',
                    },
                    {
                        name: '992',
                        year: '2018…',
                    },
                ],
            },
            {
                model: '924',
                generations: [],
            },
            {
                model: 'Boxster',
                generations: [
                    {
                        name: '986',
                        year: '1996…2002',
                    },
                    {
                        name: '986 · Рестайлинг',
                        year: '2002…2004',
                    },
                    {
                        name: '981',
                        year: '2012…2016',
                    },
                    {
                        name: '718',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Cayenne',
                generations: [
                    {
                        name: '955',
                        year: '2002…2006',
                    },
                    {
                        name: '957',
                        year: '2006…2010',
                    },
                    {
                        name: '958',
                        year: '2010…2014',
                    },
                    {
                        name: '958 · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'III',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Cayenne Coupe',
                generations: [
                    {
                        name: 'I',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Cayman',
                generations: [
                    {
                        name: '982',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Macan',
                generations: [
                    {
                        name: 'I (рестайлинг)',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Panamera',
                generations: [
                    {
                        name: '970',
                        year: '2009…2013',
                    },
                    {
                        name: '970 · Рестайлинг',
                        year: '2013…2016',
                    },
                    {
                        name: '971',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Taycan',
                generations: [],
            },
        ],
    },
    {
        brand: 'Proton',
        models: [
            {
                model: 'Persona',
                generations: [
                    {
                        name: 'I',
                        year: '1993…2007',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Ravon',
        models: [
            {
                model: 'Nexia R3',
                generations: [],
            },
            {
                model: 'R2',
                generations: [],
            },
            {
                model: 'R4',
                generations: [],
            },
        ],
    },
    {
        brand: 'Renault',
        models: [
            {
                model: '11',
                generations: [],
            },
            {
                model: '19',
                generations: [
                    {
                        name: 'I',
                        year: '1988…1992',
                    },
                    {
                        name: 'II',
                        year: '1992…2002',
                    },
                ],
            },
            {
                model: '21',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1989…1995',
                    },
                ],
            },
            {
                model: '25',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1988…1992',
                    },
                ],
            },
            {
                model: 'Arkana',
                generations: [],
            },
            {
                model: 'Captur',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…2019',
                    },
                ],
            },
            {
                model: 'City K-ZE',
                generations: [
                    {
                        name: 'I',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Clio',
                generations: [
                    {
                        name: 'I',
                        year: '1990…1998',
                    },
                    {
                        name: 'II',
                        year: '1997…2013',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2003…2013',
                    },
                    {
                        name: 'III',
                        year: '2005…2014',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'IV',
                        year: '2012…2016',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2016…2019',
                    },
                ],
            },
            {
                model: 'Clio RS',
                generations: [
                    {
                        name: 'II',
                        year: '2000…2001',
                    },
                ],
            },
            {
                model: 'Dokker',
                generations: [],
            },
            {
                model: 'Duster',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2015',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2015…2021',
                    },
                    {
                        name: 'II',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Espace',
                generations: [
                    {
                        name: 'I',
                        year: '1984…1988',
                    },
                    {
                        name: 'II',
                        year: '1991…1996',
                    },
                    {
                        name: 'III',
                        year: '1996…2002',
                    },
                    {
                        name: 'IV',
                        year: '2002…2006',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2006…2012',
                    },
                    {
                        name: 'V',
                        year: '2014…2019',
                    },
                ],
            },
            {
                model: 'Fluence',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2017',
                    },
                ],
            },
            {
                model: 'Grand Espace',
                generations: [
                    {
                        name: 'III',
                        year: '1996…2002',
                    },
                    {
                        name: 'IV',
                        year: '2002…2006',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2006…2012',
                    },
                ],
            },
            {
                model: 'Grand Scenic',
                generations: [
                    {
                        name: 'II',
                        year: '2003…2006',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'III',
                        year: '2009…2012',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2012…2013',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2013…2016',
                    },
                    {
                        name: 'IV',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Kadjar',
                generations: [],
            },
            {
                model: 'Kangoo',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2003',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2003…2009',
                    },
                    {
                        name: 'II',
                        year: '2008…2013',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2013…2021',
                    },
                ],
            },
            {
                model: 'Kaptur',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Koleos',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2011',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…2013',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2013…2016',
                    },
                    {
                        name: 'II',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Laguna',
                generations: [
                    {
                        name: 'I',
                        year: '1993…2001',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1998…2001',
                    },
                    {
                        name: 'II',
                        year: '2001…2007',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2005…2008',
                    },
                    {
                        name: 'III',
                        year: '2007…2015',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2010…2015',
                    },
                ],
            },
            {
                model: 'Latitude',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2014',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2014…2015',
                    },
                ],
            },
            {
                model: 'Lodgy',
                generations: [],
            },
            {
                model: 'Logan',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2008…2015',
                    },
                    {
                        name: 'II',
                        year: '2013…',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Logan Stepway',
                generations: [],
            },
            {
                model: 'Megane',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2003',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2003',
                    },
                    {
                        name: 'II',
                        year: '2002…2009',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'III',
                        year: '2008…2014',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2012…2014',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2014…2016',
                    },
                    {
                        name: 'IV',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'Modus',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2008',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2012',
                    },
                ],
            },
            {
                model: 'Rapid',
                generations: [],
            },
            {
                model: 'Safrane',
                generations: [
                    {
                        name: 'I',
                        year: '1992…1996',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1996…2000',
                    },
                ],
            },
            {
                model: 'Sandero',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2014',
                    },
                    {
                        name: 'II',
                        year: '2013…2018',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Sandero Stepway',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2014',
                    },
                    {
                        name: 'II',
                        year: '2014…2018',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Scenic',
                generations: [
                    {
                        name: 'I',
                        year: '1996…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2003',
                    },
                    {
                        name: 'II',
                        year: '2003…2006',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'III',
                        year: '2009…2012',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2012…2013',
                    },
                    {
                        name: 'III · 2-й рестайлинг',
                        year: '2013…2016',
                    },
                    {
                        name: 'IV',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Scenic RX4',
                generations: [],
            },
            {
                model: 'Symbol',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2002',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2002…2006',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2012',
                    },
                ],
            },
            {
                model: 'Talisman',
                generations: [
                    {
                        name: 'I',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'Trafic',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '1989…1997',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '1997…2002',
                    },
                    {
                        name: 'II',
                        year: '2001…2006',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2006…2014',
                    },
                    {
                        name: 'III',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Twingo',
                generations: [
                    {
                        name: 'I',
                        year: '1993…1998',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1998…2000',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2000…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2011',
                    },
                ],
            },
            {
                model: 'Vel Satis',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2005',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2005…2009',
                    },
                ],
            },
            {
                model: 'Wind',
                generations: [],
            },
            {
                model: 'ZOE',
                generations: [],
            },
        ],
    },
    {
        brand: 'Rover',
        models: [
            {
                model: '25',
                generations: [],
            },
            {
                model: '45',
                generations: [],
            },
            {
                model: '75',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2005',
                    },
                ],
            },
            {
                model: '100',
                generations: [],
            },
            {
                model: '200',
                generations: [
                    {
                        name: 'R8',
                        year: '1989…1995',
                    },
                    {
                        name: 'R3',
                        year: '1995…1999',
                    },
                ],
            },
            {
                model: '400',
                generations: [
                    {
                        name: 'R8',
                        year: '1990…1995',
                    },
                    {
                        name: 'HH-R',
                        year: '1995…2000',
                    },
                ],
            },
            {
                model: '600',
                generations: [],
            },
            {
                model: '800',
                generations: [],
            },
            {
                model: 'Streetwise',
                generations: [],
            },
        ],
    },
    {
        brand: 'Saab',
        models: [
            {
                model: '900',
                generations: [
                    {
                        name: 'II',
                        year: '1993…1998',
                    },
                ],
            },
            {
                model: '9000',
                generations: [
                    {
                        name: 'I',
                        year: '1984…1993',
                    },
                    {
                        name: 'II',
                        year: '1993…1998',
                    },
                ],
            },
            {
                model: '9-3',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2002',
                    },
                    {
                        name: 'II',
                        year: '2002…2008',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2007…2014',
                    },
                ],
            },
            {
                model: '9-5',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2001',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2001…2005',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2005…2010',
                    },
                ],
            },
            {
                model: '9-7X',
                generations: [],
            },
        ],
    },
    {
        brand: 'Saturn',
        models: [
            {
                model: 'Outlook',
                generations: [],
            },
            {
                model: 'Sky',
                generations: [],
            },
            {
                model: 'SL',
                generations: [
                    {
                        name: 'II',
                        year: '1995…1999',
                    },
                ],
            },
            {
                model: 'VUE',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2007',
                    },
                    {
                        name: 'II',
                        year: '2007…2010',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Scion',
        models: [
            {
                model: 'tC',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                ],
            },
            {
                model: 'xD',
                generations: [],
            },
        ],
    },
    {
        brand: 'SEAT',
        models: [
            {
                model: 'Alhambra',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2015',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Altea',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2015',
                    },
                ],
            },
            {
                model: 'Arona',
                generations: [],
            },
            {
                model: 'Arosa',
                generations: [
                    {
                        name: '6H',
                        year: '1997…2000',
                    },
                    {
                        name: '6H · Рестайлинг',
                        year: '2000…2004',
                    },
                ],
            },
            {
                model: 'Ateca',
                generations: [],
            },
            {
                model: 'Cordoba',
                generations: [
                    {
                        name: 'I',
                        year: '1993…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2002',
                    },
                    {
                        name: 'II',
                        year: '2003…2006',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2006…2009',
                    },
                ],
            },
            {
                model: 'Ibiza',
                generations: [
                    {
                        name: 'I',
                        year: '1984…1993',
                    },
                    {
                        name: 'II',
                        year: '1993…1999',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1996…2002',
                    },
                    {
                        name: 'III',
                        year: '2002…2006',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'IV',
                        year: '2008…2012',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'IV · 2-й рестайлинг',
                        year: '2015…2017',
                    },
                    {
                        name: 'V',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Leon',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2005',
                    },
                    {
                        name: 'II',
                        year: '2005…2009',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'III',
                        year: '2012…2016',
                    },
                    {
                        name: 'III (Рестайлинг)',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Mii',
                generations: [],
            },
            {
                model: 'Toledo',
                generations: [
                    {
                        name: 'I',
                        year: '1991…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1995…1999',
                    },
                    {
                        name: 'II',
                        year: '1998…2004',
                    },
                    {
                        name: 'III',
                        year: '2004…2010',
                    },
                    {
                        name: 'IV',
                        year: '2012…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Skoda',
        models: [
            {
                model: 'Citigo',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2017',
                    },
                ],
            },
            {
                model: 'Fabia',
                generations: [
                    {
                        name: '6Y',
                        year: '1999…2004',
                    },
                    {
                        name: '6Y · Рестайлинг',
                        year: '2002…2007',
                    },
                    {
                        name: '5J',
                        year: '2007…2010',
                    },
                    {
                        name: '5J · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'NJ',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Favorit',
                generations: [],
            },
            {
                model: 'Felicia',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1998',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1998…2001',
                    },
                ],
            },
            {
                model: 'Forman',
                generations: [],
            },
            {
                model: 'Karoq',
                generations: [],
            },
            {
                model: 'Kodiaq',
                generations: [],
            },
            {
                model: 'Octavia',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2010',
                    },
                    {
                        name: 'II',
                        year: '2004…2008',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2008…2013',
                    },
                    {
                        name: 'III',
                        year: '2013…2017',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2017…2020',
                    },
                    {
                        name: 'IV',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Rapid',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2017',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…2020',
                    },
                    {
                        name: 'II',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Roomster',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2010…2015',
                    },
                ],
            },
            {
                model: 'Superb',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2013',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2013…2015',
                    },
                    {
                        name: 'III',
                        year: '2015…2020',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Yeti',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2017',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Smart',
        models: [
            {
                model: 'Forfour',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2006',
                    },
                    {
                        name: 'II',
                        year: '2014…2021',
                    },
                ],
            },
            {
                model: 'Fortwo',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2003',
                    },
                    {
                        name: 'II',
                        year: '2007…2012',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'III',
                        year: '2014…2021',
                    },
                ],
            },
            {
                model: 'Roadster',
                generations: [],
            },
        ],
    },
    {
        brand: 'SsangYong',
        models: [
            {
                model: 'Actyon',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2012',
                    },
                    {
                        name: 'II',
                        year: '2010…2013',
                    },
                ],
            },
            {
                model: 'Actyon Sports',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2012',
                    },
                ],
            },
            {
                model: 'Korando',
                generations: [
                    {
                        name: 'II',
                        year: '1996…2006',
                    },
                    {
                        name: 'III',
                        year: '2010…2013',
                    },
                ],
            },
            {
                model: 'Kyron',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2015',
                    },
                ],
            },
            {
                model: 'Musso',
                generations: [
                    {
                        name: 'I',
                        year: '1993…1998',
                    },
                    {
                        name: 'II',
                        year: '1998…2005',
                    },
                ],
            },
            {
                model: 'Rexton',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2007',
                    },
                    {
                        name: 'II',
                        year: '2006…2012',
                    },
                    {
                        name: 'III',
                        year: '2012…2017',
                    },
                    {
                        name: 'IV',
                        year: '2017…2021',
                    },
                ],
            },
            {
                model: 'Rodius',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2013',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Subaru',
        models: [
            {
                model: 'Ascent',
                generations: [
                    {
                        name: 'I',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Baja',
                generations: [],
            },
            {
                model: 'BRZ',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2016',
                    },
                ],
            },
            {
                model: 'Forester',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2002',
                    },
                    {
                        name: 'II',
                        year: '2002…2005',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2005…2008',
                    },
                    {
                        name: 'III',
                        year: '2007…2011',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2011…2013',
                    },
                    {
                        name: 'IV',
                        year: '2012…2015',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2015…2018',
                    },
                    {
                        name: 'V',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Impreza',
                generations: [
                    {
                        name: 'I',
                        year: '1992…2000',
                    },
                    {
                        name: 'II',
                        year: '2000…2002',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2002…2005',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2005…2007',
                    },
                    {
                        name: 'III',
                        year: '2007…2011',
                    },
                    {
                        name: 'IV',
                        year: '2011…2014',
                    },
                    {
                        name: 'V',
                        year: '2013…2016',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Justy',
                generations: [
                    {
                        name: 'MS)',
                        year: '1995…2003',
                    },
                    {
                        name: '3 (G3X)',
                        year: '2003…2007',
                    },
                    {
                        name: 'IV',
                        year: '2007…2011',
                    },
                ],
            },
            {
                model: 'Legacy',
                generations: [
                    {
                        name: 'I',
                        year: '1989…1994',
                    },
                    {
                        name: 'II',
                        year: '1994…1999',
                    },
                    {
                        name: 'III',
                        year: '1998…2004',
                    },
                    {
                        name: 'IV',
                        year: '2003…2009',
                    },
                    {
                        name: 'V',
                        year: '2009…2013',
                    },
                    {
                        name: 'VII',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Outback',
                generations: [
                    {
                        name: 'I',
                        year: '1994…1999',
                    },
                    {
                        name: 'II',
                        year: '1999…2003',
                    },
                    {
                        name: 'III',
                        year: '2003…2009',
                    },
                    {
                        name: 'IV',
                        year: '2009…2012',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'V',
                        year: '2015…2018',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2017…2021',
                    },
                    {
                        name: 'VI',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'SVX',
                generations: [],
            },
            {
                model: 'Trezia',
                generations: [],
            },
            {
                model: 'Tribeca',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2014',
                    },
                ],
            },
            {
                model: 'XV',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…2017',
                    },
                    {
                        name: 'II',
                        year: '2017…2021',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Suzuki',
        models: [
            {
                model: 'Aerio',
                generations: [],
            },
            {
                model: 'Alto',
                generations: [
                    {
                        name: 'IV',
                        year: '1994…1998',
                    },
                    {
                        name: 'V',
                        year: '1998…2004',
                    },
                    {
                        name: 'VII',
                        year: '2008…',
                    },
                ],
            },
            {
                model: 'Baleno',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2002',
                    },
                ],
            },
            {
                model: 'Grand Vitara',
                generations: [
                    {
                        name: 'FT',
                        year: '1997…2001',
                    },
                    {
                        name: 'FT · Рестайлинг',
                        year: '2001…2006',
                    },
                    {
                        name: 'JT',
                        year: '2005…2008',
                    },
                    {
                        name: 'JT · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'JT · 2-й рестайлинг',
                        year: '2012…2015',
                    },
                ],
            },
            {
                model: 'Ignis',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2006',
                    },
                    {
                        name: 'II',
                        year: '2003…2008',
                    },
                ],
            },
            {
                model: 'Jimny',
                generations: [
                    {
                        name: 'III · Рестайлинг',
                        year: '2005…2012',
                    },
                    {
                        name: 'IV',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Kizashi',
                generations: [],
            },
            {
                model: 'Liana',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2007',
                    },
                ],
            },
            {
                model: 'Reno',
                generations: [],
            },
            {
                model: 'Splash',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                ],
            },
            {
                model: 'Swift',
                generations: [
                    {
                        name: 'II',
                        year: '1989…1995',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1995…2003',
                    },
                    {
                        name: 'III',
                        year: '2004…2010',
                    },
                    {
                        name: 'IV',
                        year: '2010…2014',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2013…2017',
                    },
                ],
            },
            {
                model: 'SX4',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2014',
                    },
                    {
                        name: 'II',
                        year: '2013…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Vitara',
                generations: [
                    {
                        name: 'TA',
                        year: '1988…2006',
                    },
                    {
                        name: 'IV',
                        year: '2014…2019',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Wagon R+',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2000',
                    },
                    {
                        name: 'II',
                        year: '2000…2008',
                    },
                ],
            },
            {
                model: 'X-90',
                generations: [],
            },
            {
                model: 'XL-7',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2006',
                    },
                    {
                        name: 'II ',
                        year: '2006…2009',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Tata',
        models: [
            {
                model: 'Telcoline',
                generations: [],
            },
        ],
    },
    {
        brand: 'Tesla',
        models: [
            {
                model: 'Model 3',
                generations: [],
            },
            {
                model: 'Model S',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2017',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Model X',
                generations: [],
            },
            {
                model: 'Model Y',
                generations: [],
            },
        ],
    },
    {
        brand: 'Toyota',
        models: [
            {
                model: '4Runner',
                generations: [
                    {
                        name: 'II',
                        year: '1989…1992',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1992…1995',
                    },
                    {
                        name: 'IV',
                        year: '2002…2006',
                    },
                    {
                        name: 'V',
                        year: '2009…2013',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2013…',
                    },
                ],
            },
            {
                model: 'Alphard',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2008',
                    },
                    {
                        name: 'II',
                        year: '2008…2011',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Auris',
                generations: [
                    {
                        name: 'I (E150)',
                        year: '2006…2009',
                    },
                    {
                        name: 'I (E150) · Рестайлинг',
                        year: '2010…2012',
                    },
                    {
                        name: 'II (E180)',
                        year: '2012…2015',
                    },
                    {
                        name: 'II (E180) · Рестайлинг',
                        year: '2015…2018',
                    },
                ],
            },
            {
                model: 'Avalon',
                generations: [
                    {
                        name: 'III (XX30)',
                        year: '2005…2007',
                    },
                    {
                        name: 'III (XX30) · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'V (XX50)',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Avensis',
                generations: [
                    {
                        name: 'I (T220)',
                        year: '1997…2000',
                    },
                    {
                        name: 'I (T220) · Рестайлинг',
                        year: '2000…2003',
                    },
                    {
                        name: 'II (T250)',
                        year: '2003…2006',
                    },
                    {
                        name: 'II (T250) · Рестайлинг',
                        year: '2006…2009',
                    },
                    {
                        name: 'III (T270)',
                        year: '2008…2012',
                    },
                    {
                        name: 'III (T270) · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'III (T270) (2-ой рестайлинг)',
                        year: '2015…2018',
                    },
                ],
            },
            {
                model: 'Avensis Verso',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2003',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2003…2009',
                    },
                ],
            },
            {
                model: 'Aygo',
                generations: [
                    {
                        name: 'I (AB10)',
                        year: '2005…2008',
                    },
                    {
                        name: 'I (AB10) · Рестайлинг',
                        year: '2008…2012',
                    },
                    {
                        name: 'II (AB40)',
                        year: '2014…2018',
                    },
                    {
                        name: 'II (AB40) · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'C-HR',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Camry',
                generations: [
                    {
                        name: 'V10',
                        year: '1982…1984',
                    },
                    {
                        name: 'V20',
                        year: '1986…1992',
                    },
                    {
                        name: 'XV10',
                        year: '1991…1994',
                    },
                    {
                        name: 'XV10 · Рестайлинг',
                        year: '1994…1996',
                    },
                    {
                        name: 'XV20',
                        year: '1996…2000',
                    },
                    {
                        name: 'XV30',
                        year: '2001…2004',
                    },
                    {
                        name: 'XV30 · Рестайлинг',
                        year: '2004…2006',
                    },
                    {
                        name: 'XV40',
                        year: '2006…2009',
                    },
                    {
                        name: 'XV40 · Рестайлинг',
                        year: '2009…2011',
                    },
                    {
                        name: 'XV50',
                        year: '2011…2014',
                    },
                    {
                        name: 'XV55 · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'XV70',
                        year: '2017…',
                    },
                    {
                        name: 'XV70 · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Camry Solara',
                generations: [
                    {
                        name: 'XV20',
                        year: '1998…2001',
                    },
                    {
                        name: 'XV20 · Рестайлинг',
                        year: '2001…2003',
                    },
                    {
                        name: 'XV30',
                        year: '2003…2006',
                    },
                ],
            },
            {
                model: 'Carina',
                generations: [
                    {
                        name: 'V (T170)',
                        year: '1988…1992',
                    },
                    {
                        name: 'VI (T190)',
                        year: '1992…1998',
                    },
                ],
            },
            {
                model: 'Carina E',
                generations: [],
            },
            {
                model: 'Celica',
                generations: [
                    {
                        name: 'V (T180)',
                        year: '1989…1993',
                    },
                    {
                        name: 'VI (T200) · Рестайлинг',
                        year: '1995…1999',
                    },
                    {
                        name: 'VII (T230)',
                        year: '1999…2002',
                    },
                ],
            },
            {
                model: 'Corolla',
                generations: [
                    {
                        name: 'V (E80)',
                        year: '1983…1990',
                    },
                    {
                        name: 'VI (E90)',
                        year: '1987…1991',
                    },
                    {
                        name: 'VII (E100)',
                        year: '1991…1997',
                    },
                    {
                        name: 'VIII (E110)',
                        year: '1995…2001',
                    },
                    {
                        name: 'VIII (E110) · Рестайлинг',
                        year: '1997…2002',
                    },
                    {
                        name: 'IX (E120',
                        year: '130)',
                    },
                    {
                        name: 'IX (E120',
                        year: '130) · Рестайлинг',
                    },
                    {
                        name: 'X (E140',
                        year: '150)',
                    },
                    {
                        name: 'X (E140',
                        year: '150) · Рестайлинг',
                    },
                    {
                        name: 'XI (E170)',
                        year: '2012…2017',
                    },
                    {
                        name: 'XI (E170) · Рестайлинг',
                        year: '2016…2019',
                    },
                    {
                        name: 'XI (E210) ',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Corolla Verso',
                generations: [
                    {
                        name: 'I (E120)',
                        year: '2001…2004',
                    },
                    {
                        name: 'II (AR10)',
                        year: '2004…2007',
                    },
                    {
                        name: 'II (AR10) · Рестайлинг',
                        year: '2007…2009',
                    },
                ],
            },
            {
                model: 'Echo',
                generations: [
                    {
                        name: 'I',
                        year: '1999…2003',
                    },
                ],
            },
            {
                model: 'Fortuner',
                generations: [
                    {
                        name: 'II ',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'GT 86',
                generations: [
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Hiace',
                generations: [
                    {
                        name: 'IV (H100)',
                        year: '1989…2004',
                    },
                    {
                        name: 'V (H200)',
                        year: '2004…2010',
                    },
                ],
            },
            {
                model: 'Highlander',
                generations: [
                    {
                        name: 'I (XU20) · Рестайлинг',
                        year: '2003…2008',
                    },
                    {
                        name: 'II (XU40)',
                        year: '2008…2010',
                    },
                    {
                        name: 'II (XU40) · Рестайлинг',
                        year: '2010…2014',
                    },
                    {
                        name: 'III (XU50)',
                        year: '2013…2016',
                    },
                    {
                        name: 'III (XU50) · Рестайлинг',
                        year: '2016…2020',
                    },
                    {
                        name: 'IV (XU70)',
                        year: '2019…',
                    },
                ],
            },
            {
                model: 'Hilux',
                generations: [
                    {
                        name: 'VII (AN10)',
                        year: '2005…2008',
                    },
                    {
                        name: 'VII (AN10) · Рестайлинг',
                        year: '2008…2011',
                    },
                    {
                        name: 'VII (AN10) · 2-й рестайлинг',
                        year: '2011…2015',
                    },
                    {
                        name: 'VIII (AN120)',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'iQ',
                generations: [],
            },
            {
                model: 'Land Cruiser',
                generations: [
                    {
                        name: 'J80',
                        year: '1989…1995',
                    },
                    {
                        name: 'J80 · Рестайлинг',
                        year: '1995…1997',
                    },
                    {
                        name: 'J100',
                        year: '1997…2002',
                    },
                    {
                        name: 'J100 · Рестайлинг',
                        year: '2002…2005',
                    },
                    {
                        name: 'J100 · 2-й рестайлинг',
                        year: '2005…2007',
                    },
                    {
                        name: 'J200',
                        year: '2007…2012',
                    },
                    {
                        name: 'J200 · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'J200 · 2-й рестайлинг',
                        year: '2015…2021',
                    },
                    {
                        name: 'J300 ',
                        year: '2021…',
                    },
                ],
            },
            {
                model: 'Land Cruiser Prado',
                generations: [
                    {
                        name: 'J90',
                        year: '1996…1999',
                    },
                    {
                        name: 'J90 · Рестайлинг',
                        year: '1999…2002',
                    },
                    {
                        name: 'J120',
                        year: '2002…2009',
                    },
                    {
                        name: 'J150',
                        year: '2009…2013',
                    },
                    {
                        name: 'J150 · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'J150 · 2-й рестайлинг',
                        year: '2017…2020',
                    },
                    {
                        name: 'J150 · 3-й рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Lite Ace',
                generations: [
                    {
                        name: 'III · Рестайлинг',
                        year: '1988…1992',
                    },
                ],
            },
            {
                model: 'Mark II',
                generations: [
                    {
                        name: 'X80',
                        year: '1988…1992',
                    },
                    {
                        name: 'X90',
                        year: '1992…1996',
                    },
                ],
            },
            {
                model: 'Mark X',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2009',
                    },
                ],
            },
            {
                model: 'Matrix',
                generations: [
                    {
                        name: 'I (E130)',
                        year: '2002…2008',
                    },
                    {
                        name: 'II (E140)',
                        year: '2009…2014',
                    },
                ],
            },
            {
                model: 'MR2',
                generations: [
                    {
                        name: 'III (W30)',
                        year: '1999…2002',
                    },
                ],
            },
            {
                model: 'Paseo',
                generations: [
                    {
                        name: 'II',
                        year: '1995…1999',
                    },
                ],
            },
            {
                model: 'Picnic',
                generations: [],
            },
            {
                model: 'Previa',
                generations: [
                    {
                        name: 'I (XR10',
                        year: 'XR20)',
                    },
                    {
                        name: 'II (XR30',
                        year: 'XR40)',
                    },
                    {
                        name: 'II (XR30',
                        year: 'XR40) · Рестайлинг',
                    },
                ],
            },
            {
                model: 'Prius',
                generations: [
                    {
                        name: 'II (XW20) · Рестайлинг',
                        year: '2005…2011',
                    },
                    {
                        name: 'III (XW30)',
                        year: '2009…2011',
                    },
                    {
                        name: 'IV (XW50)',
                        year: '2015…',
                    },
                    {
                        name: 'IV (XW50) · Рестайлинг',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Prius C',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2015',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Prius Prime',
                generations: [],
            },
            {
                model: 'Prius V(+)',
                generations: [
                    {
                        name: 'ZVW40/41',
                        year: '2011…2014',
                    },
                    {
                        name: 'ZVW40/41 · Рестайлинг',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'ProAce Verso',
                generations: [],
            },
            {
                model: 'RAV4',
                generations: [
                    {
                        name: 'I (XA10)',
                        year: '1994…1997',
                    },
                    {
                        name: 'II (XA20)',
                        year: '2000…2003',
                    },
                    {
                        name: 'II (XA20) · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'III (XA30)',
                        year: '2006…2009',
                    },
                    {
                        name: 'III (XA30) · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'III (XA30) · 2-й рестайлинг',
                        year: '2010…2013',
                    },
                    {
                        name: 'IV (XA40)',
                        year: '2013…2015',
                    },
                    {
                        name: 'IV (XA40) · Рестайлинг',
                        year: '2015…2019',
                    },
                    {
                        name: 'V (XA50)',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Sequoia',
                generations: [
                    {
                        name: 'I (XA10)',
                        year: '1994…1997',
                    },
                    {
                        name: 'II (XA20)',
                        year: '2000…2003',
                    },
                    {
                        name: 'II (XA20) · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'III (XA30)',
                        year: '2006…2009',
                    },
                    {
                        name: 'III (XA30) · Рестайлинг',
                        year: '2008…2010',
                    },
                    {
                        name: 'III (XA30) · 2-й рестайлинг',
                        year: '2010…2013',
                    },
                    {
                        name: 'IV (XA40)',
                        year: '2013…2015',
                    },
                    {
                        name: 'IV (XA40) · Рестайлинг',
                        year: '2015…2019',
                    },
                    {
                        name: 'V (XA50)',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Sienna',
                generations: [
                    {
                        name: 'I (XL10)',
                        year: '1997…2001',
                    },
                    {
                        name: 'II (XL20)',
                        year: '2003…2005',
                    },
                    {
                        name: 'II (XL20) · Рестайлинг',
                        year: '2005…2010',
                    },
                    {
                        name: 'III (XL30)',
                        year: '2010…',
                    },
                    {
                        name: 'III (XL30) · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'III (XL30) · 2-й рестайлинг',
                        year: '2017…2020',
                    },
                    {
                        name: 'IV (XL40)',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Starlet',
                generations: [
                    {
                        name: '60 Series',
                        year: '1978…1984',
                    },
                    {
                        name: '80 series',
                        year: '1989…1996',
                    },
                    {
                        name: '90 Series',
                        year: '1996…1999',
                    },
                ],
            },
            {
                model: 'Supra',
                generations: [
                    {
                        name: 'III (A70) · Рестайлинг',
                        year: '1988…1993',
                    },
                ],
            },
            {
                model: 'Tacoma',
                generations: [
                    {
                        name: 'II',
                        year: '2005…2010',
                    },
                    {
                        name: 'III',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Tercel',
                generations: [
                    {
                        name: 'II (L20)',
                        year: '1982…1988',
                    },
                ],
            },
            {
                model: 'Tundra',
                generations: [
                    {
                        name: 'II',
                        year: '2006…2009',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2009…2013',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2013…',
                    },
                ],
            },
            {
                model: 'Urban Cruiser',
                generations: [],
            },
            {
                model: 'Venza',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2015',
                    },
                    {
                        name: 'II',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Verso',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2018',
                    },
                ],
            },
            {
                model: 'Verso-S',
                generations: [],
            },
            {
                model: 'Yaris',
                generations: [
                    {
                        name: 'I (P1)',
                        year: '1999…2003',
                    },
                    {
                        name: 'I (P1) · Рестайлинг',
                        year: '2003…2005',
                    },
                    {
                        name: 'II (XP9)',
                        year: '2005…2009',
                    },
                    {
                        name: 'II (XP9) · Рестайлинг',
                        year: '2009…2012',
                    },
                    {
                        name: 'III (XP130) · Рестайлинг',
                        year: '2014…2017',
                    },
                    {
                        name: 'IA',
                        year: '2016…2018',
                    },
                    {
                        name: 'III (XP130) · 2-й рестайлинг',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'Yaris Verso',
                generations: [],
            },
        ],
    },
    {
        brand: 'Trabant',
        models: [
            {
                model: 'P601',
                generations: [],
            },
        ],
    },
    {
        brand: 'Volkswagen',
        models: [
            {
                model: 'Amarok',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2016',
                    },
                ],
            },
            {
                model: 'Arteon',
                generations: [],
            },
            {
                model: 'Atlas',
                generations: [],
            },
            {
                model: 'Beetle',
                generations: [
                    {
                        name: 'Type 1',
                        year: '1953…1985',
                    },
                    {
                        name: 'A4',
                        year: '1997…2005',
                    },
                    {
                        name: 'A5',
                        year: '2011…',
                    },
                ],
            },
            {
                model: 'Bora',
                generations: [],
            },
            {
                model: 'Caddy',
                generations: [
                    {
                        name: 'I',
                        year: '1979…1996',
                    },
                    {
                        name: 'II',
                        year: '1995…2004',
                    },
                    {
                        name: 'III',
                        year: '2004…2010',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'IV',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'Caddy MAXI',
                generations: [
                    {
                        name: 'III',
                        year: '2004…2010',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'IV',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Caravelle',
                generations: [
                    {
                        name: 'T3',
                        year: '1980…1991',
                    },
                    {
                        name: 'T4',
                        year: '1990…2003',
                    },
                    {
                        name: 'T5',
                        year: '2003…2009',
                    },
                    {
                        name: 'T5 · Рестайлинг',
                        year: '2009…2015',
                    },
                    {
                        name: 'T6',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Corrado',
                generations: [],
            },
            {
                model: 'Derby',
                generations: [
                    {
                        name: 'I',
                        year: '1977…1981',
                    },
                    {
                        name: 'II',
                        year: '1981…1985',
                    },
                ],
            },
            {
                model: 'e-Bora',
                generations: [],
            },
            {
                model: 'e-Golf',
                generations: [
                    {
                        name: 'VII',
                        year: '2012…2017',
                    },
                    {
                        name: 'VII · Рестайлинг',
                        year: '2017…2020',
                    },
                ],
            },
            {
                model: 'e-Lavida',
                generations: [],
            },
            {
                model: 'Eos',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2011',
                    },
                ],
            },
            {
                model: 'Fox',
                generations: [
                    {
                        name: 'II',
                        year: '2003…2009',
                    },
                    {
                        name: 'III',
                        year: '2009…2011',
                    },
                ],
            },
            {
                model: 'Gol',
                generations: [
                    {
                        name: 'I',
                        year: '1980…1994',
                    },
                ],
            },
            {
                model: 'Golf',
                generations: [
                    {
                        name: 'I',
                        year: '1974…1993',
                    },
                    {
                        name: 'II',
                        year: '1983…1992',
                    },
                    {
                        name: 'III',
                        year: '1991…2000',
                    },
                    {
                        name: 'IV',
                        year: '1997…2006',
                    },
                    {
                        name: 'V',
                        year: '2003…2009',
                    },
                    {
                        name: 'VI',
                        year: '2008…2013',
                    },
                    {
                        name: 'VII',
                        year: '2012…2017',
                    },
                    {
                        name: 'VII · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Golf Alltrack',
                generations: [
                    {
                        name: 'II',
                        year: '2017…2019',
                    },
                ],
            },
            {
                model: 'Golf GTI',
                generations: [
                    {
                        name: 'III',
                        year: '1992…1997',
                    },
                    {
                        name: 'V',
                        year: '2004…2008',
                    },
                    {
                        name: 'VII',
                        year: '2013…2017',
                    },
                    {
                        name: 'VII · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Golf Plus',
                generations: [
                    {
                        name: 'I',
                        year: '2005…2009',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2009…2014',
                    },
                ],
            },
            {
                model: 'Golf R',
                generations: [
                    {
                        name: 'VI',
                        year: '2009…2013',
                    },
                    {
                        name: 'VII',
                        year: '2013…2017',
                    },
                    {
                        name: 'VII · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'Golf Sportsvan',
                generations: [
                    {
                        name: 'I',
                        year: '2014…2017',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'ID.3',
                generations: [],
            },
            {
                model: 'ID.4',
                generations: [],
            },
            {
                model: 'ID.6',
                generations: [],
            },
            {
                model: 'Jetta',
                generations: [
                    {
                        name: 'I',
                        year: '1979…1984',
                    },
                    {
                        name: 'II',
                        year: '1984…1992',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1987…1992',
                    },
                    {
                        name: 'III',
                        year: '1992…1998',
                    },
                    {
                        name: 'IV',
                        year: '1998…2005',
                    },
                    {
                        name: 'V',
                        year: '2005…2011',
                    },
                    {
                        name: 'VI',
                        year: '2010…2015',
                    },
                    {
                        name: 'VI · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'VII',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Lupo',
                generations: [],
            },
            {
                model: 'Multivan',
                generations: [
                    {
                        name: 'T3',
                        year: '1984…1992',
                    },
                    {
                        name: 'T4',
                        year: '1992…2003',
                    },
                    {
                        name: 'T5',
                        year: '2003…2009',
                    },
                    {
                        name: 'T5 · Рестайлинг',
                        year: '2009…2015',
                    },
                    {
                        name: 'T6',
                        year: '2015…',
                    },
                    {
                        name: 'T7',
                        year: '2021…',
                    },
                ],
            },
            {
                model: 'Passat',
                generations: [
                    {
                        name: 'B1',
                        year: '1973…1981',
                    },
                    {
                        name: 'B2',
                        year: '1980…1988',
                    },
                    {
                        name: 'B3',
                        year: '1988…1994',
                    },
                    {
                        name: 'B4',
                        year: '1993…1997',
                    },
                    {
                        name: 'B5',
                        year: '1996…2005',
                    },
                    {
                        name: 'B6',
                        year: '2005…2010',
                    },
                    {
                        name: 'B7',
                        year: '2010…2015',
                    },
                    {
                        name: 'B8',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Passat (USA)',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Passat Alltrack',
                generations: [
                    {
                        name: 'B8',
                        year: '2014…',
                    },
                ],
            },
            {
                model: 'Passat CC',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2012',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2017',
                    },
                ],
            },
            {
                model: 'Phaeton',
                generations: [
                    {
                        name: 'I',
                        year: '2001…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2010…2017',
                    },
                ],
            },
            {
                model: 'Polo',
                generations: [
                    {
                        name: 'II',
                        year: '1981…1990',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '1990…1994',
                    },
                    {
                        name: 'III',
                        year: '1994…2002',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2000…2002',
                    },
                    {
                        name: 'IV',
                        year: '2001…2005',
                    },
                    {
                        name: 'IV · Рестайлинг',
                        year: '2005…2009',
                    },
                    {
                        name: 'V',
                        year: '2009…2015',
                    },
                    {
                        name: 'V · Рестайлинг',
                        year: '2014…2020',
                    },
                    {
                        name: 'VI',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Polo Sedan',
                generations: [
                    {
                        name: 'I',
                        year: '2009…2015',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2015…2020',
                    },
                ],
            },
            {
                model: 'Routan',
                generations: [],
            },
            {
                model: 'Santana',
                generations: [],
            },
            {
                model: 'Scirocco',
                generations: [
                    {
                        name: 'II',
                        year: '1981…1992',
                    },
                    {
                        name: 'III',
                        year: '2008…2015',
                    },
                ],
            },
            {
                model: 'Sharan',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2003',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2003…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2015',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'T-Roc',
                generations: [],
            },
            {
                model: 'Taos',
                generations: [],
            },
            {
                model: 'Taro',
                generations: [],
            },
            {
                model: 'Teramont',
                generations: [],
            },
            {
                model: 'Tiguan',
                generations: [
                    {
                        name: 'I',
                        year: '2007…2011',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2011…2018',
                    },
                    {
                        name: 'II',
                        year: '2016…2021',
                    },
                    {
                        name: 'II· Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'Touareg',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'II',
                        year: '2010…2014',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2014…2018',
                    },
                    {
                        name: 'III',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'Touran',
                generations: [
                    {
                        name: 'I',
                        year: '2003…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2010',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2010…2015',
                    },
                    {
                        name: 'II',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'Transporter',
                generations: [
                    {
                        name: 'T3',
                        year: '1979…1992',
                    },
                    {
                        name: 'T4',
                        year: '1990…2003',
                    },
                    {
                        name: 'T5',
                        year: '2003…2009',
                    },
                    {
                        name: 'T5 · Рестайлинг',
                        year: '2009…2015',
                    },
                    {
                        name: 'T6',
                        year: '2015…',
                    },
                ],
            },
            {
                model: 'up!',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'Vento',
                generations: [],
            },
        ],
    },
    {
        brand: 'Volvo',
        models: [
            {
                model: '440',
                generations: [],
            },
            {
                model: '460',
                generations: [],
            },
            {
                model: '740',
                generations: [],
            },
            {
                model: '760',
                generations: [],
            },
            {
                model: '850',
                generations: [],
            },
            {
                model: '940',
                generations: [],
            },
            {
                model: '960',
                generations: [
                    {
                        name: 'I',
                        year: '1990…1994',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1994…1996',
                    },
                ],
            },
            {
                model: '240 series',
                generations: [],
            },
            {
                model: '260 series',
                generations: [],
            },
            {
                model: '300 series',
                generations: [],
            },
            {
                model: 'C30',
                generations: [
                    {
                        name: 'I',
                        year: '2006…2010',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2010…2013',
                    },
                ],
            },
            {
                model: 'C70',
                generations: [
                    {
                        name: 'I',
                        year: '1997…2005',
                    },
                ],
            },
            {
                model: 'S40',
                generations: [
                    {
                        name: 'I',
                        year: '1995…2000',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2000…2004',
                    },
                    {
                        name: 'II',
                        year: '2004…2007',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2007…2012',
                    },
                ],
            },
            {
                model: 'S60',
                generations: [
                    {
                        name: 'I',
                        year: '2000…2004',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2004…2009',
                    },
                    {
                        name: 'II',
                        year: '2010…2013',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2013…2019',
                    },
                    {
                        name: 'III',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'S70',
                generations: [],
            },
            {
                model: 'S80',
                generations: [
                    {
                        name: 'I',
                        year: '1998…2003',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2003…2006',
                    },
                    {
                        name: 'II',
                        year: '2006…2009',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2009…2013',
                    },
                    {
                        name: 'II · 2-й рестайлинг',
                        year: '2013…2016',
                    },
                ],
            },
            {
                model: 'S90',
                generations: [
                    {
                        name: 'I',
                        year: '1996…1998',
                    },
                    {
                        name: 'II',
                        year: '2016…2020',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2020…',
                    },
                ],
            },
            {
                model: 'V40',
                generations: [
                    {
                        name: 'I',
                        year: '1995…1999',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '1999…2004',
                    },
                    {
                        name: 'II',
                        year: '2012…2016',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'V40 Cross Country',
                generations: [
                    {
                        name: 'I',
                        year: '2012…2016',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'V50',
                generations: [
                    {
                        name: 'I',
                        year: '2004…2007',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2007…2010',
                    },
                    {
                        name: 'I · 2-й рестайлинг',
                        year: '2010…2012',
                    },
                ],
            },
            {
                model: 'V60',
                generations: [
                    {
                        name: 'I',
                        year: '2010…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2019',
                    },
                    {
                        name: 'II ',
                        year: '2018…2020',
                    },
                ],
            },
            {
                model: 'V60 Cross Country',
                generations: [
                    {
                        name: 'I',
                        year: '2014…2018',
                    },
                    {
                        name: 'II',
                        year: '2018…',
                    },
                ],
            },
            {
                model: 'V70',
                generations: [
                    {
                        name: 'I',
                        year: '1996…2000',
                    },
                    {
                        name: 'II',
                        year: '2000…2005',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2004…2008',
                    },
                    {
                        name: 'III',
                        year: '2007…2013',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2013…2016',
                    },
                ],
            },
            {
                model: 'V90',
                generations: [
                    {
                        name: 'II',
                        year: '2016…2020',
                    },
                ],
            },
            {
                model: 'V90 Cross Country',
                generations: [
                    {
                        name: 'I',
                        year: '2016…',
                    },
                ],
            },
            {
                model: 'XC40',
                generations: [],
            },
            {
                model: 'XC60',
                generations: [
                    {
                        name: 'I',
                        year: '2008…2013',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2013…2017',
                    },
                    {
                        name: 'II',
                        year: '2017…',
                    },
                ],
            },
            {
                model: 'XC70',
                generations: [
                    {
                        name: 'II',
                        year: '2000…2004',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2004…2007',
                    },
                    {
                        name: 'III',
                        year: '2007…2013',
                    },
                    {
                        name: 'III · Рестайлинг',
                        year: '2013…2016',
                    },
                ],
            },
            {
                model: 'XC90',
                generations: [
                    {
                        name: 'I',
                        year: '2002…2006',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2006…2014',
                    },
                    {
                        name: 'II',
                        year: '2014…2020',
                    },
                    {
                        name: 'II · Рестайлинг',
                        year: '2019…',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Vortex',
        models: [
            {
                model: 'Corda',
                generations: [],
            },
            {
                model: 'Tingo',
                generations: [
                    {
                        name: 'I',
                        year: '2011…2012',
                    },
                    {
                        name: 'I · Рестайлинг',
                        year: '2012…2013',
                    },
                ],
            },
        ],
    },
    {
        brand: 'Wartburg',
        models: [
            {
                model: '353',
                generations: [],
            },
        ],
    },
    {
        brand: 'Weltmeister',
        models: [
            {
                model: 'EX5',
                generations: [],
            },
        ],
    },
    {
        brand: 'Xpeng',
        models: [
            {
                model: 'G3',
                generations: [],
            },
        ],
    },
    {
        brand: 'Zotye',
        models: [
            {
                model: 'Coupa',
                generations: [],
            },
            {
                model: 'T600',
                generations: [],
            },
            {
                model: 'Z300',
                generations: [],
            },
        ],
    },
    {
        brand: 'Богдан',
        models: [
            {
                model: '2310',
                generations: [],
            },
        ],
    },
    {
        brand: 'ГАЗ',
        models: [
            {
                model: '21',
                generations: [],
            },
            {
                model: '24',
                generations: [
                    {
                        name: '1 серия',
                        year: '1967…1977',
                    },
                    {
                        name: '2 серия',
                        year: '1977…1985',
                    },
                    {
                        name: '10',
                        year: '1985…1992',
                    },
                ],
            },
            {
                model: '67',
                generations: [],
            },
            {
                model: '69',
                generations: [],
            },
            {
                model: '3102',
                generations: [],
            },
            {
                model: '3110',
                generations: [],
            },
            {
                model: '31029',
                generations: [],
            },
            {
                model: '31105',
                generations: [],
            },
            {
                model: 'M-20 Победа',
                generations: [],
            },
            {
                model: 'Volga Siber',
                generations: [],
            },
            {
                model: 'Тигр',
                generations: [],
            },
        ],
    },
    {
        brand: 'ЕрАЗ',
        models: [
            {
                model: '762',
                generations: [],
            },
        ],
    },
    {
        brand: 'ЗАЗ',
        models: [
            {
                model: '965',
                generations: [],
            },
            {
                model: '968',
                generations: [
                    {
                        name: 'A',
                        year: '1970…1979',
                    },
                    {
                        name: 'M',
                        year: '1979…1994',
                    },
                ],
            },
            {
                model: '1102 Таврия',
                generations: [],
            },
            {
                model: '1103 Slavuta',
                generations: [],
            },
            {
                model: '1105 Дана',
                generations: [],
            },
            {
                model: 'Chance',
                generations: [],
            },
            {
                model: 'Forza',
                generations: [],
            },
            {
                model: 'Lanos',
                generations: [],
            },
            {
                model: 'Sens',
                generations: [],
            },
            {
                model: 'Vida',
                generations: [],
            },
        ],
    },
    {
        brand: 'ИЖ',
        models: [
            {
                model: '2125',
                generations: [
                    {
                        name: 'I',
                        year: '1973…1982',
                    },
                    {
                        name: '21251 · Рестайлинг',
                        year: '1982…1997',
                    },
                ],
            },
            {
                model: '2715',
                generations: [],
            },
            {
                model: '2717',
                generations: [
                    {
                        name: 'II ',
                        year: '2005…2012',
                    },
                ],
            },
        ],
    },
    {
        brand: 'ЛуАЗ',
        models: [
            {
                model: '967',
                generations: [],
            },
            {
                model: '969',
                generations: [],
            },
        ],
    },
    {
        brand: 'Москвич',
        models: [
            {
                model: '400',
                generations: [],
            },
            {
                model: '401',
                generations: [],
            },
            {
                model: '402',
                generations: [],
            },
            {
                model: '403',
                generations: [],
            },
            {
                model: '407',
                generations: [],
            },
            {
                model: '408',
                generations: [],
            },
            {
                model: '412',
                generations: [],
            },
            {
                model: '423',
                generations: [],
            },
            {
                model: '426',
                generations: [],
            },
            {
                model: '2138',
                generations: [],
            },
            {
                model: '2140',
                generations: [],
            },
            {
                model: '2141',
                generations: [],
            },
            {
                model: 'Святогор',
                generations: [],
            },
        ],
    },
    {
        brand: 'РАФ',
        models: [
            {
                model: '2203',
                generations: [],
            },
        ],
    },
    {
        brand: 'УАЗ',
        models: [
            {
                model: '31',
                generations: [],
            },
            {
                model: '452',
                generations: [
                    {
                        name: 'I',
                        year: '1965…1985',
                    },
                    {
                        name: 'II',
                        year: '1985…2020',
                    },
                ],
            },
            {
                model: '469',
                generations: [
                    {
                        name: 'I',
                        year: '1972…',
                    },
                ],
            },
            {
                model: '2206',
                generations: [],
            },
            {
                model: '2360',
                generations: [],
            },
            {
                model: '3909',
                generations: [],
            },
            {
                model: '2363 Пикап',
                generations: [],
            },
            {
                model: 'Hunter',
                generations: [],
            },
            {
                model: 'Патриот',
                generations: [],
            },
        ],
    },
]
