import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLinks } from '../../dataFetch/getLinks'

const fetchLinks = createAsyncThunk(
    'fetchLinks',
    async ({
        id,
    }) => {
        return await getLinks(id)
    },
)

export default fetchLinks
