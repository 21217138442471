import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import Button from '@mui/material/Button'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { carCompareSlice } from '../redux/reducers/CarsCompareSlice'
import tracker from '../utils/tracker'

export const AddToCompareButton = ({ car }) => {
  const dispatch = useDispatch()

  const { cars: carsToCompare = [] } = useSelector(
    (state) => state.carsCompareReducer
  )

  const { addToCompare, initCars, removeFromCompare } = carCompareSlice.actions

  const onAddToCompareChange = (isAdd) => {
    tracker.reachGoal('add_to_compare', {
      id: car.id,
    })
    if (isAdd) {
      tracker.reachGoal('add_to_compare', {
        id: car.id,
      })
      dispatch(
        addToCompare({
          car,
        })
      )
    } else {
      tracker.reachGoal('remove_from_compare', {
        id: car.id,
      })
      dispatch(
        removeFromCompare({
          car,
        })
      )
    }
  }

  const isPressed = carsToCompare.find((x) => x.id === car.id)

  const onClick = () => onAddToCompareChange(!isPressed)

  useEffect(() => {
    if (carsToCompare.length === 0) {
      dispatch(initCars())
    }
  }, [])

  return (
    <div style={{ background: 'rgba(255,255,255,0.6)' }}>
      <Button
        variant="text"
        aria-label="добавить для сравнения"
        size="small"
        onClick={onClick}
      >
        {isPressed ? <StarIcon /> : <StarBorderIcon />}
      </Button>
    </div>
  )
}
