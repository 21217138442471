import { format, formatDistanceToNow } from 'date-fns'

// ----------------------------------------------------------------------

export function fDate(date) {
    return format(new Date(date), 'dd MMMM yyyy')
}

export function fDateTime(date) {
    return format(new Date(date), 'dd MMM yyyy HH:mm')
}

export function fDateTimeSuffix(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true,
    })
}

export const secondsFromNow = (date) => {
    const now = new Date()
    const diff = now.getTime() - date.getTime()
    return Math.trunc(diff / 1000)
}

export const labelForTime = (date) => {
    const seconds = secondsFromNow(date)
    let result = ''
    if (seconds < 60) {
        result = `${seconds} секунд`
    } else if (seconds < 3600) {
        result = `${Math.trunc(seconds / 60)} минут`
    } else if (seconds < 86400) {
        result = `${Math.trunc(seconds / 3600)} часов`
    } else if (seconds < 604800) {
        result = `${Math.trunc(seconds / 86400)} дней`
    } else if (seconds < 2419200) {
        result = `${Math.trunc(seconds / 604800)} нед.`
    } else if (seconds < 29030400) {
        result = `${Math.trunc(seconds / 2419200)} мес.`
    } else {
        result = `${Math.trunc(seconds / 29030400)} лет`
    }
    return `Добавлено ${result} назад`
}
