import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Snackbar from '@mui/material/Snackbar'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import { carCompareSlice } from '../redux/reducers/CarsCompareSlice'
import { PAGES } from '../routes/constants'


export const CompareNotification = () => {
    const dispatch = useDispatch()

    const { cars: carsToCompare } = useSelector((state) => state.carsCompareReducer)
    const { setCars } = carCompareSlice.actions

    const handleClose = () => {
        dispatch(
            setCars([]),
        )
    }

    // const url = makeUrl(PAGES.carCompare, { carIds: carsToCompare?.map((car) => car.id) })

    return (
        <Snackbar
            open={!!carsToCompare?.length}
            severity='info'
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert severity='info'>
                {`Вы выбрали ${carsToCompare?.length} авто`}
                <Link
                    sx={{ ml: 1 }}
                    aria-label='сравнить'
                    component={RouterLink}
                    to={PAGES.carCompare.link}
                >Сравнить</Link>
                <Button
                    size='small'
                    sx={{ ml: 1 }}
                    variant='outlined'
                    aria-label='очистить'
                    onClick={handleClose}
                >Очистить</Button>
            </Alert>
        </Snackbar>
    )
}
