import { ReactComponent as Alert } from '../assets/icons/alert-circle-outline.svg'
import { ReactComponent as Save } from '../assets/icons/ant-design_save-outlined.svg'
import { ReactComponent as AudiLogo } from '../assets/icons/audi.svg'
import { ReactComponent as BMWLogo } from '../assets/icons/bmw-logo.svg'
import { ReactComponent as CarFilter } from '../assets/icons/bx_bx-filter-alt.svg'
import { ReactComponent as Search } from '../assets/icons/bx_bx-search.svg'
import { ReactComponent as Camera } from '../assets/icons/camera-plus-outline.svg'
import { ReactComponent as BatteryCar } from '../assets/icons/car-battery.svg'
import { ReactComponent as CarConvertible } from '../assets/icons/car-convertible.svg'
import { ReactComponent as CarCruiseControl } from '../assets/icons/car-cruise-control.svg'
import { ReactComponent as CarElectric } from '../assets/icons/car-electric-outline.svg'
import { ReactComponent as CarEstate } from '../assets/icons/car-estate.svg'
import { ReactComponent as CarHatchback } from '../assets/icons/car-hatchback.svg'
import { ReactComponent as CarOutline } from '../assets/icons/car-outline.svg'
import { ReactComponent as CarSeatHeater } from '../assets/icons/car-seat-heater.svg'
import { ReactComponent as SimpleCar } from '../assets/icons/car.svg'
import { ReactComponent as ChevronDown } from '../assets/icons/chevron-down.svg'
import { ReactComponent as ChevronLeft } from '../assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from '../assets/icons/chevron-right.svg'
import { ReactComponent as ChevronUp } from '../assets/icons/chevron-up.svg'
import { ReactComponent as Hamburger } from '../assets/icons/ci_hamburger.svg'
import { ReactComponent as Fire } from '../assets/icons/fire.svg'
import { ReactComponent as GridView } from '../assets/icons/ic_baseline-grid-view.svg'
import { ReactComponent as Location } from '../assets/icons/ic_outline-location-on.svg'
import { ReactComponent as Trash } from '../assets/icons/ic_sharp-delete-outline.svg'
import { ReactComponent as InstagramLogo } from '../assets/icons/instagram.svg'
import { ReactComponent as KIALogo } from '../assets/icons/kia-motors-1.svg'
import { ReactComponent as LandRoverLogo } from '../assets/icons/landrover.svg'
import { ReactComponent as Logo } from '../assets/icons/logo.svg'
import { ReactComponent as Dots } from '../assets/icons/mdi-light_dots-horizontal.svg'
import { ReactComponent as Account } from '../assets/icons/mdi_account-circle-outline.svg'
import { ReactComponent as ArrowDown } from '../assets/icons/mdi_arrow-down.svg'
import { ReactComponent as ArrowExpand } from '../assets/icons/mdi_arrow-expand.svg'
import { ReactComponent as Bookmark } from '../assets/icons/mdi_bookmark-outline.svg'
import { ReactComponent as CarShiftPattern } from '../assets/icons/mdi_car-shift-pattern.svg'
import { ReactComponent as AccountPhone } from '../assets/icons/mdi_card-account-phone-outline.svg'
import { ReactComponent as ChatOutline } from '../assets/icons/mdi_chat-outline.svg'
import { ReactComponent as Check } from '../assets/icons/mdi_check.svg'
import { ReactComponent as Close } from '../assets/icons/mdi_close.svg'
import { ReactComponent as CurrencyUsd } from '../assets/icons/mdi_currency-usd.svg'
import { ReactComponent as EngineOutline } from '../assets/icons/mdi_engine-outline.svg'
import { ReactComponent as Fuel } from '../assets/icons/mdi_fuel.svg'
import { ReactComponent as HeartOutline } from '../assets/icons/mdi_heart-outline.svg'
import { ReactComponent as ViewListOutline } from '../assets/icons/mdi_view-list-outline.svg'
import { ReactComponent as MercedesLogo } from '../assets/icons/mercedes-benz.svg'
import { ReactComponent as Minivan } from '../assets/icons/minivan.svg'
import { ReactComponent as Phone } from '../assets/icons/phone.svg'
import { ReactComponent as Sedan } from '../assets/icons/sedan.svg'
import { ReactComponent as SkodoLogo } from '../assets/icons/skoda-auto.svg'
import { ReactComponent as StationWag } from '../assets/icons/stationwagon.svg'
import { ReactComponent as Suv } from '../assets/icons/suv.svg'
import { ReactComponent as TelegramLogo } from '../assets/icons/telegram.svg'
import { ReactComponent as ToyotaLogo } from '../assets/icons/toyota.svg'
import { ReactComponent as ViberLogo } from '../assets/icons/viber-icon.svg'
import { ReactComponent as VolkswagenLogo } from '../assets/icons/volkswagen-1.svg'
import { ReactComponent as WhatsappLogo } from '../assets/icons/whatsapp-icon.svg'

const icons = {
    INTERFACE_ACTIONS: {
        chevronDown: ChevronDown,
        ChevronLeft: ChevronLeft,
        ChevronRight: ChevronRight,
        ChevronUp: ChevronUp,
        ArrowDown: ArrowDown,
        ArrowExpand: ArrowExpand,
        search: Search,
        hamburger: Hamburger,
        trash: Trash,
        close: Close,
        dots: Dots,
        bookmark: Bookmark,
    },
    CARS: {
        simpleCar: SimpleCar,
        carConvertible: CarConvertible,
        carElectric: CarElectric,
        carEstate: CarEstate,
        carHatchback: CarHatchback,
        carOutline: CarOutline,
        sedan: Sedan,
        minivan: Minivan,
        suv: Suv,
        stationWag: StationWag,
    },

    CARS_PARTS: {
        batteryCar: BatteryCar,
        carCruiseControl: CarCruiseControl,
        carSeatHeater: CarSeatHeater,
        engineOutline: EngineOutline,
        fuel: Fuel,
        carShiftPattern: CarShiftPattern,
    },

    CARS_LOGO: {
        KIA: KIALogo,
        BMW: BMWLogo,
        MERCEDES: MercedesLogo,
        SKODA: SkodoLogo,
        VOLKSWAGEN: VolkswagenLogo,
        LANDROVER: LandRoverLogo,
        AUDI: AudiLogo,
        TOYOTA: ToyotaLogo,
    },

    MESSENGERS_LOGO: {
        telegram: TelegramLogo,
        whatsapp: WhatsappLogo,
        viber: ViberLogo,
        instagram: InstagramLogo,
    },

    OTHER: {
        alert: Alert,
        fire: Fire,
        save: Save,
        carFilter: CarFilter,
        camera: Camera,
        gridView: GridView,
        location: Location,
        account: Account,
        accountPhone: AccountPhone,
        chatOutline: ChatOutline,
        check: Check,
        currencyUsd: CurrencyUsd,
        heartOutline: HeartOutline,
        viewListOutline:ViewListOutline,
        phone: Phone,
        logo: Logo,
    },
}

export default icons
