import NorthIcon from '@mui/icons-material/North'
import SouthIcon from '@mui/icons-material/South'
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import { useLogto } from '@logto/react'

import { convertProfitForColor } from '../utils/convertProfitForColor'

const styleParamsForCard = {
  small: {
    minWidth: '70px',
    minHeight: '20px',
    textAlign: 'center',
  },
  medium: {
    minWidth: '100px',
    minHeight: '60px',
    textAlign: 'center',
  },
}

const styleParams = {
  small: {
    maxHeight: '20px',
    minWidth: '20px',
    borderRadius: '5px',
  },
  medium: {
    maxHeight: '40px',
    minWidth: '240px',
    borderRadius: '40px',
  },
}

const typographyWithTooltip = {
  medium: {
    variant: 'h5',
    component: 'h3',
  },
  small: {
    variant: 'subtitle3',
    component: 'h7',
  },
}

// size - small, medium
export default function ProfitView({ profit, isForCard, size = 'medium' }) {
  const { isAuthenticated } = useLogto()

  const convertProfitToString = (profit) => {
    const value = +profit.replace(/[^0-9,-]/g, '')
    if (value >= 15) {
      return 'HIGH'
    }
    if (value <= -15) {
      return 'LOW'
    }
    return 'MEDIUM'
  }

  const MAP_PROFIT_TEXT = {
    HIGH: {
      text: 'Высокая',
      icon: <NorthIcon fontSize={size} />,
    },
    MEDIUM: {
      text: 'Средняя',
      icon: <VerticalAlignCenterIcon fontSize={size} />,
    },
    LOW: {
      text: 'Низкая',
      icon: <SouthIcon fontSize={size} />,
    },
  }

  const theme = useTheme()
  let element
  const isShrineProfit =
    profit === 'HIGH' || profit === 'MEDIUM' || profit === 'LOW'
  const profitLevel =
    isAuthenticated && !isShrineProfit ? convertProfitToString(profit) : profit
  const { text, icon } = MAP_PROFIT_TEXT[profitLevel]

  isForCard
    ? (element = (
        <Tooltip title="Выгода" placement="top">
          <Stack direction="row" alignItems="center" justifyContent="center">
            {icon}
            <Typography
              {...typographyWithTooltip[size]}
              sx={{
                lineHeight: 'normal',
                fontWeight: 600,
              }}
            >
              {isAuthenticated && !isShrineProfit ? profit : text}
            </Typography>
          </Stack>
        </Tooltip>
      ))
    : (element = (
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography
            variant="subtitle1"
            component="h5"
            sx={{
              lineHeight: 'normal',
              fontWeight: 400,
            }}
          >
            {isAuthenticated && !isShrineProfit
              ? `Выгода ${profit}`
              : `${text} выгода`}
          </Typography>
        </Stack>
      ))

  let color
  switch (profitLevel) {
    case 'HIGH':
      color = theme.palette.COLORS.DARKGREEN
      break
    case 'MEDIUM':
      color = theme.palette.COLORS.DARKYELLOW
      break
    case 'LOW':
      color = theme.palette.COLORS.DARKRED
      break
    default:
      color = theme.palette.warning.main
      break
  }

  const styles = {
    ...(isForCard ? styleParamsForCard : styleParams)[size],
    p: 1,
    color: theme.palette.common.white,
    backgroundColor: color,
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={styles}
    >
      {element}
    </Stack>
  )
}

ProfitView.propTypes = {
  profit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isForCard: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
}

ProfitView.defaultProps = {
  isForCard: true,
}
