import { Grid, useMediaQuery } from '@mui/material'
import Divider from '@mui/material/Divider'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import { v4 as uuidv4 } from 'uuid'
import CarouselSkeleton from './Carousel/CarouselSkeleton'
import MainInfoSkeleton from './MainInfoSkeleton'


export default function DetailedCardSkeleton() {
    const theme = useTheme()

    const ChipsSkeletons = Array.from(new Array(5)).map(() => (
        <Skeleton
            key={uuidv4()}
            variant='rounded'
            sx={{
                height: 32,
                width: 64,
            }}/>
    ))

    const matchesMedia = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Grid
            container
            spacing={2}
            mt={2}
        >
            <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={6}
            >
                { matchesMedia ? <MainInfoSkeleton /> : null }

                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    gap={1}
                >
                    <Skeleton width='40%'>
                        <Typography variant='caption' component='p' display='block' sx={{
                            lineHeight: 'normal',
                        }}>.</Typography>
                    </Skeleton>
                    <Skeleton width='40%'>
                        <Typography variant='caption' component='p' display='block' sx={{
                            lineHeight: 'normal',
                        }}>.</Typography>
                    </Skeleton>
                </Stack>
                <CarouselSkeleton />
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                lg={6}
                xl={6}
            >
                <Stack gap={2}>
                    { !matchesMedia ? <MainInfoSkeleton /> : null }

                    <Stack gap={2} pb={2}>
                        <Skeleton width='40%'>
                            <Typography variant='subtitle1' component='p' sx={{
                                lineHeight: 'normal',
                                whiteSpace: 'nowrap',
                            }}>.</Typography>
                        </Skeleton>

                        <Divider />

                        <Stack direction='row' gap={1} flexWrap='wrap'>
                            {ChipsSkeletons}
                        </Stack>
                    </Stack>

                    <Stack gap={2}>
                        <Skeleton width='40%'>
                            <Typography variant='subtitle1' component='p' sx={{
                                lineHeight: 'normal',
                                whiteSpace: 'nowrap',
                            }}>.</Typography>
                        </Skeleton>

                        <Divider />

                        <Skeleton width='100%' height='138px'>
                            <Typography variant='body1' component='p' sx={{
                                lineHeight: 'normal',
                            }}>.</Typography>
                        </Skeleton>
                    </Stack>

                    <Skeleton width='100%' height={48} />
                </Stack>
            </Grid>
        </Grid>
    )
}
