import DeleteIcon from '@mui/icons-material/Delete'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import { IconButton } from '@mui/material'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import ProfitView from '../../../components/ProfitView'
import { carCompareSlice } from '../../../redux/reducers/CarsCompareSlice'
import { PAGES } from '../../../routes/constants'

const CarImage = ({ photos }) => {
    const url = photos?.[0]?.url

    if (!url) {
        return (
            <div style={{ 
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: 150,
            }}>
                <DirectionsBusIcon sx={{ fontSize: 80 }} />
            </div>)
    }

    return (
        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-end', width: '100%' }}>
            <img style={{  height: 150 }}
                src={url}
                alt='автомобиль' />
        </div>
    )
}

const formatNumber = value => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

const attributes = [ 
    { label: 'год', key: 'year', format: (value) => `${value} г.` },
    { label: 'цена', key: '', format: (car) => 
        <Grid sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '> *': {
                marginLeft: 1,
            },
        }}
        >
            <ProfitView profit={car.profit} size='small' />
            <span>{`${formatNumber(car.price.usd)}$`}</span>
        </Grid>,
        
    },
    { label: 'пробег', key: 'mileage',
        format: (value) => `${formatNumber(value)} км.`,
    },
    { label: 'тип кузова', key: 'bodyType' },
    { label: 'двигатель', key: 'engine', format: ({ capacity, type }) => `${capacity} л. ${type}` },
    { label: 'коробка передач', key: 'transmission' },
    { label: 'место', key: 'location' },
]

const getCarAttribute = (car, attribute) => {
    if (attribute === '') {
        return car
    }

    const attributeKeys = attribute.split('.')
    let attributeValue = attributeKeys.reduce((acc, key) => {
        return acc[key]
    }, car)
    return attributeValue
}

const rightBorderForCell = {
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '-1px',
        right: '-1px',
        width: '1px',
        background: '#ddd',
        zIndex: 100,
    },
}

const stickyCellStyle = {
    position: 'sticky',
    left: 0,
    background: 'white',
    zIndex: 1,
    '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        bottom: '-1px',
        right: '-1px',
        width: '1px',
        background: '#ddd',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.4)',
    },
}

export const CarCompareTable = () => {
    const { cars: carsToCompare } = useSelector((state) => state.carsCompareReducer)
    const { initCars, removeFromCompare } = carCompareSlice.actions
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (carsToCompare.length === 0) {
            dispatch(initCars())
        }
    }, [])

    if (carsToCompare.length === 0) {
        navigate(PAGES.search.path)
        return null
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label='comparison table'>
                <TableHead>
                    <TableRow>
                        <TableCell
                            align='right'
                            sx={stickyCellStyle}
                        >
                            <span style={{ position: 'absolute', bottom: 16, right: 16 }}>атрибуты</span>
                        </TableCell>
                        {carsToCompare.map((car) => (
                            <TableCell
                                key={`title_${car.id}`}
                                align='right'
                                sx={{
                                    height: 100,
                                    minWidth: 250,
                                    ...rightBorderForCell,
                                }}
                            >
                                <Grid container justifyContent='space-between' style={{ height: '100%' }}>
                                    <Grid container xs={12}>
                                        <Grid item
                                            xs={11}
                                            style={{
                                                display: 'flex',
                                                alignContent: 'center',
                                                justifyContent: 'flex-end',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <CarImage photos={car.photos} />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                sx={{
                                                    ml: 1,
                                                }}
                                                aria-label={`удалить ${car.title} из сравнения`}
                                                onClick={() => dispatch(removeFromCompare({ car }))}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ display: 'flex' }} alignItems='flex-end'>
                                        <Link
                                            component={RouterLink}
                                            to={`${PAGES.search.path}/${car.id}`}
                                        >
                                            {car.title}, {car.year}, {car.price.usd}$
                                        </Link>
                                    </Grid>
                                </Grid>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {attributes.map(({ key, label, format = x=>x }) => (
                        <TableRow key={`row_${key}`}>
                            <TableCell sx={stickyCellStyle} align='right'>{label}</TableCell>
                            {carsToCompare.map((car) => (
                                <TableCell
                                    key={`${key}_${car.id}`}
                                    align='right'
                                    sx={rightBorderForCell}
                                >
                                    {format(getCarAttribute(car, key))}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
