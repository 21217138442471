import { Box, Stack } from '@mui/material'
import Skeleton from '@mui/material/Skeleton'
import React from 'react'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'
import { v4 as uuidv4 } from 'uuid'


export default function CarouselSkeleton() {
    const smallImagesSkeletons = Array.from(new Array(3)).map(() => (
        <Box key={uuidv4()}>
            <Skeleton sx={{
                height: 173,
                width: 173,
            }} variant='rectangular' />
        </Box>
    ))

    return (
        <Stack gap={2}>
            <Box sx={{
                position: 'relative',
            }}>
                <Skeleton sx={{
                    height: 330,
                    width: '100%',
                }} variant='rectangular' />
            </Box>

            <Stack direction='row' gap={1} justifyContent='space-between'>
                {smallImagesSkeletons}
            </Stack>
        </Stack>
    )
}
