import { Box, Grid } from '@mui/material'
import Chip from '@mui/material/Chip'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CARS } from '../../../dataFetch/cars'
import { filtersFormSlice } from '../../../redux/reducers/FiltersFormSlice'

export default function ModelBrandFilter({ fieldName, fieldText, forModel }) {
    const theme = useTheme()

    const { formData } = useSelector((state) => state.filtersFormReducer)
    const {
        setFormData,
        resetFormDataItem,
        setOpenedFilters,
        setSelectedFilters,
    } = filtersFormSlice.actions
    const dispatch = useDispatch()

    const [searchQuery, setSearchQuery] = useState('')

    const filterDataWithObjects = (query, data, field) => {
        if (!query) {
            return data
        } else {
            return data.filter((items) => {
                const item = items[field]
                return item.toLowerCase().includes(query.toLowerCase())
            })
        }
    }

    let arrayForFilter

    if (forModel) {
        const brandObj = CARS.find((brands) => brands.brand === formData.brand)
        arrayForFilter = brandObj?.models
    } else {
        arrayForFilter = [...CARS]
    }

    const dataFiltered = filterDataWithObjects(
        searchQuery,
        arrayForFilter,
        fieldName,
    )

    const handleChange = (event) => {
        setSearchQuery(event.target.value)
    }

    const handleClick = (event) => {
        if (!forModel) {
            dispatch(resetFormDataItem('model'))
        }

        dispatch(setFormData({ [`${fieldName}`]: event.target.innerText }))
        dispatch(setOpenedFilters(`${fieldName}`))
    }

    const handleDelete = (event) => {
        if (event.currentTarget.parentNode) {
            if (!forModel) {
                dispatch(resetFormDataItem(`${fieldName}`))
                dispatch(resetFormDataItem('model'))
                dispatch(setOpenedFilters('model'))
                dispatch(setSelectedFilters('model'))
                arrayForFilter = []
            }
            dispatch(resetFormDataItem(`${fieldName}`))
        }
    }

    return (
        <Stack direction='column' gap={3} pt={3} pb={3}>
            {formData[`${fieldName}`] ? (
                <Stack gap={2} flexWrap='wrap' direction='row' alignItems='center'>
                    <Typography variant='body1' component='p'>
            Выбранная {fieldText}:{' '}
                    </Typography>
                    <Box>
                        <Chip
                            id={formData[`${fieldName}`]}
                            label={formData[`${fieldName}`]}
                            sx={{
                                backgroundColor: theme.palette.COLORS.FOUNTAINBLUE,
                                color: theme.palette.COLORS.NONE,
                                '& .MuiChip-deleteIcon': {
                                    color: theme.palette.COLORS.NONE,
                                },
                                '& .MuiChip-deleteIcon:hover': {
                                    color: theme.palette.COLORS.DARKCYAN,
                                },
                            }}
                            onDelete={handleDelete}
                        />
                    </Box>
                </Stack>
            ) : null}
            {dataFiltered ? (
                <>
                    <TextField
                        id={`${fieldText}-search-bar`}
                        onInput={handleChange}
                        label={`${fieldText} авто`}
                        placeholder='Поиск...'
                    />
                    <Stack
                        mt={2}
                        mb={2}
                        ml={2}
                        sx={{
                            maxHeight: '300px',
                            overflowY: 'scroll',
                        }}
                    >
                        <Grid container spacing={2}>
                            {dataFiltered.map((cars) => (
                                <Grid key={cars[`${fieldName}`]} item xs={6}>
                                    <Link
                                        component='button'
                                        underline='hover'
                                        onClick={handleClick}
                                        type='button'
                                        sx={{
                                            width: '100%',
                                            textAlign: 'left',
                                            paddingTop: 0.5,
                                            paddingBottom: 0.5,
                                        }}
                                    >
                                        <Typography variant='body1' component='p'>
                                            {cars[`${fieldName}`]}
                                        </Typography>
                                    </Link>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>
                </>
            ) : (
                <Typography variant='body1' component='p'>
          Сначала нужно выбрать марку{' '}
                </Typography>
            )}
        </Stack>
    )
}

ModelBrandFilter.propTypes = {
    fieldName: PropTypes.string.isRequired,
    fieldText: PropTypes.string.isRequired,
    forModel: PropTypes.bool,
}

ModelBrandFilter.defaultProps = {
    forModel: false,
}
