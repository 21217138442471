import { ThemeProvider } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import { createTheme, styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'


export default function AutoCardSkeleton() {
    const theme = useTheme()

    const cardTheme = createTheme({
        breakpoints: {
            ...theme.breakpoints,
        },
        palette: {
            ...theme.palette,
            action: {
                disabled: theme.palette.COLORS.LIGHTGRAY,
            },
            info: {
                main: theme.palette.COLORS.DARKCYAN,
            },
        },
        typography: {
            caption: {
                ...theme.typography.caption,
                lineHeight: 'normal',
                color: theme.palette.COLORS.GRANITEGRAY,
            },
            h5: {
                ...theme.typography.h5,
                lineHeight: 'normal',
                fontWeight: 600,
            },
            h6: {
                ...theme.typography.h6,
                lineHeight: 'normal',
            },
            subtitle1: {
                ...theme.typography.subtitle1,
                color: theme.palette.COLORS.GRANITEGRAY,
            },
            button: {
                ...theme.typography.button,
            },
        },
    })

    const CardContentCustomPadding = styled(CardContent)`
      &:last-child {
            padding-bottom: 8px;
      }
    `

    return (
        <ThemeProvider theme={cardTheme}>
            <Card elevation={0} sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                justifyContent: 'space-between',
            }}>
                <Box>
                    <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
                        <Skeleton width='40%'>
                            <Typography variant='caption' component='p' display='block'>.</Typography>
                        </Skeleton>
                        <Skeleton width='40%'>
                            <Typography variant='caption' component='p' display='block'>.</Typography>
                        </Skeleton>
                    </Stack>
                    <Box sx={{
                        position: 'relative',
                        marginBottom: '20px',
                    }}>
                        <Skeleton sx={{ height: 320 }} variant='rectangular' />
                    </Box>
                    <CardContentCustomPadding sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                    }}>
                        <Stack direction='row' justifyContent='space-between' gap={1}>
                            <Skeleton width='40%'>
                                <Typography variant='h5' component='h3'>.</Typography>
                            </Skeleton>

                            <Stack direction='column' alignItems='flex-end' sx={{
                                width: '35%',
                            }}>
                                <Skeleton width='100%'>
                                    <Typography variant='h5' component='h4' color='primary' sx={{
                                        flexGrow: 3,
                                        whiteSpace: 'nowrap',
                                    }}>.</Typography>
                                </Skeleton>
                                <Skeleton width='100%'>
                                    <Typography variant='subtitle1' component='h5' sx={{
                                        flexGrow: 1,
                                        whiteSpace: 'nowrap',
                                    }}>.</Typography>
                                </Skeleton>
                            </Stack>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <Skeleton variant='circular' width={24} height={24} />
                                <Skeleton width={50}>
                                    <Typography variant='body1' component='p'>.</Typography>
                                </Skeleton>
                            </Stack>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <Skeleton variant='circular' width={24} height={24} />
                                <Skeleton width={50}>
                                    <Typography variant='body1' component='p'>.</Typography>
                                </Skeleton>
                            </Stack>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <Skeleton variant='circular' width={24} height={24} />
                                <Skeleton width={50}>
                                    <Typography variant='body1' component='p'>.</Typography>
                                </Skeleton>
                            </Stack>
                        </Stack>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' gap={1}>
                            <Stack direction='row' alignItems='center' gap={1}>
                                <Skeleton variant='circular' width={24} height={24} />
                                <Skeleton width={50}>
                                    <Typography variant='body1' component='p'>.</Typography>
                                </Skeleton>
                            </Stack>
                            <Skeleton width={50}>
                                <Typography variant='body1' component='p'>.</Typography>
                            </Skeleton>
                        </Stack>
                    </CardContentCustomPadding>
                </Box>

                <CardActions>
                    <Skeleton width='100%' height={48} />
                </CardActions>
            </Card>
        </ThemeProvider>
    )
}
