import React from 'react'
import { useHandleSignInCallback, useLogto } from '@logto/react'
import { useSearchParams } from 'react-router-dom'
import { Box } from '@mui/material'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

import Page from '../../components/Page'
import { DATA_CONTROLLER } from '../../const/externalLinks'
import getUrl from '../../dataFetch/_baseUrl'
import { BASE_URL } from '../../const/externalLinks'
import ErrorComponent from '../ErrorPage/components/ErrorComponent'

export default function CallbackPage() {
  const [hasError, setHasError] = React.useState(false)
  let [searchParams] = useSearchParams()

  const { isLoading } = useHandleSignInCallback(() => {})

  const { getAccessToken, isAuthenticated, signOut } = useLogto()

  React.useEffect(() => {
    if (searchParams.get('status') === 'fail') {
      setHasError(true)
    } else if (searchParams.size === 0) {
      window.location.href = '/'
    }
  }, [isAuthenticated, searchParams])

  React.useEffect(() => {
    const loadToken = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessToken(DATA_CONTROLLER)
          if (!accessToken) {
            throw new Error('fail get accessToken')
          }
          localStorage.setItem('accessToken', accessToken)

          const userURL = getUrl('/user')
          const response = await fetch(userURL, {
            method: 'POST',
            body: JSON.stringify({ token: accessToken }),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })

          if (response.status === 200) {
            window.location.href = '/search'
          } else {
            throw new Error('fail set user')
          }
        } catch (error) {
          // localStorage.removeItem('accessToken')
          // signOut(`${BASE_URL}/callback?status=fail`)
        }
      }
    }

    loadToken()
  }, [isAuthenticated, getAccessToken, signOut])

  return (
    <Page>
      {isLoading && (
        <Stack
          spacing={2}
          alignItems={'center'}
          pt={10}
          sx={{
            width: '100%',
          }}
        >
          <CircularProgress size={60} />
          Загрузка...
        </Stack>
      )}
      {!isLoading && hasError && (
        <Box sx={{ height: '100%' }}>
          <ErrorComponent />
        </Box>
      )}
    </Page>
  )
}
