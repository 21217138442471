import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'


export default function MainInfoSkeleton() {
    const theme = useTheme()

    return (
        <Stack>
            <Stack direction='row' gap={2} justifyContent='space-between' sx={{
                flexWrap: 'wrap',
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'center',
                },
            }}>
                <Skeleton width='40%'>
                    <Typography variant='h5' component='h3' pt={1} sx={{
                        lineHeight: 'normal',
                    }}>.</Typography>
                </Skeleton>

                <Skeleton width='70%' height={48} />
            </Stack>

            <Stack direction='row' pt={2} pb={2} gap={1} sx={{
                [theme.breakpoints.down('sm')]: {
                    justifyContent: 'center',
                },
            }}>
                <Skeleton width='40%'>
                    <Typography variant='h5' component='h4' sx={{
                        lineHeight: 'normal',
                        whiteSpace: 'nowrap',
                    }}>.</Typography>
                </Skeleton>
                <Skeleton width='40%'>
                    <Typography variant='h5' component='h4' sx={{
                        lineHeight: 'normal',
                        whiteSpace: 'nowrap',
                    }}>.</Typography>
                </Skeleton>
            </Stack>
        </Stack>
    )
}
