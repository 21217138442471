import { Provider } from 'react-redux'
import { LogtoProvider } from '@logto/react'

import ScrollToTop from './components/ScrollToTop'
import Router from './routes'
import ThemeConfig from './theme'
import GlobalStyles from './theme/globalStyles'
import { setupStore } from './redux/store'
import { DATA_CONTROLLER } from './const/externalLinks'

const config = {
  endpoint: 'https://dofxb3.logto.app/',
  appId: 'potelmrdfi35icxldv0ax',
  resources: [DATA_CONTROLLER],
  scopes: ['read:users', 'write:users'],
}

export default function App() {
  const store = setupStore()

  return (
    <LogtoProvider config={config}>
      <Provider store={store}>
        <ThemeConfig>
          <ScrollToTop />
          <GlobalStyles />
          <Router />
        </ThemeConfig>
      </Provider>
    </LogtoProvider>
  )
}
