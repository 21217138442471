// import { getOptionalUrlParams } from '../../routes/constants'
import Grid from '@mui/material/Grid'
import { CarCompareTable } from './components/carCompareTable'
import GoBackAndTitle from '../../components/GoBackAndTitle'
import Page from '../../components/Page'

export const CarComparePage = () => {
    // to do: uncomment when load from BE
    // const { carIds } = getOptionalUrlParams()

    // // to do
    // // 1) fetch cars by ids
    // // 2) render cars table

    return ( 
        <Page>
            <Grid
                container
                spacing={2}
                alignItems='center'
                justifyContent='space-between'
                mb={2}
            >
                <Grid item>
                    <GoBackAndTitle title='Сравнить автомобили'/>
                </Grid>
                <Grid item sx={{ width: '100%' }}>
                    <CarCompareTable/>
                </Grid>
            </Grid>
        </Page>
    )
}
