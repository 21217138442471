import {
  Box,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { ReactComponent as FooterCar } from '../../assets/footerCar.svg'
import Icon from '../../components/Icon'
import { TELEGRAM_LINK, FEEDBACK_FORM } from '../../const/externalLinks'
import tracker from '../../utils/tracker'
import { Link as RouterLink } from 'react-router-dom'
import navBarConfig from './NavBarConfig'

export default function FooterBar() {
  const theme = useTheme()

  const Footer = styled('footer')(({ theme }) => ({
    height: '263px',
    backgroundColor: theme.palette.COLORS.LIGHTBLUE,
    paddingTop: '16px',
    paddingBottom: '16px',
  }))

  const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  }))

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const flexDirection = isMobile ? 'column' : 'row'

  const handleOpenSupport = () => {
    tracker.reachGoal('[FOOTER]: open support')
  }

  const handleOpenBot = () => {
    tracker.reachGoal('[MAIN_PAGE]: open TG bot')
  }

  return (
    <Footer>
      <Container>
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            height: '100%',
            zIndex: 1,
          }}
        >
          {!isMobile && (
            <Box
              sx={{ position: 'absolute', zIndex: -1, right: 0, bottom: '25%' }}
            >
              <Icon icon={FooterCar} width={248} height={87} />
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              justifyContent: isMobile ? 'flex-start' : 'center',
              alignItems: isMobile ? 'flex-start' : 'center',
              paddingLeft: isMobile ? '15px' : 0,
              flexDirection,
              width: '100%',
            }}
          >
            {isMobile ? (
              <Box
                xs={{
                  position: 'relative',
                  zIndex: 1,
                  width: '100%',
                  height: '100%',
                }}
              >
                <Icon
                  sx={{
                    position: 'absolute',
                    zIndex: -1,
                    right: '20px',
                    top: '0',
                  }}
                  icon={theme.icons.OTHER.logo}
                  width={50}
                  height={50}
                  color={theme.palette.logo}
                />
              </Box>
            ) : (
              <Box sx={{ mr: '87px' }}>
                <Icon
                  icon={theme.icons.OTHER.logo}
                  width={138}
                  height={134}
                  color={theme.palette.logo}
                />
              </Box>
            )}
            {/* <Box sx={{ mr: '95px', mt: '8px' }}>
                        <Typography component='div' sx={{
                            color: theme.palette.COLORS.DARKCYAN,
                            fontWeight: 600,
                            mb: '16px'
                        }}>
                            Разделы
                        </Typography>
                        <List disablePadding={true}>
                            <ListItem disablePadding>
                                <ListItemButtonStyled disableRipple component={RouterLink} to={navBarConfig.homePage} >
                                    <ListItemText primary={'Pro-аккаунт'} sx={{ fontSize: '16px' }} />
                                </ListItemButtonStyled>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButtonStyled disableRipple component={RouterLink} to={navBarConfig.homePage} >
                                    <ListItemText primary={'Каталог авто'} />
                                </ListItemButtonStyled>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButtonStyled disableRipple component={RouterLink} to={navBarConfig.homePage} >
                                    <ListItemText primary={'О проекте'} />
                                </ListItemButtonStyled>
                            </ListItem>
                        </List>
                    </Box> */}
            <Box sx={{ mr: isMobile ? 0 : '140px', mt: '8px' }}>
              <Typography
                component="div"
                sx={{
                  color: theme.palette.COLORS.DARKCYAN,
                  fontWeight: 600,
                  mb: '16px',
                }}
              >
                Важная информация
              </Typography>
              <List disablePadding={true}>
                <ListItem disablePadding>
                  <ListItemButtonStyled
                    disableRipple
                    component={'a'}
                    href={FEEDBACK_FORM}
                    onClick={handleOpenSupport}
                    target="_blank"
                    aria-label="Обратная связь"
                  >
                    <ListItemText
                      primary={'Задать вопрос'}
                      sx={{ fontSize: '16px' }}
                    />
                  </ListItemButtonStyled>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButtonStyled disableRipple component={RouterLink} to={navBarConfig.licenseAgreement.path}>
                    <ListItemText primary={'Договор публичной оферты'} />
                  </ListItemButtonStyled>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButtonStyled disableRipple component={RouterLink} to={navBarConfig.offer.path}>
                    <ListItemText primary={'Преимущества премиума'} />
                  </ListItemButtonStyled>
                </ListItem>
              </List>
            </Box>
            <Box sx={{ mt: '8px' }}>
              <Typography
                component="div"
                sx={{
                  color: theme.palette.COLORS.DARKCYAN,
                  fontWeight: 600,
                  mb: '16px',
                }}
              >
                Социальные сети
              </Typography>
              <List disablePadding={true}>
                <ListItem disablePadding>
                  <ListItemButtonStyled
                    disableRipple
                    component={'a'}
                    target="_blank"
                    href={TELEGRAM_LINK}
                    onClick={handleOpenBot}
                    aria-label={'Telegram bot'}
                  >
                    <Icon
                      icon={theme.icons.MESSENGERS_LOGO.telegram}
                      width={16.5}
                      height={21}
                      sx={{ mr: theme.spacing(1) }}
                    />
                    <ListItemText
                      primary={'Telegram бот'}
                      sx={{ fontSize: '16px' }}
                    />
                  </ListItemButtonStyled>
                </ListItem>
                {/* <ListItem disablePadding>
                                <ListItemButtonStyled disableRipple component={RouterLink} to={navBarConfig.homePage}>
                                    <Icon
                                        icon={theme.icons.MESSENGERS_LOGO.instagram}
                                        width={16.5}
                                        height={21}
                                        sx={{ marginRight: '22px' }}
                                    />
                                    <ListItemText primary={'Instagram'} />
                                </ListItemButtonStyled>
                            </ListItem> */}
              </List>
            </Box>
          </Box>
        </Box>
      </Container>
    </Footer>
  )
}
