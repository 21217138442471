import { Grid, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'

import ButtonLinksWithDropdown from './ButtonLinksWithDropdown'
import Carousel from './Carousel/Carousel'
import MainInfo from './MainInfo'
import { numberWithSpaces } from '../../../utils/formatNumber'
import { labelForTime } from '../../../utils/formatTime'

import tracker from '../../../utils/tracker'
import { getTrackingTargetBySourceUrl } from '../../../utils/trackingUtils'

export default function DetailedCard({ car, id }) {
    const theme = useTheme()

    const StyledButton = styled(Button)({
        height: '48px',
        padding: '8px 22px',
    })

    const StyledChip = styled(Chip)({
        backgroundColor: theme.palette.COLORS.FOUNTAINBLUE,
        color: theme.palette.COLORS.NONE,
    })

    const matchesMedia = useMediaQuery(theme.breakpoints.down('md'))

    const whenAddedDate = new Date(car.whenUpdated)

    const handleOpenSource = () => {
        tracker.reachGoal(getTrackingTargetBySourceUrl(car.publicUrl))
    }

    return (
        <Grid container spacing={2} mt={2}>
            <Grid item xs={12} md={6} lg={6} xl={6}>
                {matchesMedia ? <MainInfo car={car} /> : null}

                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    gap={1}
                    pl={1}
                    pr={1}
                >
                    <Typography
                        variant='caption'
                        component='p'
                        display='block'
                        sx={{
                            lineHeight: 'normal',
                            color: theme.palette.COLORS.GRANITEGRAY,
                        }}
                    >
                        {labelForTime(whenAddedDate)}
                    </Typography>
                    <Typography
                        variant='caption'
                        component='p'
                        display='block'
                        sx={{
                            lineHeight: 'normal',
                            color: theme.palette.COLORS.GRANITEGRAY,
                        }}
                    >
                        {car.location}
                    </Typography>
                </Stack>
                <Carousel images={car.photos} />
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6}>
                <Stack gap={2}>
                    {!matchesMedia ? <MainInfo car={car} /> : null}

                    <Stack gap={2} pb={2}>
                        <Typography
                            variant='subtitle1'
                            component='p'
                            sx={{
                                lineHeight: 'normal',
                                whiteSpace: 'nowrap',
                                color: theme.palette.COLORS.LIGHTGRAY,
                            }}
                        >
              Основные характеристики:
                        </Typography>

                        <Divider sx={{ borderColor: theme.palette.COLORS.PUMICE }} />

                        <Stack direction='row' gap={1} flexWrap='wrap'>
                            <StyledChip label={car.bodyType} />
                            <StyledChip label={car.engine.type} />
                            <StyledChip label={`Объём: ${car.engine.capacity}`} />
                            <StyledChip label={car.transmission} />
                            <StyledChip label={`${numberWithSpaces(car.mileage)} км`} />
                        </Stack>
                    </Stack>

                    <Stack gap={2}>
                        <Typography
                            variant='subtitle1'
                            component='p'
                            sx={{
                                lineHeight: 'normal',
                                whiteSpace: 'nowrap',
                                color: theme.palette.COLORS.LIGHTGRAY,
                            }}
                        >
              Комментарий продавца:
                        </Typography>

                        <Divider sx={{ borderColor: theme.palette.COLORS.PUMICE }} />

                        <Typography
                            variant='body1'
                            component='p'
                            sx={{
                                lineHeight: 'normal',
                                color: theme.palette.COLORS.LIGHTGRAY,
                            }}
                        >
                            {car.description
                                ? car.description
                                : 'Продавец не написал комментарий.'}
                        </Typography>
                    </Stack>

                    <StyledButton
                        component={Link}
                        href={car.publicUrl}
                        target='_blank'
                        size='medium'
                        variant='contained'
                        fullWidth
                        onClick={handleOpenSource}
                        sx={{
                            mt: 2,
                        }}
                    >Перейти к объявлению</StyledButton>
                    {/*<Links id={id} />*/}
                    {/* <ButtonLinksWithDropdown id={id} /> */}
                    {/*<StackLinksWithDropdown id={id} />*/}
                </Stack>
            </Grid>
        </Grid>
    )
}

DetailedCard.propTypes = {
    car: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        price: PropTypes.shape({
            usd: PropTypes.number,
            byn: PropTypes.number,
        }),
        year: PropTypes.string,
        photos: PropTypes.arrayOf(
            PropTypes.shape({
                url: PropTypes.string,
                id: PropTypes.number,
            }),
        ),
        whenAdded: PropTypes.string,
        location: PropTypes.string,
        bodyType: PropTypes.string,
        engine: PropTypes.shape({
            capacity: PropTypes.string,
            type: PropTypes.string,
        }),
        transmission: PropTypes.string,
        mileage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        profit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        publicUrl: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
}
