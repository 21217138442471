import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import carsCompareReducer from './reducers/CarsCompareSlice'
import carReducer from './reducers/CarSlice'
import carsReducer from './reducers/CarsSlice'
import filtersFormReducer from './reducers/FiltersFormSlice'
import linksReducer from './reducers/LinksSlice'

const rootReducer = combineReducers({
  carsReducer,
  carReducer,
  linksReducer,
  filtersFormReducer,
  carsCompareReducer,
})

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
  })
}
