import React from 'react';

import MdBasePage from './../mdBasePage';
import agreement from './agreement.md';

const values = {
    '[заглушка:Имя Индивидуального предпринимателя]': 'Иванов Иван Иванович (заглушка)',
    '[заглушка:номер свидетельства о государственной регистрации]': '#123 (заглушка)',
    '[заглушка:дата выдачи]': '01.01.2020 (заглушка)',
    '[заглушка:кем выдано]': 'Могилевским горисполкомом (заглушка)',
}

export default function LicenseAgreementPage() {
    return <MdBasePage markdownTextLink={agreement} labelValues={values} />
}