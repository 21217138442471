import { merge } from 'lodash'
import Alert from './Alert'
import Button from './Button'
import Container from './Container'

export default function ComponentsOverrides(theme) {
    return merge(
        Button(theme),
        Container(theme),
        Alert(theme),
    )
}
