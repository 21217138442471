import { AppBar, Container, Toolbar } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'

import navBarConfig from './NavBarConfig'
import NavSection from './NavSection'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxSizing: 'border-box',
  boxShadow: 'none',
}))

export default function DashboardNavbar() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <StyledAppBar position="static" color="transparent">
      <Container>
        <Toolbar disableGutters>
          <NavSection navConfig={navBarConfig} />
        </Toolbar>
      </Container>
    </StyledAppBar>
  )
}

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
}
