import Container from '@mui/material/Container'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { Helmet } from 'react-helmet-async'

const Page = forwardRef(({ children, title = '', heightNavBar, ...other }, ref) => (
    <Container
        ref={ref}
        {...other}
        sx={{
            marginTop: `${heightNavBar}px`,
            marginBottom: 4,
            flex: '1 1 auto',
        }}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {children}
    </Container>
))

Page.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    heightNavBar: PropTypes.number,
}
Page.defaultProps = {
    heightNavBar: 0,
}

export default Page
