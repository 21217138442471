import { Box, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'


export default function CardCarousel({ images }) {
    const theme = useTheme()

    const settingsSlider1 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        arrows: false,
        appendDots: dots => (
            <div
                style={{
                    bottom: '-14px',
                    width: '100%',
                    height: '20px',
                    backgroundColor: theme.palette.COLORS.LIGHTBLUE,
                }}
            >
                <ul style={{
                    margin: '0px',
                    display: 'flex',
                }}> {dots} </ul>
            </div>
        ),
        dotsClass: `slick-dots dots`,
    }

    return (
        images.length ?
            <Stack gap={2}>
                <Slider
                    {...settingsSlider1}
                >
                    {
                        images.map(({ url, id }, index) => (
                            <Box key={id}>
                                <img
                                    src={url}
                                    alt={`Фото авто №${index + 1}`}
                                    style={{
                                        objectFit: 'cover',
                                        width: '100%',
                                        height: '320px',
                                    }}
                                />
                            </Box>
                        ))
                    }
                </Slider>
            </Stack> :
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '320px',
                    backgroundColor: theme.palette.COLORS.LIGHTSIROCCO,
                }}>
                    <Typography variant='h3' component='p' display='block'>
                        Без фото
                    </Typography>
                </div>
            </div>
    )
}

CardCarousel.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
}
