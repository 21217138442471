import MenuIcon from '@mui/icons-material/Menu'
import { UserScope, useLogto } from '@logto/react'
import { BASE_URL } from '../../const/externalLinks'

import {
  Box,
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  MenuList,
  Toolbar,
} from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom'
import PersonIcon from '@mui/icons-material/Person'

import Icon from '../../components/Icon'
import { TgLink } from '../../components/tgLink'
import { PAGES } from '../../routes/constants'

const ListRow = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '& li': {
    width: 'auto',
  },
  height: '125px',
}))

const ListItemTextStyles = styled(ListItemText)(({ theme }) => ({
  '& span': {
    fontSize: theme.typography.navItems.fontSize,
    weight: theme.typography.navItems.weight,
    color: theme.palette.primary.main,
  },
}))

const TgLinkStyled = () => (
  <ListItem>
    <ListItemButton component={TgLink} isShort={true} />
  </ListItem>
)

const ListItemLinkButton = ({
  navigateTo,
  target,
  label,
  onClick = () => {},
}) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const match = useCallback(
    (path) => {
      return path ? !!matchPath({ path, end: false }, pathname) : false
    },
    [pathname]
  )

  const activeRootStyle = useMemo(
    () => ({
      '& span': {
        color: theme.palette.common.black,
      },
    }),
    [theme]
  )

  return (
    <ListItem>
      <ListItemButton
        disableRipple
        disableGutters
        component={RouterLink}
        target={target}
        to={navigateTo}
        onClick={onClick}
      >
        <ListItemTextStyles
          sx={{
            ...(match(navigateTo) && activeRootStyle),
          }}
          primary={label}
        />
      </ListItemButton>
    </ListItem>
  )
}

const MenuItemLinkButton = ({ navigateTo, label }) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const match = useCallback(
    (path) => {
      return path ? !!matchPath({ path, end: false }, pathname) : false
    },
    [pathname]
  )

  const activeRootStyle = useMemo(
    () => ({
      '& span': {
        color: theme.palette.common.black,
      },
    }),
    [theme]
  )

  return (
    <MenuItem component={RouterLink} to={navigateTo}>
      <ListItemTextStyles
        sx={{
          ...(match(navigateTo) && activeRootStyle),
        }}
      >
        {label}
      </ListItemTextStyles>
    </MenuItem>
  )
}

const DesktopMenu = ({ navConfig }) => {
  const theme = useTheme()

  const { signIn, signOut, isAuthenticated } = useLogto()

  const handleSignOut = () => {
    localStorage.removeItem('accessToken')
    signOut(BASE_URL)
  }

  return (
    <ListRow>
      <ListItem disableGutters>
        <ListItemButton
          disableRipple
          variant="raised"
          style={{ backgroundColor: 'transparent' }}
          component={RouterLink}
          to={navConfig.default.path}
        >
          <Icon
            icon={theme.icons.OTHER.logo}
            width={45}
            height={45}
            color={theme.palette.logo}
          />
        </ListItemButton>
      </ListItem>
      <ListItemLinkButton navigateTo={PAGES.home.path} label="Главная" />
      <ListItemLinkButton navigateTo={PAGES.search.path} label="Поиск" />
      {/* <ListItemLinkButton
        navigateTo={TELEGRAM_LINK}
        target="_blank"
        label="Бот"
      /> */}

      {/* <TgLinkStyled /> */}
      <ListItem
        disableGutters
        sx={{
          width: '100% !important',
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 0,
          }}
        >
          {isAuthenticated ? (
            <Button
              variant="outlined"
              onClick={handleSignOut}
              sx={{
                fontSize: theme.typography.navItems.fontSize,
                weight: theme.typography.navItems.weight,
                lineHeight: 1.5,
              }}
            >
              Выйти
            </Button>
          ) : (
            <Button
              variant="contained"
              onClick={() => signIn(`${BASE_URL}/callback`)}
              sx={{
                fontSize: theme.typography.navItems.fontSize,
                weight: theme.typography.navItems.weight,
                lineHeight: 1.5,
              }}
            >
              Войти
            </Button>
          )}
        </Box>
      </ListItem>
    </ListRow>
  )
}

const MobileMenu = ({ navConfig }) => {
  const theme = useTheme()
  const { signIn, signOut, isAuthenticated } = useLogto()

  const handleSignOut = () => {
    localStorage.removeItem('accessToken')
    signOut(BASE_URL)
  }

  const [isOpen, setIsOpen] = useState(false)
  const handleOpenMenu = useCallback(() => setIsOpen(true), [])
  const handleCloseMenu = useCallback(() => setIsOpen(false), [])

  return (
    <>
      <Toolbar
        sx={{
          padding: '0',
        }}
      >
        <ListRow
          sx={{
            padding: '0',
          }}
        >
          <ListItem disableGutters>
            <ListItemButton
              disableRipple
              variant="raised"
              style={{
                padding: '0',
                backgroundColor: 'transparent',
              }}
              component={RouterLink}
              to={navConfig.default.path}
            >
              <Icon
                icon={theme.icons.OTHER.logo}
                width={45}
                height={45}
                color={theme.palette.logo}
              />
            </ListItemButton>
          </ListItem>
          <Box sx={{ flexGrow: 1 }} />
          <ListItemLinkButton
            disableGutters
            navigateTo={PAGES.home.path}
            label="Главная"
          />
          <ListItemLinkButton
            disableGutters
            navigateTo={PAGES.search.path}
            label="Поиск"
          />
          {/* <ListItemLinkButton
            disableGutters
            navigateTo={TELEGRAM_LINK}
            target="_blank"
            label="Бот"
          /> */}
          {isAuthenticated ? (
            <ListItemLinkButton
              disableGutters
              onClick={handleSignOut}
              label="Выйти"
            />
          ) : (
            <ListItemLinkButton
              disableGutters
              onClick={() => signIn(`${BASE_URL}/callback`)}
              label="Войти"
            />
          )}

          {/* <TgLinkStyled /> */}
          <Box sx={{ flexGrow: 1 }} />
          {/* <ListItem disableGutters>
                <Button variant='contained'>Войти</Button>
            </ListItem> */}
        </ListRow>
        {/*<RouterLink to={navConfig.default.path}>*/}
        {/*    <Icon icon={theme.icons.OTHER.logo} width={45} height={45}*/}
        {/*        color={theme.palette.logo} />*/}
        {/*</RouterLink>*/}
        {/*<IconButton*/}
        {/*    size='large'*/}
        {/*    edge='start'*/}
        {/*    color='inherit'*/}
        {/*    aria-label='menu'*/}
        {/*    aria-controls={isOpen ? 'basic-menu' : undefined}*/}
        {/*    aria-haspopup='true'*/}
        {/*    aria-expanded={isOpen ? 'true' : undefined}*/}
        {/*    onClick={handleOpenMenu}*/}
        {/*    sx={{ marginLeft: '5px', mr: 2 }}*/}
        {/*>*/}
        {/*    <MenuIcon />*/}
        {/*</IconButton>*/}
      </Toolbar>
      {/*<Drawer*/}
      {/*    open={isOpen}*/}
      {/*    onClose={handleCloseMenu}*/}
      {/*>*/}
      {/*    <MenuList sx={{ width: 250 }}>*/}
      {/*        <MenuItemLinkButton navigateTo={PAGES.home.path} label='Главная'/>*/}
      {/*        <MenuItemLinkButton navigateTo={PAGES.search.path} label='Поиск авто'/>*/}
      {/*    </MenuList>*/}
      {/*</Drawer>*/}
    </>
  )
}
export default function NavSection({ navConfig, ...other }) {
  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box {...other} sx={{ width: '100%' }}>
      {isMobile ? (
        <MobileMenu navConfig={navConfig} />
      ) : (
        <DesktopMenu navConfig={navConfig} />
      )}
    </Box>
  )
}

NavSection.propTypes = {
  navConfig: PropTypes.shape({}),
}
