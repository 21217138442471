import getUrl from './_baseUrl'


export const getLinks = async (id) => {
    const getLinksUrl = getUrl('/get-links')

    const response = await fetch(`${getLinksUrl}/?id=${id}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        },
    )

    if (!response.ok) {
        return response.error
    }

    return response.json()
}
