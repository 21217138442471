import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

const COLORS = {
    WHITESMOAK: '#F8F8F8',
    PUMICE: '#BDC1BB',
    BLACKBEAN: '#0B0E0C',
    SIROCCO: '#606763',
    TEPAPAGREEN: '#344C43',
    FOCOLTONE: '#008498',
    LAUREL_06: alpha('#6A8E70', 0.6),
    SCANDAL: '#B1CDC9',
    ROMAN: '#D96262',
    VANILLAICE: '#EED6D6',
    MANZ: '#E9DA55',
    FERN: '#5AAB62',
    MIDNIGHT: '#212B36',
    GREYCHATEAU_OP: alpha('#919EAB', 0.6),
    TEPAPAGREEN_OP: alpha('#344C43', 0.6),
    NONE: '#fff',
    BLACK: '#000',
    FOUNTAINBLUE: '#66B5C1',
    GRAYISHBLUE: '#696871',
    DARKCYAN: '#007B94',
    LIGHTCYAN: '#328591',
    LIGHTBLUE: '#B3DAE0',
    GRANITEGRAY: '#60636A',
    LIGHTGRAY: '#393B3F',
    DARKGREEN: '#36B368',
    DARKRED: '#EA5230',
    DARKYELLOW: '#ed6c02',
    LIGHTBLUE_OP: alpha('#B3DAE0', 0.6),
    LIGHTSIROCCO: '#cacbce',
}

const INFO = {
    lighter: '#D0F2FF',
    light: '#74CAFF',
    main: '#1890FF',
    dark: '#0C53B7',
    darker: '#04297A',
    contrastText: '#fff',
}
const SUCCESS = {
    lighter: '#E9FCD4',
    light: '#AAF27F',
    main: '#54D62C',
    dark: '#229A16',
    darker: '#08660D',
    contrastText: COLORS.MIDNIGHT,
}
const WARNING = {
    lighter: '#FFF7CD',
    light: '#FFE16A',
    main: '#FFC107',
    dark: '#B78103',
    darker: '#7A4F01',
    contrastText: COLORS.MIDNIGHT,
}
const ERROR = {
    lighter: '#FFE7D9',
    light: '#FFA48D',
    main: '#FF4842',
    dark: '#B72136',
    darker: '#7A0C2E',
    contrastText: '#fff',
}

const palette = {
    logo: COLORS.FOCOLTONE,
    common: { black: '#000', white: '#fff' },
    primary: { main: COLORS.FOCOLTONE },
    info: { ...INFO },
    success: { ...SUCCESS },
    warning: { ...WARNING },
    error: { ...ERROR },
    divider: COLORS.GREYCHATEAU_OP,
    text: { primary: COLORS.BLACK, secondary: COLORS.SIROCCO },
    background: { main: COLORS.SIROCCO, secondary: COLORS.WHITESMOAK },
    actionButtons: {
        hover: COLORS.FOUNTAINBLUE,
        disabled: COLORS.PUMICE,
    },
    COLORS,
    /*  action: {
         active: COLORS.NONE,
         hover: COLORS.GREYCHATEAU_OP,
         selected: COLORS.NONE,
         disabled: COLORS.PUMICE,
         focus: COLORS.NONE,
         hoverOpacity: 0.08,
         disabledOpacity: 0.48,
     }, */
}

export default palette
