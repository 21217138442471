import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import CloseIcon from '@mui/icons-material/Close'
import { Box, ButtonBase, IconButton, Modal, Stack } from '@mui/material'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './carousel.css'


export default function Carousel({ images }) {
    const theme = useTheme()

    const [nav1, setNav1] = useState(null)
    const [nav2, setNav2] = useState(null)
    const [nav3, setNav3] = useState(null)
    const [activeSlide, setActiveSlide] = useState(0)
    const [openModal, setOpenModal] = React.useState(false)

    const settingsSlider1 = {
        initialSlide: activeSlide,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        arrows: false,
    }

    const settingsSlider2 = {
        initialSlide: activeSlide,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        lazyLoad: true,
        focusOnSelect: true,
        swipeToSlide: true,
        centerPadding: '12px',
        arrows: false,
    }

    const settingsSlider3 = {
        initialSlide: activeSlide,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        swipeToSlide: true,
        adaptiveHeight: true,
        arrows: false,
    }

    const styleForModal = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 40,
        width: '1200px',
        height: 'auto',
        maxHeight: 'calc(100vh - 40px)',
        overflow: 'hidden',
        [theme.breakpoints.down('lg')]: {
            width: 'calc(100vw - 48px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
    }

    const styleForPrevButton = {
        position: 'absolute',
        top: '50%',
        left: '15px',
        zIndex: 40,
        transform: 'translate(0, -50%)',
        height: '40px',
        width: '40px',
        borderRadius: 0,
        backgroundColor: theme.palette.COLORS.LIGHTBLUE_OP,
        color: theme.palette.COLORS.NONE,
    }

    const styleForNextButton = {
        position: 'absolute',
        top: '50%',
        right: '15px',
        zIndex: 40,
        transform: 'translate(0, -50%)',
        height: '40px',
        width: '40px',
        borderRadius: 0,
        backgroundColor: theme.palette.COLORS.LIGHTBLUE_OP,
        color: theme.palette.COLORS.NONE,
    }

    const styleForCloseButton = {
        position: 'absolute',
        top: '15px',
        right: '15px',
        zIndex: 40,
        height: '40px',
        width: '40px',
        borderRadius: 0,
        backgroundColor: theme.palette.COLORS.LIGHTBLUE_OP,
        color: theme.palette.COLORS.NONE,
    }

    const handleOpenModal = (index) => {
        setActiveSlide(index)
        setOpenModal(true)
    }

    const handleCloseModal = () => setOpenModal(false)

    return (
        images.length ?
            <Stack gap={2}>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                >
                    <Box sx={styleForModal}>
                        <IconButton
                            sx={styleForPrevButton}
                            onClick={nav3?.slickPrev}
                        >
                            <ArrowBackIcon fontSize='large'/>
                        </IconButton>
                        <IconButton
                            sx={styleForNextButton}
                            onClick={nav3?.slickNext}
                        >
                            <ArrowForwardIcon fontSize='large'/>
                        </IconButton>
                        <IconButton
                            sx={styleForCloseButton}
                            onClick={handleCloseModal}
                        >
                            <CloseIcon fontSize='large'/>
                        </IconButton>
                        <Slider
                            {...settingsSlider3}
                            ref={(slider3) => setNav3(slider3)}
                        >
                            {
                                images.map(({ url, id }, index) => (
                                    <Box key={id + 'modal'}>
                                        <Stack sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor: theme.palette.COLORS.BLACK,
                                        }}>
                                            <img
                                                src={url}
                                                alt={`Фото авто №${index + 1}`}
                                                style={{
                                                    width: '100%',
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                ))
                            }
                        </Slider>
                    </Box>
                </Modal>
                <Box sx={{
                    position: 'relative',
                }}>
                    <IconButton
                        sx={styleForPrevButton}
                        onClick={nav1?.slickPrev}
                    >
                        <ArrowBackIcon fontSize='large'/>
                    </IconButton>
                    <IconButton
                        sx={styleForNextButton}
                        onClick={nav1?.slickNext}
                    >
                        <ArrowForwardIcon fontSize='large'/>
                    </IconButton>
                    <Slider
                        {...settingsSlider1}
                        asNavFor={nav2} ref={(slider1) => setNav1(slider1)}
                    >
                        {
                            images.map(({ url, id }, index) => (
                                <Box key={id}>
                                    <ButtonBase
                                        sx={{
                                            width: '100%',
                                        }}
                                        onClick={()=>{handleOpenModal(index)}}>
                                        <img
                                            src={url}
                                            alt={`Фото авто №${index + 1}`}
                                            style={{
                                                objectFit: 'cover',
                                                width: '100%',
                                                height: '330px',
                                            }}
                                        />
                                    </ButtonBase>
                                </Box>
                            ))
                        }
                    </Slider>
                </Box>

                <Box sx={{
                    position: 'relative',
                }}>
                    <Slider
                        {...settingsSlider2}
                        asNavFor={nav1}
                        ref={(slider2) => setNav2(slider2)}
                    >
                        {
                            images.map(({ url, id }, index) => (
                                <Box key={id + 'nav'} sx={{
                                    cursor: 'pointer',
                                }}>
                                    <img
                                        src={url}
                                        alt={`Фото авто №${index + 1}`}
                                        style={{
                                            objectFit: 'cover',
                                            width: '100%',
                                            height: '150px',

                                        }}
                                    />
                                </Box>
                            ))
                        }
                    </Slider>
                </Box>
            </Stack> :
            <div>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '570px',
                    backgroundColor: theme.palette.COLORS.LIGHTSIROCCO,
                }}>
                    <Typography variant='h3' component='p' display='block'>
                            Без фото
                    </Typography>
                </div>
            </div>
    )
}

Carousel.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        id: PropTypes.number,
    })),
}
