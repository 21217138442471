import Grid from '@mui/material/Grid'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/system'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import AutoCard from './AutoCard'
import AutoCardSkeleton from './AutoCardSkeleton'
import { carCompareSlice } from '../../../redux/reducers/CarsCompareSlice'
import { carsSlice } from '../../../redux/reducers/CarsSlice'
import fetchCars from '../../../redux/thunks/fetchCarsThunk'
import ErrorComponent from '../../ErrorPage/components/ErrorComponent'

export default function AutoCards() {
  const { cars, status, error, perPage, page } = useSelector(
    (state) => state.carsReducer
  )

  const { initCars } = carCompareSlice.actions

  const { setPage } = carsSlice.actions
  const dispatch = useDispatch()
  const theme = useTheme()

  const handleChangePage = (event, page) => {
    dispatch(setPage(page))
    dispatch(
      fetchCars({
        count: perPage,
        page,
      })
    )
  }

  useEffect(() => {
    dispatch(
      fetchCars({
        count: perPage,
        page: 1,
      })
    )
    dispatch(initCars())
  }, [])

  let carsInfo = null

  if (status === 'loading') {
    carsInfo = Array.from(new Array(perPage)).map(() => (
      <Grid key={uuidv4()} item xs={12} md={12} lg={6} xl={4}>
        <AutoCardSkeleton />
      </Grid>
    ))
  } else if (status === 'succeeded') {
    carsInfo = cars.slice(0, perPage).map((car) => {
      return (
        <Grid key={car.publicUrl} item xs={12} md={12} lg={6} xl={4}>
          <AutoCard {...car} />
        </Grid>
      )
    })

    if (carsInfo.length === 0) {
      carsInfo = (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          gap={2}
          p={4}
          sx={{
            height: '100%',
          }}
        >
          <Typography
            variant="h4"
            component="h2"
            sx={{
              lineHeight: 'normal',
              color: theme.palette.COLORS.LIGHTGRAY,
              textAlign: 'center',
              paddingTop: 2,
            }}
          >
            Подходящих объявлений не найдено
          </Typography>
        </Stack>
      )
    }
  } else if (status === 'failed') {
    console.error(error)
    carsInfo = <ErrorComponent />
  }
  const paginationCount =
    cars.length / perPage === 5 ? 50 : page + Math.ceil(cars.length / perPage)

  return (
    <>
      {carsInfo}
      {paginationCount > 2 && (
        <Stack
          spacing={2}
          alignItems={'center'}
          justifyContent={'end'}
          pt={10}
          sx={{
            width: '100%',
          }}
        >
          <Pagination
            onChange={handleChangePage}
            page={page}
            count={paginationCount}
            variant="outlined"
            shape="rounded"
            color={'primary'}
            disabled={status === 'loading'}
            sx={{
              '& .MuiPagination-ul': {
                '& li:nth-last-child(2)': {
                  display: 'none',
                },
              },
            }}
          />
        </Stack>
      )}
    </>
  )
}
