import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

// material

// ----------------------------------------------------------------------

Icon.propTypes = {
    sx: PropTypes.object,
}

export default function Icon(props) {
    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: props.width,
        height: props.height,
        '& path': { fill: props.color, width: props.width, height: props.height },
        '& svg': { display: 'block', width: `${props.width}px !important`, height: `${props.height}px !important` },
        ...props?.sx
    }}>
        <props.icon />
        {props.children}
    </Box >



    // eslint-disable-next-line no-lone-blocks
    // eslint-disable-next-line no-unreachable
    {/* <SvgIcon component={icon} viewBox="0 0 24 24" sx={{
        '& path': {
            fill: color,
        }
    }} /> */}
}
