import { PAGES } from '../../routes/constants'

const navBarConfig = {
    default: { path: PAGES.default.path },
    homePage: { path: PAGES.home.path },
    benefits: {
        path: `${PAGES.home.path}#${PAGES.home.anchors.benefits}`,
    },
    bestOffers: { path: PAGES.catalog.home },
    licenseAgreement: { path: PAGES.licenseAgreement.path },
    offer: { path: PAGES.offer.path },
}

export default navBarConfig
