import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { useEffect, useMemo, useRef } from 'react'
import { ReactComponent as AdvantageOne } from '../../../assets/adv1.svg'
import { ReactComponent as AdvantageTwo } from '../../../assets/adv2.svg'
import { ReactComponent as AdvantageThree } from '../../../assets/adv3.svg'
import { ReactComponent as AdvantageFive } from '../../../assets/adv5.svg'
import Icon from '../../../components/Icon'
import { TgLink } from '../../../components/tgLink'
import { PAGES } from '../../../routes/constants'

const CardComponent = ({ isMobile, title, children, img, isRevers = false }) => {
    const styles = useMemo(() => {
        return isMobile
            ? {
                width: '100%',
                marginBottom: 5,
                marginTop: 5,
                maxWidth: 400,
            }
            : {
                width: 449,
                margin: 0,
            }
    }, [isMobile])

    const flexDirection = useMemo(() => {
        let direct = 'column'
        if (!isMobile) {
            direct = isRevers ? 'row-reverse' : 'row'
        }
        return direct
    }, [isMobile, isRevers])

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: flexDirection,
                alignItems: 'center',
                marginBottom: isMobile ? 0 : 10,
            }}
        >
            {isMobile && (
                <Typography variant='h3' gutterBottom component='div' style={{ textAlign: 'center' }}>
                    {title}
                </Typography>
            )}
            <Icon icon={img} sx={{ mr: isMobile ? 0 : 136, ...styles }} />
            <Box>
                {!isMobile && (
                    <Typography variant='h3' gutterBottom component='div'>
                        {title}
                    </Typography>
                )}
                <Typography
                    gutterBottom
                    component='div'
                    sx={{
                        fontSize: '20px',
                    }}
                >
                    {children}
                </Typography>
            </Box>
        </Box>
    )
}

const CostAnalyticsBox = ({ isMobile }) => {
    return (
        <CardComponent
            isMobile={isMobile}
            title='Аналитика цен'
            img={AdvantageOne}
        >
            Мы анализируем объявления, чтобы вы понимали текущую рыночную конкуренцию и принимали более обоснованные решения.
        </CardComponent>
    )
}

const FastNotifications = ({ isMobile }) => {
    return (
        <CardComponent
            isMobile={isMobile}
            title='Быстрые уведомления'
            isRevers={true}
            img={AdvantageTwo}
        >
            Мы отправляем вам уведомления о продажах через нашего бота, чтобы вы могли первым дозвониться до продовца.
            <TgLink/>
        </CardComponent>
    )
}

export default function Advantages() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const containerRef = useRef(null)

    useEffect(() => {
        const container = containerRef?.current
        if (container && window.location.hash === `#${PAGES.home.anchors.benefits}`) {
            container.scrollIntoView({ behavior: 'smooth' })
        }
    }, [window?.location?.hash])

    // const smallContainerFlexDirection = useMemo(() => {
    //     return isMobile ? 'column' : 'row'
    // }, [isMobile])

    return (
        <Box id={PAGES.home.anchors.benefits} ref={containerRef} sx={{ pt: isMobile ? '30px' : '120px', pb: '50px' }}>
            <FastNotifications isMobile={isMobile} />
            <CostAnalyticsBox isMobile={isMobile} />
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: smallContainerFlexDirection }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Icon icon={AdvantageThree} sx={{ mb: '40px' }} width={200} height={200} />
                    <Typography variant='subtitle1' gutterBottom component='div'>
                        Сохранить фильтр
                    </Typography>
                    <Typography
                        gutterBottom
                        component='div'
                        sx={{
                            fontSize: '16px',
                            mt: '18px',
                        }}
                    >
                        Для быстрого поиска мы сохраняем выбранные вами фильтры.
                    </Typography>
                </Box>
                {isMobile && <Box sx={{ width: 40 }}></Box>}
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                    <Icon icon={AdvantageFive} sx={{ mb: '40px' }} width={200} height={200} />
                    <TgLink/>
                    <Typography
                        gutterBottom
                        component='div'
                        sx={{
                            fontSize: '16px',
                            mt: '18px',
                        }}
                    >
                        Наш Telegram бот отправляет новые объявления сразу после публикации.
                    </Typography>
                </Box>
            </Box> */}
        </Box>
    )
}
