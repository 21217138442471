const ym = window.ym
const trackerId = 92335309

const parseParams = (params) =>
    Object.keys(params).reduce((acc, cur) => {
        return `${acc}; key: ${cur}, value: ${params[cur]}`
    }, '')


class Tracker {
    reachGoal = (targetName, params) => {
        let target = targetName
        if(params) {
            target = `${targetName} ${parseParams(params)}`
        }
        ym(trackerId, 'reachGoal', target)
    }

}

export default new Tracker()
