import Stack from '@mui/material/Stack'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import DetailedCard from './components/DetailedCard'
import DetailedCardSkeleton from './components/DetailedCardSkeleton'
import { AddToCompareButton } from '../../components/AddToCompareButton'
import { CompareNotification } from '../../components/CompareNotification'
import GoBackAndTitle from '../../components/GoBackAndTitle'
import Page from '../../components/Page'
import fetchCar from '../../redux/thunks/fetchCarThunk'
import ErrorComponent from '../ErrorPage/components/ErrorComponent'

export default function DetailedInfoPage() {
    const { id } = useParams()

    const { car, error, status } = useSelector((state) => state.carReducer)
    const dispatch = useDispatch()

    useEffect(() => {
        if (id !== car?.id || status === 'idle') {
            dispatch(fetchCar({ id }))
        }
    }, [])

    let carInfo

    if (status === 'loading') {
        carInfo = <DetailedCardSkeleton />
    } else if (status === 'succeeded') {
        carInfo = <DetailedCard car={car} id={id}/>
    } else if (status === 'failed') {
        console.error(error)
        carInfo = <ErrorComponent />
    }

    return (
        <Page>
            <Stack alignItems='center' justifyContent='center' direction='column'>
                {status === 'succeeded' ?
                    (<GoBackAndTitle title={`Купить ${car.title}, ${car.year}`}>
                        <AddToCompareButton car={car} />
                    </GoBackAndTitle>) :
                    <GoBackAndTitle />}
                {carInfo}
            </Stack>
            <CompareNotification />
        </Page>
    )
}
