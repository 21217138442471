import { Box, Button } from '@mui/material'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { Link as RouterLink } from 'react-router-dom'
import MainPageCar from '../../../assets/mainPageCar.jpg'
import { PAGES } from '../../../routes/constants'
import tracker from '../../../utils/tracker'

export default function PickCar() {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const mobileStylesForContainer = isMobile ? {
        width: '100%',
        backgroundImage: `url(${MainPageCar})`,
        backgroundColor: 'rgba(255,255,255,0.6)',
        backgroundBlendMode: 'lighten',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '0 5px 45px 5px',
    } : {
        display: 'flex',
        paddingRight: '40px',
    }

    return (
        <Box sx={{
            ...mobileStylesForContainer,
        }}>
            <Box sx={{
                pt: '68px',
                flexShrink: 2,
                flexGrow: 1,
            }}>
                <Typography variant='h1' gutterBottom component='div'>
                    Подберем идеальное авто
                </Typography>
                <Typography gutterBottom variant='h4' component='div' style={{
                    margin: 5,
                    padding: 5,
                }}
                sx={{
                    fontSize: '20px',
                    fontWeight: 'normal',
                    fontStyle: 'normal',
                    color: theme.palette.COLORS.GRAYISHBLUE,
                    background: 'rgba(255, 255, 255, 0.7)',
                }}>
                    Мы собираем объявления о продаже авто и показываем вам выгодные сделки.
                </Typography>
                <Box sx={{ display: 'flex', pt: '40px' }}>
                    <Button
                        component={RouterLink}
                        variant='contained'
                        to={PAGES.search.path}
                        sx={{
                            width: 250,
                            height: 64,
                            fontSize: 26,
                        }}
                        onClick={() => tracker.reachGoal('[MAIN_PAGE]: search click')}
                    >
                        Подобрать авто
                    </Button>
                </Box>
            </Box>
            {isMobile ? null : <Box sx={{
                backgroundImage: `url(${MainPageCar})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                width: '683px',
                height: '544px',
                flexShrink: 1,
            }} />}
        </Box>
    )
}
