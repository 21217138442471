export default function Alert(theme) {
    return {
        MuiAlert: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 16px',
                },
                '& .MuiAlert-message': {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
                '& .MuiAlert-action': {
                    padding: 0,
                },
            },
        },
    }
}
