
import Grid from '@mui/material/Grid'
import AutoCards from './components/AutoCards'
import Filters from './components/Filters'
import FiltersState from './components/FiltersState'
import { CompareNotification } from '../../components/CompareNotification'
import GoBackAndTitle from '../../components/GoBackAndTitle'
import Page from '../../components/Page'

export default function SearchPage() {
    return (
        <Page>
            <Grid
                container
                spacing={2}
                sx={{
                    height: '100%',
                }}
            >
                <Grid
                    container
                    item
                    spacing={2}
                    xl={12}
                    alignItems='center'
                    justifyContent='space-between'
                    mb={2}
                >
                    <Grid
                        item
                        xs={12}
                        md={4}
                        lg={4}
                        xl={3}
                    >
                        <GoBackAndTitle title='Поиск авто'/>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={8}
                        lg={8}
                        xl={9}
                    >
                        <FiltersState />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    xl={3}
                >
                    <Filters />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    xs={12}
                    md={8}
                    lg={8}
                    xl={9}
                    direction='column'
                    justifyContent='space-between'
                >
                    <Grid
                        container
                        item
                        spacing={2}
                    >
                        <AutoCards />
                    </Grid>
                </Grid>
                <Grid>
                    <CompareNotification />
                </Grid>
            </Grid>
        </Page >
    )
}
