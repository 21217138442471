import { createSlice } from '@reduxjs/toolkit'
import fetchLinks from '../thunks/fetchLinksThunk'


const initialState = {
    links: {},
    error: null,
    status: 'idle',
}

export const carSlice = createSlice({
    name: 'links',
    initialState,
    reducers: {
        addCar(state, action) {
            state.car = [action.payload]
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLinks.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchLinks.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.links = action.payload
            })
            .addCase(fetchLinks.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    },
})

export default carSlice.reducer
