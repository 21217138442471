import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { useTheme } from '@mui/system'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { filtersFormSlice } from '../../../redux/reducers/FiltersFormSlice'

export default function FilterItem({ filterText, filterName, withDisabledForBrand, children }) {
    const theme = useTheme()

    const { formData, selectedFilters, openedFilters } = useSelector((state) => state.filtersFormReducer)
    const { setOpenedFilters, setSelectedFilters } = filtersFormSlice.actions
    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setOpenedFilters(filterName))
        dispatch(setSelectedFilters(filterName))
    }

    const disabled = withDisabledForBrand ?
        formData['brand'] === '' :
        false

    return (
        <>
            <ListItemButton
                onClick={handleClick}
                selected={selectedFilters[filterName]}
                disabled={disabled}
                sx={{
                    paddingTop: 2,
                    paddingBottom: 2,
                    '&.Mui-selected': {
                        backgroundColor: theme.palette.COLORS.FILTERLISTACTIVE,
                    },
                    '&.Mui-focusVisible': {
                        backgroundColor: theme.palette.COLORS.FILTERLISTACTIVE,
                    },
                    ':hover': {
                        backgroundColor: theme.palette.COLORS.FILTERLISTACTIVE,
                    },
                }}
            >
                <ListItemText
                    primary={filterText}
                    primaryTypographyProps={{
                        color: theme.palette.COLORS.DARKCYAN,
                    }}
                />
                {withDisabledForBrand ?
                    openedFilters[filterName] && !disabled ?
                        <ExpandLess sx={{ color: theme.palette.COLORS.DARKCYAN }}/> :
                        <ExpandMore sx={{ color: theme.palette.COLORS.DARKCYAN }}/> :
                    openedFilters[filterName] ?
                        <ExpandLess sx={{ color: theme.palette.COLORS.DARKCYAN }}/> :
                        <ExpandMore sx={{ color: theme.palette.COLORS.DARKCYAN }}/>
                }
            </ListItemButton>
            {
                withDisabledForBrand ?
                    <Collapse in={ disabled ? false : openedFilters[filterName]} timeout='auto' unmountOnExit>
                        {children}
                    </Collapse> :
                    <Collapse in={openedFilters[filterName]} timeout='auto' unmountOnExit>
                        {children}
                    </Collapse>
            }

        </>
    )
}

FilterItem.propTypes = {
    filterText: PropTypes.string.isRequired,
    filterName: PropTypes.string.isRequired,
    withDisabledForBrand: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

FilterItem.defaultProps = {
    disabled: false,
}

