import getUrl from './_baseUrl'


export const getCar = async (id) => {
    const getCarUrl = getUrl('/get-car')

    const response = await fetch(`${getCarUrl}/?id=${id}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        },
    )

    if (!response.ok) {
        return response.error
    }
    return response.json()
}
