import { Grid, Input } from '@mui/material'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { filtersFormSlice } from '../../../redux/reducers/FiltersFormSlice'


export default function SliderFilter({ fieldName, min, max, step }) {
    const { formData } = useSelector((state) => state.filtersFormReducer)
    const { setFormData } = filtersFormSlice.actions
    const dispatch = useDispatch()

    const handleChangeSlider = (event, newValue) => {
        if (typeof newValue === 'number') {
            dispatch(setFormData({ [`${fieldName}`]: String(newValue) }))
        }
    }

    const handleChangeInput = (event) => {
        dispatch(setFormData({ [`${fieldName}`]: String(event.target.value === '' ? '' : event.target.value) }))
    }

    const handleBlur = () => {
        if (formData[`${fieldName}`] < min) {
            dispatch(setFormData({ [`${fieldName}`]: String(min) }))
        } else if (formData[`${fieldName}`] > max) {
            dispatch(setFormData({ [`${fieldName}`]: String(max) }))
        }
    }

    return (
        <Box p={4}>
            <Grid container spacing={2} alignItems='center'>
                <Grid item xs={10}>
                    <Box>
                        <Slider
                            min={min}
                            max={max}
                            step={step}
                            getAriaLabel={() => fieldName}
                            value={Number(formData[`${fieldName}`])}
                            onChange={handleChangeSlider}
                            valueLabelDisplay='on'
                            disableSwap
                            aria-labelledby='input-slider'
                        />
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box>
                        <Input
                            value={Number(formData[`${fieldName}`])}
                            size='small'
                            onChange={handleChangeInput}
                            onBlur={handleBlur}
                            inputProps={{
                                step,
                                min,
                                max,
                                type: 'number',
                                'aria-labelledby': 'input-slider',
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

SliderFilter.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    fieldName: PropTypes.string.isRequired,
}
