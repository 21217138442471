import { createSlice } from '@reduxjs/toolkit'
import fetchCars from '../thunks/fetchCarsThunk'


const initialState = {
    cars: [],
    status: 'idle',
    error: null,
    perPage: 20,
    page: 1,
}

export const carsSlice = createSlice({
    name: 'cars',
    initialState,
    reducers: {
        addCars(state, action) {
            state.cars = [action.payload]
        },
        setPage(state, action) {
            state.page = action.payload
        },
        setPerPage(state, action) {
            state.perPage = action.payload
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchCars.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchCars.fulfilled, (state, action) => {
                state.status = 'succeeded'
                state.cars = action.payload
            })
            .addCase(fetchCars.rejected, (state, action) => {
                state.status = 'failed'
                state.cars = []
                state.error = action.error.message
            })
    },
})

export default carsSlice.reducer
