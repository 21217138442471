export const PAGES = {
    home: {
        path: '/home',
        anchors: {
            benefits: 'benefits', // do not use advertisement - it can be hidden by adblock and similar 
        },
    },
    default: { path: '/' },
    catalog: { path: '/catalog' },
    search: { path: '/search' },
    about: { path: '/about' },
    login: { path: '/login' },
    callback: { path: '/callback' },

    detailedInfo: {
        path: '/search/:id',
        link: '/search/',
    },
    carCompare: {
        path: '/compare',
        link: '/compare',
        // to do: uncoment when add load from BE
        // path: '/compare?carIds=:carIds',
        // link: '/compare',
        // param: {
        //     optional: ['carIds'],
        // },
    },
    licenseAgreement: {
        path: '/license-agreement',
        link: '/license-agreement',
    },
    offer: {
        path: '/offer',
        link: '/offer',
    }
}

export const makeUrl = (pageObj, params) => {
    const { path, param } = pageObj
    const optional = param?.optional || []
    // replace optional params
    const url = path.replace(/:\w+/g, (param) => {
        const paramName = param.replace(':', '')
        if (optional.includes(paramName)) {
            return params[paramName] || ''
        }
        return params[paramName]
    })
    return url
}

export const getOptionalUrlParams = () => {
    const { search = '' } = window.location
    const params = search.replace('?', '').split('&')
    return params.reduce((prev, cur) => {
        const [key, value] = cur.split('=')
        return { [key] : value, ...prev }
    }, {})
}
