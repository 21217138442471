import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCar } from '../../dataFetch/getCar'

const fetchCar = createAsyncThunk(
    'fetchCar',
    async ({
        id,
    }) => {
        return await getCar(id)
    },
)

export default fetchCar
