import { Button } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { useDispatch, useSelector } from 'react-redux'
import FilterItem from './FilterItem'
import ModelBrandFilter from './ModelBrandFilter'
import SliderFilter from './SliderFilter'
import TextFieldFilter from './TextFieldFilter'
import { carsSlice } from '../../../redux/reducers/CarsSlice'
import { filtersFormSlice, initialFormData } from '../../../redux/reducers/FiltersFormSlice'
import fetchCars from '../../../redux/thunks/fetchCarsThunk'
import tracker from '../../../utils/tracker'

export default function Filters() {
    const theme = useTheme()

    const StyledList = styled(List)({
        '&& .Mui-selected, && .Mui-selected:hover': {
            backgroundColor: theme.palette.COLORS.FILTERLISTACTIVE,
        },
        '& .MuiListItemButton-root:hover': {
            backgroundColor: theme.palette.COLORS.FILTERLISTACTIVE,
        },
    })

    const StyledButton = styled(Button)({
        height: '48px',
        padding: '8px 22px',
    })

    const { setPage } = carsSlice.actions
    const { setActiveFilters } = filtersFormSlice.actions
    const { status, perPage } = useSelector((state) => state.carsReducer)
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const dispatch = useDispatch()

    const FILTERS = {
        price: 'Цена в $',
        profit: 'Выгода в % (от -100% до 100%)',
        brand: 'Марка',
        model: 'Модель',
        year: 'Год',
        mileage: 'Пробег',
        engineCapacity: 'Объём двигателя в л.',
        transmission: 'Коробка',
        bodyType: 'Кузов',
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        dispatch(setPage(1))

        dispatch(setActiveFilters())

        dispatch(
            fetchCars({
                count: perPage,
                page: 1,
            }),
        )

        tracker.reachGoal('[SEARCH_PAGE]: click search button')

        if (!isMobile) {
            window.scrollTo({ top: 120, behavior: 'smooth' })
        }
    }

    return (
        <form
            onSubmit={handleSubmit}
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '2em',
            }}
        >
            <StyledList>
                <Divider sx={{ borderColor: theme.palette.COLORS.LIGHTBLUE }} />
                <FilterItem
                    filterText={FILTERS.profit}
                    filterName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.profit)]}
                >
                    <TextFieldFilter
                        fieldName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.profit)]}
                        minFieldValue={Number(initialFormData.profitFrom)}
                        maxFieldValue={Number(initialFormData.profitTo)}
                    />
                </FilterItem>
                <Divider sx={{ borderColor: theme.palette.COLORS.LIGHTBLUE }} />
                <FilterItem
                    filterText={FILTERS.brand}
                    filterName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.brand)]}
                >
                    <ModelBrandFilter
                        fieldName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.brand)]}
                        fieldText={FILTERS.brand}
                    />
                </FilterItem>
                <Divider sx={{ borderColor: theme.palette.COLORS.LIGHTBLUE }} />
                <FilterItem
                    filterText={FILTERS.model}
                    filterName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.model)]}
                    withDisabledForBrand={true}
                >
                    <ModelBrandFilter
                        fieldName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.model)]}
                        fieldText={FILTERS.model}
                        forModel={true}
                    />
                </FilterItem>
                <Divider sx={{ borderColor: theme.palette.COLORS.LIGHTBLUE }} />
                <FilterItem
                    filterText={FILTERS.price}
                    filterName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.price)]}
                >
                    <TextFieldFilter
                        fieldName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.price)]}
                        minFieldValue={Number(initialFormData.priceFrom)}
                    />
                </FilterItem>
                <Divider sx={{ borderColor: theme.palette.COLORS.LIGHTBLUE }} />
                <FilterItem
                    filterText={FILTERS.year}
                    filterName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.year)]}
                >
                    <TextFieldFilter
                        fieldName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.year)]}
                        minFieldValue={Number(initialFormData.yearFrom)}
                        maxFieldValue={Number(initialFormData.yearTo)}
                    />
                </FilterItem>
                <Divider sx={{ borderColor: theme.palette.COLORS.LIGHTBLUE }} />
                <FilterItem
                    filterText={FILTERS.engineCapacity}
                    filterName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.engineCapacity)]}
                >
                    <SliderFilter
                        fieldName={Object.keys(FILTERS)[Object.values(FILTERS).indexOf(FILTERS.engineCapacity)]}
                        min={0.2}
                        max={9.0}
                        step={0.1}
                    />
                </FilterItem>
                <Divider sx={{ borderColor: theme.palette.COLORS.LIGHTBLUE }} />
            </StyledList>

            <StyledButton
                type={'submit'}
                size='medium'
                variant='contained'
                disabled={status === 'loading'}
                sx={{
                    width: '100%',
                }}
            >
                Поиск Авто
            </StyledButton>
        </form>
    )
}
